import { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { getTZ, transformAvailSlotsDBArrToMapArrForUserView } from '../utils/objectUtils';
import dayjs from 'dayjs';
import { Badge, Typography, Paper, Chip } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import TimeZoneSelector from './TimeZoneSelector';


export default function AvailabilityUserSee(props) {

  const { dynamodbSTS, onSelectedHoursChange, onStudentTimezoneChange } = props;
  
  // const providerTZ = timeZoneState;
  // console.log('STSmap,', STSmap);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [hoursOfSD, setHoursOfSD] = useState([]);
  const maxDate = dayjs().add(8, 'week').endOf('week').toDate();
  const [selectedHours, setSelectedHours] = useState(new Map());
  const [currMonth, setCurrMonth] = useState(dayjs().startOf('month'));
  const [tzChanged, setTZChanged] = useState(false);
  // const currentMonth = dayjs().startOf('month');

  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [userTimeZoneState, setUserTimeZoneState] = useState({
    selectedTimeZone: '',
    useSystemTimeZone: true,
    systemTimeZone: systemTimeZone,
  });

  const [selectedTS, setSelectedTS] = useState(new Map());
  let userTZ = userTimeZoneState.useSystemTimeZone ? userTimeZoneState.systemTimeZone : userTimeZoneState.selectedTimeZone;
  const STSmap = transformAvailSlotsDBArrToMapArrForUserView(dynamodbSTS, userTZ);

  useEffect(() => {
    let userTZ = userTimeZoneState.useSystemTimeZone ? userTimeZoneState.systemTimeZone : userTimeZoneState.selectedTimeZone;
    const STSmap = transformAvailSlotsDBArrToMapArrForUserView(dynamodbSTS, userTZ);
    setSelectedTS(STSmap); // This triggers a re-render after state is updated
    onStudentTimezoneChange(getTZ(userTimeZoneState));
  }, [dynamodbSTS, userTimeZoneState, onStudentTimezoneChange]); // Dependencies

  useEffect(() => {
    if (selectedTS) { // Check if selectedTS is not null/undefined/empty based on your initial state
      // console.log('selectedTS changed', currMonth);
      computeHighlightedDays(currMonth);
    }
  }, [selectedTS]); // I removed selectedTS as a dependency on 4/7/2024, added it back on 4/8/24
  
  

  // const resultMap = transformAvailSlotsDBArrToMapArr(dynamodbSTS, userTZ);
  // setSelectedTS(resultMap);
  // console.log('resultMap,', resultMap)

  //   console.log('timezone', timezone); // Example output: "America/New_York"

const handleTimeZoneChange = (newUserTimeZoneState) => {
    setUserTimeZoneState(newUserTimeZoneState);
    setTZChanged(true);
  };

// const recalculateAdjustedHours = (dateString) => {
//   let timeZone1 = providerTZ.useSystemTimeZone ? providerTZ.systemTimeZone : providerTZ.selectedTimeZone;
//   let timeZone2 = userTimeZoneState.useSystemTimeZone ? userTimeZoneState.systemTimeZone : userTimeZoneState.selectedTimeZone;
//   const originalHours = STSmap.get(dateString) || []; // Ensure there's a fallback
//   const adjHours = calculateTimeZoneOffset(dateString, timeZone1, timeZone2, originalHours);
//   console.log('dateString, adjHours,', dateString, adjHours);
//   setHoursOfSD(adjHours);
// };

// const computeHighlightedDays = (month) => {
    
//     const highlightedDays = new Set();
//     STSmap.forEach((_, date) => {
//         const dateObject = dayjs(date);

//         const isTodayOrLater = dateObject.isSame(today, 'day') || dateObject.isAfter(today);
//         if (dateObject.isSame(month, 'month') && isTodayOrLater) {
//         highlightedDays.add(dateObject.date());
//         }
//       });
    
//     setHighlightedDays(Array.from(highlightedDays));
//     setIsLoading(false);
// };

const computeHighlightedDays = (month) => {
  
  const highlightedDays = new Set();
  // console.log('month,', month);
  // selectedTS.forEach((timeSlots, date) => {
  //     if (timeSlots.length === 0) {
  //       // Delete the entry for the date with empty timeSlots
  //       selectedTS.delete(date);
  //     }

  // });

  if (selectedTS.size !== 0) {
    const today = dayjs(); // Get today's date
    selectedTS.forEach((timeSlots, date) => {

      
        const dateObject = dayjs(date);
        // console.log('date, ', date, dateObject.isSame(month, 'month') && !dateObject.isBefore(today));
        const isTodayOrLater = dateObject.isSame(today, 'day') || dateObject.isAfter(today);
        // console.log('date,', date,  'isThisMonth,',dateObject.isSame(month, 'month'));
        if (dateObject.isSame(month, 'month') && isTodayOrLater) { 
          // Add the day to highlightedDays if it's in the current month
          
          highlightedDays.add(dateObject.date());
        }
    });
  }
  // console.log('highlighted days computed, ', Array.from(highlightedDays), 'length(selectedTS),', selectedTS.size);
  // console.log('Array.from(highlightedDays)', Array.from(highlightedDays));
  setHighlightedDays(Array.from(highlightedDays));
  setIsLoading(false);
};

useEffect(() => {
    onSelectedHoursChange(selectedHours);
  }, [selectedHours, onSelectedHoursChange]); //



// useEffect(() => {
//     if (selectedDate) { // Ensure there's a selected date to work with
//       const dateString = selectedDate.toDate().toDateString();
//       const hours = STSmap.get(dateString);
//       console.log('dateString,', dateString, 'selectedTS.get(string)', selectedTS.get(dateString));
//       setHoursOfSD(hours);
//     }
//   }, [providerTZ, userTimeZoneState]);





const handleMonthChange = (newDate) => {
    const currentMonth = dayjs().startOf('month');
    const nextMonth = dayjs().add(1, 'month').startOf('month');
    const newDateMonth = dayjs(newDate).startOf('month');
  
    setIsLoading(true);
    setCurrMonth(newDateMonth);

    if (dayjs(newDate).isSame(currentMonth, 'month')) {
      computeHighlightedDays(currentMonth);
    
    } else if (dayjs(newDate).isSame(nextMonth, 'month')) {
      computeHighlightedDays(nextMonth);
      console.log("User navigated to the next month");
    } else if (dayjs(newDate).isSame(newDateMonth, 'month')) {
      computeHighlightedDays(newDateMonth);
      console.log("User navigated to the 3rd month");
    }
  };

  const handleDateClick = (date) => {
    const dateString = date.toDate().toDateString();
    setSelectedDate(date);
    const hours = STSmap.get(dateString);
    setHoursOfSD(hours);
    // console.log('dateString,', dateString, 'selectedTS.get(string)', selectedTS.values(dateString));

};


const handleTimeSlotClick = (hour) => {
    // Clone the current state to a new map to ensure immutability
    // const updatedHoursSelection = new Map(selectedHours);
    const dateString = selectedDate.toDate().toDateString();

    const newSelectedHours = new Map(selectedHours);
    const hoursForDate = newSelectedHours.get(dateString) || [];

    if (hoursForDate.includes(hour)) {
        // If hour is already selected, remove it
        const updatedHours = hoursForDate.filter(h => h !== hour);
        if (updatedHours.length > 0) {
            newSelectedHours.set(dateString, updatedHours);
        } else {
            // If no more selected hours for this date, remove the date key
            newSelectedHours.delete(dateString);
        }
    } else {
        // If hour is not selected, add it
        newSelectedHours.set(dateString, [...hoursForDate, hour]);
    }

    setSelectedHours(newSelectedHours); // Update the state
    
};




const onRemoveHour = (date, hour) => {
    // Clone the current state to a new map to ensure immutability
    const newSelectedHours = new Map(selectedHours);
    
    // Check if the given date exists in the map
    if (newSelectedHours.has(date)) {
      // Get the array of hours for the date
      const hoursForDate = newSelectedHours.get(date);
      // Filter out the hour to be removed
      const updatedHours = hoursForDate.filter(h => h !== hour);
      
      if (updatedHours.length > 0) {
        // If there are still hours left, update the map
        newSelectedHours.set(date, updatedHours);
      } else {
        // If no hours are left for the date, remove the date from the map
        newSelectedHours.delete(date);
      }
      
      // Update the state with the new map
      setSelectedHours(newSelectedHours);
    }
  };
  

  
const isHourSelected = (hour) => {
    // console.log('selectedDate', selectedDate.toDate().toDateString());
    const hoursForDate = selectedHours.get(selectedDate) || [];
    return hoursForDate.includes(hour);
};

const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌚' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };
  
 

  return (
    <>

    <TimeZoneSelector timeZoneState={userTimeZoneState} onTimeZoneChange={handleTimeZoneChange}/>
    {tzChanged && <Typography variant='body2'>Please note: If you change the timezone after selecting time slots, you may need to deselect and reselect them.</Typography>}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar 
        value={selectedDate}
        loading={isLoading}
        onChange={handleDateClick}
        slots={{
            day: ServerDay,
            }}
            slotProps={{
            day: {highlightedDays},
            // day: {currmonhighdays},
            }}
        disablePast
        maxDate={dayjs(maxDate)}
        onMonthChange={handleMonthChange}
        sx={{width: '100%' }}/>
    </LocalizationProvider>
    <div>
       
        {hoursOfSD?.map((hour) => {
            // console.log('hour', hour, 'isHoursSelected', isHourSelected(hour));
        return (
              
              <div key={hour} >
                <Paper
                    onClick={() => handleTimeSlotClick(hour)}
                    style={{
                      padding: '10px',
                      marginBottom: '5px',
                      textAlign: 'center',
                      width: '100px',
                      height: '30px',
                      backgroundColor: (() => {
                        const hoursForDate = selectedHours.get(selectedDate.toDate().toDateString()) || [];
                        const isHourHighlighted = hoursForDate.includes(hour);
                      
                        if (isHourHighlighted || isHourSelected(hour)) {
                          return '#ADD8E6'; //#4caf50 Use the same background color for both clicked and highlighted
                        } else {
                          return '#ffffff'; // Default background color when not clicked or highlighted
                        }
                      })(),
                      cursor: 'pointer', 
                    }}
                  >
                    <Typography variant="body2"> {hour}:00 - {hour + 1}:00 </Typography>
                    
                </Paper>
            </div>
        );
    })}
    </div>

    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', paddingTop: '10px' }}>
      {Array.from(selectedHours.entries()).map(([date, hours]) => (
        hours.map((hour, index) => (
            <Chip
              key={`${date}-${hour}-${index}`}
              label= {`${date}: ${hour}:00 - ${hour + 1}:00`}
              size="small"
              onDelete={() => onRemoveHour(date, hour)}
              style={{ cursor: 'pointer' }}
            />
        ))
      ))}
    </div>



    </>
  );
}

