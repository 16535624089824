import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { API } from 'aws-amplify';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { CircularProgress } from '@mui/material';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51P3KjIEHH3eI15xPJWUyoMWui5GlEwPny1qIsJhKufiN0yO4RQ1GOlzLOHiARWdTosQHeJ34XeHIfrynP7wCUy6V00W2VjGzHg');

export default function CheckoutPage() {
    const [clientSecret, setClientSecret] = useState("");
    const location = useLocation();
    const { totalCharge, orderID } = location.state || {};
    const totalChargeInCents = totalCharge * 100;
  //   const ws = new WebSocket('wss://i9drpf2n60.execute-api.us-east-1.amazonaws.com/production/');

  //   ws.onmessage = function(event) {
  //       const data = JSON.parse(event.data);
  //       if (data.message === 'Slot Released') {
  //           alert(`Slot ${data.slotId} has been released. Please update your cart.`);
  //           // Optionally, reload the cart or redirect the user
  //       }
  //   };

  //   ws.onopen = function() {
  //     console.log('WebSocket connection established');
  //     // Send slot info and any other required data right after opening the connection
  //     const slotInfoJson = sessionStorage.getItem('timeSlotsWhrid');
  //     const slotInfo = slotInfoJson ? JSON.parse(slotInfoJson) : null;

  //     if (slotInfo) {
  //         // Send slot info and any other required data right after opening the connection
  //         ws.send(JSON.stringify({ action: 'register', data: slotInfo }));
  //     } else {
  //         console.log('No slot information found in sessionStorage');
  //     }
  // };
  
    
  //   ws.onerror = function(error) {
  //       console.error('WebSocket error:', error);
  //   };
    
  //   ws.onclose = function() {
  //       console.log('WebSocket connection closed');
  //   };


    useEffect(() => {
      let isMounted = true;
      // creating paymentIntent in paymentfunc
      const fetchPaymentIntent = async () => {
        
        const requestBody = {
            body: {
                amount: totalChargeInCents, // Example amount in your smallest currency unit (e.g., cents for USD)
                orderID: orderID
            },
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // Call your API endpoint that creates a PaymentIntent and returns its client secret
        const response = await API.post('paymentapi', "/payment", requestBody);
        // console.log('response from stripe,', response);
        if (isMounted) {

        setClientSecret(response.client_secret);
        sessionStorage.setItem('pi_id', response.payment_id);
        }

      };
  
      // Call the async function
      fetchPaymentIntent();
      return () => { isMounted = false };
    }, []); // Empty dependency array means this effect runs once on mount
  
    // Ensure stripePromise and clientSecret are ready before rendering the Elements wrapper
    if (!clientSecret) {
      // return a centered div with a loading spinner using CircularProgress component with message Loading..., please do not close the browser
      return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}><CircularProgress /><h1>Loading...</h1></div>;
    }
  
    const appearance = {
        theme: 'stripe',
      };

    const options = {
      // passing the client secret obtained from the server
      clientSecret: clientSecret,
      appearance: appearance
    };
  
    return (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm amountInDollars={totalCharge} />
      </Elements>
    );
  }
  