import React, { useState, useEffect } from 'react';
import '../styles/ProfileCard.css'; // Assuming you will create a CSS file for styles
import { getSignedUrl } from '../utils/s3utils';



const ProfileCard = ({ profile, onMoreInfoClicked }) => {

    const [photoSignedURL, setPhotoSignedURL] = useState('');

    useEffect(() => {
        let isMounted = true; // Flag to track component's mount status
      
        if (profile.photoname) {
          const filename = profile.photoname; // Assuming 'res[0].photoname' was a mistake and you meant 'profile.photoname'
      
          getSignedUrl(filename, profile.userid)
            .then((signedUrl) => {
              if (isMounted) { // Only update state if component is still mounted
                setPhotoSignedURL(signedUrl);
              }
            })
            .catch((error) => {
              console.error('Error getting signed URL:', error);
            });
        }
      
        return () => {
          isMounted = false; // Set the flag to false when the component unmounts
        };
    }, [profile]); // Assuming 'profile' is a prop or state that might change
      


    return (
        <div className="profile-card" onClick={() => onMoreInfoClicked(profile)} tabIndex="0">
            <div className="profile-photo-container">
                {/* <img src={profile.photoUrl} alt={profile.name} className="profile-photo" /> */}
                {photoSignedURL ? 
                        <img src={photoSignedURL} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    :null}
            </div>
            <div className="profile-info">
                <h3 className="profile-name">{profile.preferredN}</h3>
                {/* <p className="profile-keywords">{profile.addtl}</p> */}
                <p className="profile-keywords">
                {
                    profile.addtl.split(' ').length > 5
                    ? `${profile.addtl.split(' ').slice(0, 5).join(' ')}...`
                    : profile.addtl
                }
                </p>
            </div>
            {/* <div className="profile-actions">
                <button className="more-info-btn" onClick={() => onMoreInfoClicked(profile)}>More Info</button>
            </div> */}
        </div>
    );
};

export default ProfileCard;
