import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ChangeApptOptions = ({ onReschedule, onCancel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReschedule = (event) => {
    handleClose();
    onReschedule(event);
  };


  const handleCancel = (event) => {
    handleClose();
    onCancel(event);
  };

  return (
    <div>
      <IconButton onClick={handleClick} 
      style={{ 
          padding: 0, 
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleReschedule}>Reschedule</MenuItem>
        <MenuItem onClick={handleCancel}>Cancel</MenuItem>
      </Menu>
    </div>
  );
};

export default ChangeApptOptions;
