import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import "@awsui/global-styles/index.css";
import App from './App';
import { CartProvider } from './components/CartContext';

ReactDOM.render(
    //<HashRouter>
    <BrowserRouter>
        <App/>
    </BrowserRouter>,

    //</HashRouter>,
    document.getElementById('root')
);
