
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, Auth} from 'aws-amplify';
import '../styles/ProfileCard.css'; // Assuming you will create a CSS file for styles
import { Button, Grid } from '@mui/material';
import { Flex } from '@aws-amplify/ui-react';
import { getSignedUrl } from '../utils/s3utils';
import { Chip, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/Loading.css';
import DataTable from './DataComponent';
import { useCart } from './CartContext';
import AvailabilityUserSee from './AvailabilityUserSee';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import { 
  getTZ,
  sessionDict, 
  findMatchingKeys2, 
  transformToMapForSessions, 
  transformToUTCDateTimeStrings,
  transformToUTCDateTimeStringswTZ,
  transformToReadableDateTimeStrings,
 } from '../utils/objectUtils';

const ProfileOverlay = ({ profile, onBookNow}) => {

    const [photoSignedURL, setPhotoSignedURL] = useState('');
    const [selectedChips, setSelectedChips] = useState([]);
    const [selectedServData, setSelectedServData] = useState(null);
    const [showAvailButtonClicked, setShowAvailButtonClicked] = useState(false);

    const [isBooking, setIsBooking] = useState(false); // State to manage booking loading
    // const [isLoading, setIsLoading] = useState(true);
    const [selectedTS, setSelectedTS] = useState(new Map());
    // In AvailabilityUserSee
    const [selectedHours, setSelectedHours] = useState(new Map());

    // const matchingKeys = findMatchingKeys(profile, sessionDict);
    const servicesPartOne = findMatchingKeys2(profile, sessionDict);
    const { addToCart } = useCart();
    const [product, setProduct] = useState([]);
    const [studentTZ, setStudentTZ] = useState(''); // Initialize timezone state
    
    // console.log('profile.otherSessions', profile.otherSessions);
    const sessions = profile.otherSessions?.map(session => transformToMapForSessions(session));
    // console.log('profile,', profile);
    // const hrtimeZone = getTZ(profile.tz);
    const hrtimeZone = profile.tz ? getTZ(profile.tz) : '';
    // console.log("sessions,", sessions);
    const allServs = [...servicesPartOne, ...(Array.isArray(sessions) ? sessions : [])];
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [userSub, setUserSub] = useState('');
    const [userid, setUserId] = useState('');
    
    const handleDiaglogClose = () => {
      setOpen(false);
    };


    

    useEffect(() => {
        getUserSub().then(sub => {
            setUserSub(sub);
        });

        getCognitoIdentityId().then(cognitoID => {
            setUserId(cognitoID);
        });
    }, []);
  

    const findServiceDetailsForCart = (service) => {
      const foundServ = allServs.find(serv => serv.has(service));
   
      const numSess = foundServ ? parseFloat(foundServ.get(service).numSess) : undefined;
      const timelength = foundServ ? parseFloat(foundServ.get(service).appointmentLength) : undefined;
      const chargePerSess = foundServ ? parseFloat(foundServ.get(service).charge) : undefined;
      
      const totalHours = Array.from(selectedHours.values()).reduce((acc, hoursArray) => acc + hoursArray.length, 0);
      
      if (totalHours !== numSess){
        // console.log(`please select equal number of hours to number of sessions in ${service}`);
        
        setMessage(`Please select ${numSess} session(s) for ${service}.`);
        setOpen(true);
        // alert(`Please select ${numSess} sessions for ${service}.`);
        return {}; // Exit the function early
      } else {
        console.log('Please proceed to booking');
      }

      // const meetingHours = transformToUTCDateTimeStrings(selectedHours);
      const meetingHours = transformToUTCDateTimeStringswTZ(selectedHours, studentTZ);
      
      const meetingTimes = transformToReadableDateTimeStrings(selectedHours);
      let totalCost;
      if (typeof chargePerSess !== 'undefined' && typeof numSess !== 'undefined') {
          totalCost = chargePerSess * numSess;
      } else {
          // Handle the case where one or both variables are undefined
          totalCost = 0; // Or any other fallback value or logic appropriate for your application
      }

      const meetingDetails ={
        hrid: profile.userid,
        serv: service,
        timelen: timelength,
        chargePS: chargePerSess,
        total: totalCost,
        numSession: numSess,
        meetHours: meetingHours, // hours is utc time
        meetTimes: meetingTimes, // times is local time
        hrsub: profile.sub,
        hrName: profile.preferredN,
        hrTZ: hrtimeZone,
        stuTZ: studentTZ,
        stusub: userSub,// this is new 4/22
        stuid: userid, // this is new 4/22
        id: uuidv4() // okay, this id is used in cartdropdown as key
      }

      return meetingDetails;

    };

    const handleAddToCart = () => {
      const service = selectedChips[0];
      const product = findServiceDetailsForCart(service);
      console.log('service, product,', service, product);
      if (product && product.serv) {
        addToCart(product);
        // Dispatch a custom event to notify the application that the cart has been updated
        // window.dispatchEvent(new CustomEvent('cartUpdated'));
      } else {
        console.log("Product is empty. Not adding to cart.");
        // Optionally, handle this case in the UI, e.g., by showing an error message or feedback to the user.
      }
      // Dispatch a custom event to notify the application that the cart has been updated
      // window.dispatchEvent(new CustomEvent('cartUpdated'));
    };
    
    const handleChipClick = (label) => {
      // If the clicked label is already selected, deselect it
      if (selectedChips.includes(label)) {
        setSelectedChips([]);
        setSelectedServData(null); // Clear selected data if no chip is selected
      } else {
        // Find the map that contains the clicked label as a key
        const selectedMap = allServs.find(map => map.has(label));
        if (selectedMap) {
          // Extract the value (the attributes object) associated with the label in the selected map
          const dataForTable = selectedMap.get(label);
          setSelectedServData(dataForTable);
        }
    
        // Select the new chip and deselect any previously selected chips
        setSelectedChips([label]);
      }
    
    };
    
    const handleSelectedHoursChange = (hours) => {
      setSelectedHours(hours);
    };

    const handleStudentTimezoneChange = (tz) => {
      setStudentTZ(tz); // Update timezone state
    };

    const availToggleCard = () => {
      setShowAvailButtonClicked(!showAvailButtonClicked);
    };

    const handleBookAppt = () => {
      handleAddToCart();
      navigate('/cart');
    }

  //   const handleBookAppt = () => {
  //     // Combine selectedHours with other data here and submit
      
  //     const service = selectedChips[0];
  //     const foundServ = allServs.find(serv => serv.has(service));
   
  //     const numSess = foundServ ? parseFloat(foundServ.get(service).numSess) : undefined;
  //     const timelength = foundServ ? parseFloat(foundServ.get(service).appointmentLength) : undefined;
  //     const chargePerSess = foundServ ? parseFloat(foundServ.get(service).charge) : undefined;
      
  //     const totalHours = Array.from(selectedHours.values()).reduce((acc, hoursArray) => acc + hoursArray.length, 0);
      
  //     if (totalHours !== numSess){
  //       // console.log(`please select equal number of hours to number of sessions in ${service}`);
  //       alert(`Please select ${numSess} sessions for ${service}.`);
  //       return; // Exit the function early
  //     } else {
  //       console.log('Please proceed to booking');
  //     }

  //     setIsBooking(true);
  //     let totalCost;
  //     if (typeof chargePerSess !== 'undefined' && typeof numSess !== 'undefined') {
  //         totalCost = chargePerSess * numSess;
  //     } else {
  //         // Handle the case where one or both variables are undefined
  //         totalCost = 0; // Or any other fallback value or logic appropriate for your application
  //     }

  //     const meetingHours = transformToUTCDateTimeStrings(selectedHours);
      
  //     const meetingTimes = transformToReadableDateTimeStrings(selectedHours);

  //     const meetingDetails ={
  //       hrid: profile.userid,
  //       serv: service,
  //       timelen: timelength,
  //       total: totalCost,
  //       numSession: numSess,
  //       meetHours: meetingHours, // hours is utc time
  //       meetTimes: meetingTimes, // times is local time
  //       hrsub: profile.sub,
  //       hrName: profile.preferredN,
  //       hrTZ: hrtimeZone,
  //       stuTZ: studentTZ
  //     }

  //     createMeeting(meetingDetails, (meetingID) => {
  //       console.log('Meeting ID received:', meetingID);
  //       // Construct meeting details including the received meetingID
  //       const newAppointmentDetails = {
  //         // newAppointmentDetails include more information, meetingDetails are being sent to API
  //         ...meetingDetails,
  //         meetingId: meetingID,
  //         // other details
  //       };
  //       console.log('newApptDetails,', newAppointmentDetails);
  //       setIsBooking(false);
  //       onBookNow(newAppointmentDetails);
  //     });

  //     console.log('isBooking,', isBooking);
    

  // };

// function createMeeting(meetingDetails, callback) {
//   API.put('meetingsapi', '/meetings', {
//     body: meetingDetails,
//   })
//   .then(response => {
//     console.log('Success:', response.success);
//     if (callback && typeof callback === 'function') {
//       callback(response.meetingID);
//     }
//   })
//   .catch(error => {
//     console.error('Error creating meeting:', error);
//   });
// }

    const onRemoveService = (label) => {
      if (selectedChips.includes(label)) {
        setSelectedChips([]);
        setSelectedServData(null); // Clear selected data if no chip is selected
      } else {
        // Find the map that contains the clicked label as a key
        const selectedMap = allServs.find(map => map.has(label));
        if (selectedMap) {
          // Extract the value (the attributes object) associated with the label in the selected map
          const dataForTable = selectedMap.get(label);
          setSelectedServData(dataForTable);
        }
    
        // Select the new chip and deselect any previously selected chips
        setSelectedChips([label]);
      }
    }

    
  useEffect(() => {
      let isMounted = true; // Flag to track component's mount status
    
      if (profile.photoname) {
        const filename = profile.photoname; // Assuming 'res[0].photoname' was a mistake and you meant 'profile.photoname'
    
        getSignedUrl(filename, profile.userid)
          .then((signedUrl) => {
            if (isMounted) { // Only update state if component is still mounted
              setPhotoSignedURL(signedUrl);
            }
          })
          .catch((error) => {
            console.error('Error getting signed URL:', error);
          });
      }
    
      return () => {
        isMounted = false; // Set the flag to false when the component unmounts
      };
  }, [profile]); // Assuming 'profile' is a prop or state that might change

  useEffect(() => {
    // const timeZone = getProviderTZ(profile.tz);
    let isMounted = true;
    API.get('availapi', `/slots/${profile.userid}`).then((res) => {
      
      if (res.length>0 && isMounted) {
        // const resultMap = transformAvailSlotsDBArrToMapArr(res, timeZone);
        // console.log(resultMap);
        setSelectedTS(res);
      }
      // setIsLoading(false);
      
    });

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, []);

    return (

      <div style={{ position: 'relative' }}>

      {/* {
          isBooking && (
            <div className="loadingOverlay">
              <CircularProgress />
            </div>
          )
        } */}
      
      <Dialog open={open} onClose={handleDiaglogClose}>
        <DialogTitle>Unmatching Session Number</DialogTitle>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiaglogClose}>OK</Button>
        </DialogActions>
      </Dialog>

      <div >
        
          <div className="overlay-content" style={{ display: 'flex', flexDirection: 'column'}}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              // columns={{ xs: 12, sm: 12, md: 12 }} // each column takes 4, 8, and 12 for xs, sm, md spacing??
            >
              <Grid container  justifyContent="center"
                  alignItems="flex-start" item xs={12} sm={4} md={4} lg={4} >
                <div className="overlay-photo-container" style={{ flex: '0 0 auto' }}>
                    {photoSignedURL ?
                        <img src={photoSignedURL} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                        : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} >
                <Flex direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                    sx={{paddingLeft:10}}>

                  {/* <div> 
                    <Typography variant="h6" gutterBottom>
                      {profile.preferredN}
                    </Typography>
                  </div> */}
                                    <Grid   container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start">
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {profile.preferredN}
                        </Typography>
                    </Grid>
                    <Grid item>
                    {profile.title && (
                        <Grid item>
                            <Typography variant="body1" gutterBottom color="text.secondary">
                                {profile.title}
                            </Typography>
                            
                        </Grid>
                    )}
                    </Grid>
                    <Grid item>
                    {profile.intro && (            
                        <Typography variant="body1" gutterBottom color="text.secondary">
                          {profile.intro}
                        </Typography>
                    )}
                    </Grid>
                </Grid>
                  
                  
                  <Typography variant="h6" gutterBottom>
                  {
                      // Adding text based on the role
                      profile.role === "0" || profile.role === "1"
                      ? <span> — Has experience in hiring for various roles: <br /> {profile.addtl}</span>
                      : profile.role === "2"
                      ? <span> — Has expertise in: <br /> {profile.addtl}</span>
                      : ""
                  }
                  </Typography>

                  {/* <div>
                    <Typography variant="body1" gutterBottom>{profile.addtl}</Typography>
                  </div> */}
                  <div>
                    <Typography variant="h6" gutterBottom>Services provided:</Typography>
                  </div>

                  <div style={{ width: '100%' }}>
                  
                      <Flex direction='column' gap='2px'>
                        
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          direction="row"
                          // columns={{ xs: 12, sm: 12, md: 12 }} // each column takes 4, 8, and 12 for xs, sm, md spacing??
                        >
                        {allServs.map((map, index) => {
                          // Assuming each map has only one key-value pair, extract the key
                          const [label] = Array.from(map.keys());
                          return (
                            <Grid item xs={12} sm="auto" md="auto" lg="auto" key={index}>
                            <Chip
                              key={index} // Using index as key because label might not be unique across all maps
                              label={label}
                              onClick={() => handleChipClick(label)}
                              variant={selectedChips.includes(label)? 'filled': 'outlined'}
                              size="small"
                              color='info'
                            />
                            </Grid>
                          );
                        })}
                        </Grid>
                        

                      </Flex>
                      <Flex>
                        {selectedServData && <DataTable data={selectedServData} />}
                      </Flex>

                  </div>

                    
                  
                </Flex>
                </Grid>
              </Grid>

              <div aria-label="avail-user-see" style={{ display: 'flex', flexDirection:'column', gap: '10px'}}>
            
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                          <Button onClick={availToggleCard} startIcon={showAvailButtonClicked ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                            {showAvailButtonClicked ?  'Collapse': 'Check Availability'}
                          </Button>
                        </div>    
                        
                        
                        <div>                            
                          {showAvailButtonClicked && (
                            // <div style={{ position: 'relative' }}>

                            // {
                            //   isLoading && (
                            //     <div className="loadingOverlay">
                            //       <CircularProgress />
                            //     </div>
                            //   )
                            // }
                
                              <div>
                                {/* <TimeZoneSelector timeZoneState={userTimeZoneState} onTimeZoneChange={handleTimeZoneChange}/> */}
                                <AvailabilityUserSee dynamodbSTS={selectedTS} 
                                    // timeZoneState={profile.tz} 
                                    onStudentTimezoneChange={handleStudentTimezoneChange}
                                    onSelectedHoursChange={handleSelectedHoursChange}
                                    />
                              </div>
                            // </div>
                            )}

                        </div>  
                            
                        <div>
                          {selectedChips.map((chip, index) => (
                              <Chip
                                key={`${chip}-${index}`}
                                label= {chip}
                                size="small"
                                onDelete={() => onRemoveService(chip)}
                                style={{ cursor: 'pointer' }}
                              />
                            ))}

                        </div>

                        
            
              </div>


          </div>
        
        <Flex direction='row' justifyContent='flex-end'>
            <Button variant="contained" style={{width:'150px'}} onClick={handleAddToCart}>Add to Cart</Button>
            <Button variant="contained" style={{width:'150px'}} onClick={handleBookAppt}>Book Now</Button>
        </Flex>

      </div>
    </div>
    );
  };

async function getUserSub() {
    try {
        const session = await Auth.currentSession();  // Retrieve the current session
        const idToken = session.getIdToken();          // Get the ID Token
        const sub = idToken.payload.sub;               // Access the 'sub' from the ID token payload
        return sub;
    } catch (error) {
        console.error('Error getting user sub', error);
    }
}

async function getCognitoIdentityId() {
  try {
      // This will return credentials (AWS STS temporary credentials) for the logged-in user
      const credentials = await Auth.currentCredentials();
      
      // This will give you the identityId
      const identityId = credentials.identityId;
      
      console.log('Cognito Identity ID:', identityId);
      return identityId;
  } catch (error) {
      console.error('Error getting Cognito Identity ID', error);
  }
}

  export default ProfileOverlay;