// LobbyPage.js
import React, { useEffect } from 'react';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import { useDyteClient } from '@dytesdk/react-web-core';


function Lobby() {
  const [meeting, initMeeting] = useDyteClient();

  useEffect(() => {
    const handleMessage = (event) => {
      // Check the origin here to ensure the message is from your trusted domain
      // if (event.origin !== 'YOUR_DOMAIN') return;
  
      if (event.data.type === 'MEETING_TOKEN') {
        const { token } = event.data;
        // Use the token to initialize the meeting here
        console.log('Received token:', token);
        if (!token) {
            alert(
              "An authToken wasn't passed, please pass an authToken in the URL query to join a meeting."
            );
            return;
          }
        initMeeting({
            authToken:token,
          });
        // For Dyte: initMeeting({ authToken: token });
      }
    };
  
    // Add event listener to receive messages
    window.addEventListener('message', handleMessage);
  
    // Clean up event listener
    return () => window.removeEventListener('message', handleMessage);
  }, []);



  return (
    <div>
     <DyteMeeting meeting={meeting} />
    </div>
  );
}

export default Lobby;
