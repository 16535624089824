import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router-dom';
import AvailabilityUserSee from '../components/AvailabilityUserSee';
// import AvailabilityReschedule from '../components/AvailabilityReschedule';
import { Flex } from '@aws-amplify/ui-react';
import { Button, Typography } from '@mui/material';
import { transformUtcToLocal } from '../utils/objectUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Paper, Chip, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';

const TimeChips = ({ timeArray, userTZ, onDelete }) => {
  return (
    <Stack direction="row" spacing={1}>
      {timeArray.map((time, index) => (
        <Chip
          key={index}
          label={`${time} (${userTZ})`}
          onDelete={() => onDelete(index)}
          deleteIcon={<DeleteIcon style={{ color: grey[500] }} />}
          sx={{ bgcolor: grey[200] }}
        />
      ))}
    </Stack>
  );
};

const TimeChipsFromMap = ({ timeMap, userTZ }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', paddingTop: '10px' }}>
      {timeMap.size === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No selected time slots.
        </Typography>
      ) : (
        [...timeMap.entries()].map(([key, value]) => (
          <Chip
            key={key}
            label={`${key}: ${value}:00 (${userTZ})`}
            sx={{ bgcolor: 'white', margin: '4px' }}
          />
        ))
      )}
    </Box>
  );
};


const RescheduleAppointmentPage = () => {
  const { appointmentId } = useParams();
  const [hrid, setHrid] = useState('');
  const [selectedTS, setSelectedTS] = useState([]);
  const [studentTZ, setStudentTZ] = useState(''); // Initialize timezone state
  const [selectedHours, setSelectedHours] = useState(new Map());
  const [originalHours, setOriginalHours] = useState([]);
  const [newOriginalHours, setNewOriginalHours] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [deletedCount, setDeletedCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;

    const fetchAppointment = async () => {
      try {
        const res = await API.get('meetingsapi', `/meetings/meeting-details/${appointmentId}`);
        console.log('fetched meeting', res);
        if (isMounted) {
          setHrid(res.hrid);
          setOriginalHours(transformUtcToLocal(res.meetHours, res.stuTZ));
          console.log('original hours:', transformUtcToLocal(res.meetHours, res.stuTZ));
          // console.log('HR ID:', res.hrid);
          console.log('original hours:', res.meetHours);
        }
      } catch (error) {
        console.error('Error fetching appointment data', error);
      }
    };

    fetchAppointment();

    return () => {
      isMounted = false;
    };
  }, [appointmentId]);

  useEffect(() => {
    let isMounted = true;
    if (hrid) {
      const fetchHRDetails = async () => {
        try {
            API.get('availapi', `/slots/${hrid}`).then((res) => {
      
                if (res.length>0 && isMounted) {
                  // const resultMap = transformAvailSlotsDBArrToMapArr(res, timeZone);
                  // console.log(resultMap);
                  setSelectedTS(res);
                  setIsLoading(false);
                }
                // setIsLoading(false);
                
              });
        } catch (error) {
          console.error('Error fetching HR details', error);
        }
      };

      fetchHRDetails();
    }

    return () => {
        isMounted = false;
      };
  }, [hrid]);

  const handleSelectedHoursChange = (hours) => {
    console.log('hours,', hours);
    setSelectedHours(hours);
  };

  const handleStudentTimezoneChange = (tz) => {
    setStudentTZ(tz); // Update timezone state
  };

  const handleOldHoursChange = (hours) => {
    setNewOriginalHours(hours);
  };

  const handleSlotsChange = () => {
    // upload to API
  };

  const handleCancel = (e) => {
    // redirect to previous page
    e.preventDefault();
    // Logic for rescheduling the session
    
    navigate(`/orders`);
  };

  const handleDelete = (index) => {
    setOriginalHours(originalHours.filter((_, i) => i !== index));
    setDeletedCount(deletedCount + 1);
  };

  return (
    <div>
      {/* <Typography variant="h6">If you want to keep any original hours:</Typography>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TransferList oldtimeslots={originalHours} />
      )} */}
    <Paper elevation={1} sx={{
          padding: '16px',
          margin: '16px',
        }}>

    
      <Paper elevation={0} sx={{
          backgroundColor: 'lightblue',
          padding: '16px',
          margin: '16px',
        }}>
        <Flex direction="column" gap="1rem"> 
          <Typography variant='h6'>Original selections (delete the ones you don't want):</Typography>
          {isLoading ?(
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
              <CircularProgress />
            </Box>
          ): (<TimeChips timeArray={originalHours} userTZ={studentTZ} onDelete={handleDelete} />)}
        </Flex>
      </Paper>
      
        {deletedCount > 0 && (<Paper elevation={0} sx={{
            backgroundColor: '#e8f5e9',
            padding: '16px',
            margin: '16px',
          }}>
          <Flex direction="column" gap="1rem">
            
              <div>
                <Typography variant='h6'>Please select {deletedCount} slot(s) from below. Selected hours:</Typography>
                <TimeChipsFromMap timeMap={selectedHours} userTZ={studentTZ} /> 
              </div>
          
          </Flex>
        </Paper> )}


        <Flex direction='row' justifyContent='flex-end'>
            <Button variant="outlined" style={{width:'150px'}}  onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" style={{width:'150px'}} onClick={handleSlotsChange}>Confirm</Button>
        </Flex>
      </Paper>

        <div>
            <AvailabilityUserSee dynamodbSTS={selectedTS} 
                onStudentTimezoneChange={handleStudentTimezoneChange}
                onSelectedHoursChange={handleSelectedHoursChange}
                />
        </div>

        
      
    </div>
  );
};

export default RescheduleAppointmentPage;
