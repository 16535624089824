import React from 'react';
import { Divider, Text} from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

function Newsletter(props) {
    let navigate = useNavigate()
    return (
        <div id="comingsoon">
            <img aria-label='forest' src="../forest.jpg"/>
            
            {/* <button id="logotextbutton" onClick={() => 
            {navigate("/")}}
            > 
            idyllic.ai</button> */}

            <div>
                <Text fontFamily='Recoleta Regular' fontStyle='sans-serif' fontSize="6vh" color='white'>COMING SOON</Text>
                <hr width="200"/>
                <Text fontFamily='Recoleta Regular' fontStyle='sans-serif' fontSize="2vh" color='white'>News: Matched jobs with your resume is done, try it out! 4/2/2023</Text>
            </div>
        </div>
    );
}

export default Newsletter;