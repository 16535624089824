import React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import SideNavigation from '@awsui/components-react/side-navigation';
import AppLayout from '@awsui/components-react/app-layout';



const appLayoutLabels = {
    navigation: 'Side navigation',
    navigationToggle: 'Open side navigation',
    navigationClose: 'Close side navigation',
    notifications: 'Notifications',
    tools: 'Help panel',
    toolsToggle: 'Open help panel',
    toolsClose: 'Close help panel'
};



const ServiceNavigation = () => {
    const location = useLocation();
    let navigate = useNavigate();

    function onFollowHandler(event) {
        if (!event.detail.external) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    }

    return (
        <SideNavigation
            activeHref={location.pathname}
            header={{href: "/", text: "idyllic.ai"}}
            onFollow={onFollowHandler}
            items={[
                {type: "link", text: "Home", href: "/"},
                {type: "link", text: "Newsletter", href: "/newsletter"}
            ]}
        />
    );
}

const Content = () => {
    return(
        <Outlet />
    )
}


const AlluserLayout = () => {
    
    return (
        <>
        {/* <AppLayout
            content={<Content/>}
            headerSelector='#navbar'
            toolsHide={true}
            navigation={<ServiceNavigation/>}
            navigationHide={false}
            ariaLabels={appLayoutLabels}
        /> */}
        <Content />

        </>
    );
}

export default AlluserLayout;