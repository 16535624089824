import { Storage } from 'aws-amplify';
export const getSignedUrl = async (filename, targetIdentityId) => {
    try {
        const config = {
        level: 'protected', // defaults to `public`
        identityId: targetIdentityId, // id of another user, if `level: protected`
        download: false, // defaults to false
        expires: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        validateObjectExistence: true, // defaults to false
        cacheControl: undefined, // Specifies caching behavior along the request/reply chain
        };
    
        const signedUrl = await Storage.get(filename, config);
        //  const signedUrl = await Storage.get(filename, { level: 'protected', validateObjectExistence: true, identityId: targetIdentityId });


        return signedUrl;
    } catch (error) {
        console.error('Error generating signed URL:', error);
        throw error;
    }
    };