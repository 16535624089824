import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import SideNavigation from '@awsui/components-react/side-navigation';
import AppLayout from '@awsui/components-react/app-layout';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth } from 'aws-amplify';
import TopNavigation from "@awsui/components-react/top-navigation"
import { AppBar, Toolbar, Typography, Box, IconButton, Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { useCart } from './CartContext';
import { styled } from '@mui/material/styles';
import AccountDropdown from './AccountDropdown';
import CartDetailsDropdown from './CartDetailsDropdown';
import { CheckboxField, Text } from '@aws-amplify/ui-react';
import { CartProvider } from './CartContext';
import { Hub } from 'aws-amplify';

import {
    Authenticator,
    useAuthenticator,
    useTheme,
    Image,
    View
} from '@aws-amplify/ui-react';
import CustomLeftDrawer from './CustomLeftDrawer';


const Main = styled('main')(
    ({ theme }) => ({
      flexGrow: 1,
      padding: theme.spacing(1),
      paddingTop: theme.spacing(8), // Adjust this value based on your AppBar's actual height
      transition: theme.transitions.create(['margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // Removed marginLeft and conditional logic related to `open`
    }),
  );
  





const Content = React.memo(() => {
    return <Outlet />;
  });



const VeuserLayout = () => {
    // const navbarItemClick = e => {
    //     console.log(e);
    //     if (e.detail.id === 'signout') {
    //         Auth.signOut().then(() => {
    //             window.location.reload();
    //         });
    //     }
    // }

const handleSignOut = () => {
    Auth.signOut().then(() => {
        window.location.reload();
    });
} 

const [isAdmin, setIsAdmin] = useState(false);
const [items, setItems] = useState([]);
const [isAuthenticated, setIsAuthenticated] = useState(false);
// const { cart } = useCart();
// const [cartItemCount, setCartItemCount] = useState(0);

useEffect(() => {
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
        console.log('user signed in');  // User is signed in
        setIsAuthenticated(true);
        break;
      case 'signOut':
        console.log('user signed out');  // User is signed out
        setIsAuthenticated(false);
        break;
      default:
        break;
    }
  });

  return () => Hub.remove('auth');  // Clean up the listener when the component unmounts
}, []);


useEffect(() => {
    async function checkAdminStatus() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
        console.log('user is admin', groups.includes('admin'));
        setIsAdmin(groups.includes('admin'));
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    }

    if (isAuthenticated) {
      checkAdminStatus();
    }
    

    // const item_arr = [
    //     {id: 3, type: "link", text: "Historical Job Matches (Available 4/25)", href: "/match" },
    //     !isAdmin && {id: 7, type: "link", text: "Ask an HR", href: "/askhr" },
    //     !isAdmin && {id: 8, type: "divider" },
    //     isAdmin && { id: 9, type: "link", text: "Admin dashboard", href: "/admindash" }
    //   ];
    // Base items that appear for all users
    const baseItems = [
        {id: 3, type: "link", text: "Historical Job Matches", href: "/match", icontype: "jobMatching" },
       {id: 33, type: "divider"}
       
    ];

    const baseItemsBottom = [
         {id: 4, type: "link", text: "Early Access: AI Career Concierge", href: "/careerconcierge", icontype: "newFeature"},
         {id: 44, type: "divider"},
        {id: 5, type: "link", text: "Feedback", href: "/feedback", icontype: "feedback"},
        
    ]

    // Items that appear only for non-admin users
    const nonAdminItems = [
        {id: 7, type: "link", text: "Industry Mentors", href: "/askhr", icontype: "networking"},
        {id: 77, type: "divider" }
        
    ];

    // Items that appear only for admin users
    const adminItems = [
        // {id: 8, type: "divider" },
        {id: 9, type: "link", text: "Admin dashboard", href: "/admindash" },
        {id: 99, type: "divider"}
    ];

    // Construct the final array based on whether the user is an admin
    const item_arr = isAdmin ? [...baseItems, ...adminItems, ...baseItemsBottom] : [...baseItems, ...nonAdminItems, ...baseItemsBottom];
    setItems(item_arr);
    
  }, [isAuthenticated]);

    const formData = {
        signUp: {
        email: {
            order:1
        },
        password: {
            order: 2
        },
        confirm_password: {
            order: 3
        },
        
        },
    }

    const TermsAndConditionsLabel = () => (
        <span>
            I agree with the <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Terms and Conditions</a>
        </span>
        );




    return (
        <Authenticator
        formData={formData}
        components={{
            SignUp: {
              FormFields() {
                const { validationErrors, handleSignUp } = useAuthenticator();
                const [acknowledged, setAcknowledged] = useState(false);
                const [emailPreferences, setEmailPreferences] = useState('no');

                const handleEmailPreferencesChange = (e) => {
                    setEmailPreferences(e.target.checked);
                    // console.log('emailPreferences', e.target.checked);
                    formData['custom:emailPreferences'] = e.target.checked ? 'yes' : 'no';
                    // console.log('formDatacustom:emailPreferences', e.target.checked ? 'yes' : 'no');
                }

                const handleAcknowledgementChange = (e) => {
                    setAcknowledged(e.target.checked);
                    formData['custom:acknowledgement'] = 'yes';
                }
                
                return (
                  <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />
    
                    {/* Append & require Terms and Conditions field to sign up  */}
                    <CheckboxField
                      errorMessage={validationErrors['custom:acknowledgement']}
                      hasError={!!validationErrors['custom:acknowledgement']}
                      name="custom:acknowledgement"
                      value="yes"
                      label= "I agree with the Terms and Conditions"
                      onChange={handleAcknowledgementChange}
                    />
                    <CheckboxField
                      name="custom:emailPreferences"
                      value={emailPreferences}
                      label="Receive updates on career coaching, webinars, new features, and more. Unsubscribe anytime via the link in our emails."
                      onChange={handleEmailPreferencesChange}
                    />
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <div style={{ fontSize: '10px', marginBottom: '10px', marginTop: '20px' }}> <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>terms and conditions</a> </div>
                    </div>
                  </>
                );
              },
            },
            Header() {
                const { tokens } = useTheme();
            
                return (
                  <View textAlign="center" padding={tokens.space.large}>
                    <Image
                      alt="iD logo"
                      src="https://marketingemailresources.s3.amazonaws.com/logo/sign-in+logo3.png"
                    />
                  </View>
                );
              },
              Footer() {
                const { tokens } = useTheme();
            
                return (
                  <View textAlign="center" padding={tokens.space.large}>
                    <Text color={tokens.colors.neutral[80]}>
                      &copy; All Rights Reserved
                    </Text>
                  </View>
                );
              },
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData['custom:acknowledgement']) {
                return {
                    'custom:acknowledgement': 'This field is required.',
                };
              }
            },
            
          }}
        
        >
            {({signOut, user}) => (
                <>
                    {/* <div id="navbar" style={{fontSize: 'body-l !important', position: 'sticky', top: 0, zIndex: 1002}}> */}
                        {/* <TopNavigation
                            identity={{
                                href: "#",
                                title: "idyllic.ai",
                            }}
                            
                            utilities={[
                                {
                                    type: "menu-dropdown",
                                    text: user.username,
                                    description: user.username,
                                    iconName: "user-profile",
                                    onItemClick: navbarItemClick,
                                    items: [
                                        {id: "profile", text: "Profile"},
                                        {id: "preferences", text: "Preferences"},
                                        {id: "security", text: "Security"},
                                        {
                                            id: "feedback",
                                            text: "Feedback",
                                            href: "#",
                                            external: true,
                                            externalIconAriaLabel:
                                                " (opens in new tab)"
                                        },
                                        {id: "signout", text: "Sign out"}
                                    ]
                                }
                            ]}
                            i18nStrings={{
                                searchIconAriaLabel: "Search",
                                searchDismissIconAriaLabel: "Close search",
                                overflowMenuTriggerText: "More"
                            }}
                        /> */}
                    {/* </div> */}
                  <CartProvider>
                    <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'white', color: 'black' }}>
                        <Toolbar>
                            <CustomLeftDrawer items={items} />
                            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                                <span style={{
                                backgroundColor: '#e8f5e9', // A light green color from the Material UI green palette
                                padding: '0.2rem 0.5rem',
                                borderRadius: '4px',
                                color: 'black' // Or another color that matches your design
                                }}>
                                    idyllic AI 
                                </span>
                            : Your Gateway to an Idyllic Career
                            </Typography>
                            {/* <Box sx={{ mr: 2 }}>
                                <IconButton color="inherit" aria-label="shopping cart">
                                    <Badge badgeContent={cartItemCount} color="secondary">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Box> */}
                            <CartDetailsDropdown />
                            <Box sx={{ display: 'flex', alignItems: 'center', ml:2 }}>
                                <AccountDropdown handleSignOut={handleSignOut} />
                            </Box>
                            
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Main>
                      
                       <Content/> 
                      
                    </Main>
                    
                    </Box>
                  </CartProvider>
                    {/* <AppLayout
                        content={<Content/>}
                        headerSelector='#navbar'
                        navigation={<ServiceNavigation/>}
                        navigationHide={false}
                        navigationWidth="15vw"
                        toolsHide={true}
                        ariaLabels={appLayoutLabels}
                    /> */}
                </>
            )}
        </Authenticator>
    );
}

export default VeuserLayout;


  

// const appLayoutLabels = {
//     navigation: 'Side navigation',
//     navigationToggle: 'Open side navigation',
//     navigationClose: 'Close side navigation',
//     notifications: 'Notifications',
//     tools: 'Help panel',
//     toolsToggle: 'Open help panel',
//     toolsClose: 'Close help panel'
// };




// const ServiceNavigation = () => {
//     const location = useLocation();
//     let navigate = useNavigate();
//     const [isAdmin, setIsAdmin] = useState(false);

//     useEffect(() => {
//         async function checkAdminStatus() {
//           try {
//             const user = await Auth.currentAuthenticatedUser();
//             const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
//             setIsAdmin(groups.includes('admin'));
//           } catch (error) {
//             console.error('Error fetching user groups:', error);
//           }
//         }
    
//         checkAdminStatus();
//       }, []);

//     function onFollowHandler(event) {
//         if (!event.detail.external) {
//             event.preventDefault();
//             navigate(event.detail.href);
//         }
//     }

//     const items = [
//         {id: 1, type: "link", text: "Home", href: "/home" }, // Adjust href as needed
//         {id: 2, type: "divider" },
//         //{ type: "link", text: "My applications", href: "/applications" },
//         //{ type: "divider" },
//         {id: 3, type: "link", text: "Jobs match your skills", href: "/match" },
//         {id: 4, type: "divider" },
//         // {id: 5, type: "link", text: "Jobs posted today", href: "/todayjobs" },
//         // {id: 6, type: "divider" },
//         !isAdmin && {id: 7, type: "link", text: "Ask an HR", href: "/askhr" },
//         !isAdmin && {id: 8, type: "divider" },

//         //{ type: "link", text: "Post a job", href: "/postjob" },
//         //{ type: "divider" },
//         isAdmin && { id: 9, type: "link", text: "Admin dashboard", href: "/admindash" }
//       ];

    

//     return (
//         <SideNavigation
//             activeHref={location.pathname}
//             // header={{href: "/match"}}
//             onFollow={onFollowHandler}
//             items={items.filter(item => !!item)}
//         />
//     );
// }