// CartDetailsDropdown.js
import React, { useState, useEffect, useContext } from 'react';
import { Button, Menu, MenuItem, IconButton, Badge, Typography, Paper, Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';

const CartDetailsDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // console.log('useCart', useCart());
  const { cart, cartItemCount } = useCart();
  // const [cartItemCount, setCartItemCount] = useState(cart.length);
  // console.log('cart in dropdown,', cart);

  const handleCartIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate(); // Hook from React Router for navigation
  
  // useEffect(() => {
  //   const syncCartWithLocalStorage = (event) => {
  //     console.log('event key, ', event.key);
  //     if (event.key === 'userShoppingCart') {

  //       if (event.newValue) {
  //         try {
  //             const updatedCart = JSON.parse(event?.newValue);
  //       // if (storedCart === null || storedCart === undefined) {
  //       //   return {}; // Return an empty object or another sensible default
  //       // }
  //             setCartItemCount(updatedCart.length);
  //             console.log('updated count, ', updatedCart.length);
  //           } catch (error) {
  //             console.error('Error parsing JSON from storage event:', error);
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener('storage', syncCartWithLocalStorage);

  //   return () => {
  //     window.removeEventListener('storage', syncCartWithLocalStorage);
  //   };

  // }, []);

  // useEffect(() => {
  //   // Update cart item count on component mount and whenever cart changes
  //   const updateCartCount = () => {
      
  //     setCartItemCount(cart.length);
  //     console.log('how many items in cart', cart.length);
  //   };

  //   updateCartCount();
  //   // window.addEventListener('cartUpdated', updateCartCount);

  //   // return () => {
  //   //   window.removeEventListener('cartUpdated', updateCartCount);
  //   // };
  // }, [cart]);
  // useEffect(() => {
  //   const handleCartUpdated = () => {
  //     // Assuming you fetch the updated cart from somewhere, like localStorage
  //     const updatedCart = JSON.parse(localStorage.getItem('userShoppingCart')) || [];
  //     setCartItemCount(updatedCart.length); // This state update triggers a re-render
  //     console.log('new cart item count, ', updatedCart.length);
  //   };
  
  //   window.addEventListener('cartUpdated', handleCartUpdated);
  
  //   // Initial check or update
  //   handleCartUpdated();
  
  //   return () => {
  //     window.removeEventListener('cartUpdated', handleCartUpdated);
  //   };
  // }, []); // No dependencies here means the effect setup/cleanup runs once
  


  const handleEditCheckout = () => {
    navigate('/cart'); // Navigate to the Checkout page
    // window.open('/cart', '_blank'); // Open the Checkout page in a new tab
    handleClose();
  };

  const totalCost = cart.reduce((acc, item) => acc + (item.total), 0);

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="shopping cart"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleCartIconClick}
      >
        <Badge badgeContent={cartItemCount} color="secondary" sx={{
          '& .MuiBadge-badge': {
            top: -4, // Adjust these values to move the badge up
            right: -4, // Adjust these values to move the badge right
            fontSize: '0.75rem', // Makes the font size smaller
            height: '15px', // Adjust badge size
            minWidth: '15px', // Adjust badge size
          },
        }}>
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom', // Changed from 'top' to 'bottom'
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top', // This ensures the menu "grows" downward from the anchor point
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        
      >
        {cart.length > 0 ? cart.map((item, index) => (
          <MenuItem key={item.id} sx={{
            '&:hover': {
              backgroundColor: "transparent", // Keeps the background transparent on hover
              color: "inherit", // Keeps the text color the same on hover
            },
            cursor: "default"
          }}>
            {/* {item.serv} - Quantity: {item.numSession} - Total cost: {item.total} - Time: {item.meetTimes} */}
          <Paper elevation={0}>

            
            <Typography component="span" variant="body1" color="text.primary" sx={{ fontWeight: 'bold' }}>
              {item.serv} 
            </Typography> 
            <br />
            <Typography component="span" variant="body2" color="text.primary">
              Provider: {item.hrName}
            </Typography>
            {' - Time: ' + item.meetTimes.join(', ')}
            <br />
            {'Sessions: ' + item.numSession + ' | Cost per Session: $' + item.chargePS}
            <br />
            <Typography component="span" variant="body1" >
              Service Total: ${item.total}
            </Typography>

          </Paper>
          </MenuItem>
        )) : (
          <MenuItem sx={{
            '&:hover': {
              backgroundColor: "transparent", // Keeps the background transparent on hover
              color: "inherit", // Keeps the text color the same on hover
            },
            cursor: "default"
          }}>Your cart is empty</MenuItem>
        )}
        <MenuItem sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Aligns children (total and button) to the right
          alignItems: 'center', // Vertically aligns items in the middle, adjust as needed
          gap: 2, // Adds some space between the total and the button, adjust as needed
          mt: 2
          }}>
          
            <Typography variant="h6">
              Order Total: ${totalCost.toFixed(2)}
            </Typography>
            <Button onClick={handleEditCheckout} color="primary" variant="contained">
              View Cart
            </Button>
          
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CartDetailsDropdown;
