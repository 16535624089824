import React, { useState } from 'react';
import { TextField, Select, MenuItem, Button, FormControl, InputLabel, OutlinedInput, Alert, Snackbar } from '@mui/material';
import { Grid, Paper, Typography } from '@mui/material';
import { API } from 'aws-amplify';


function Feedback() {

    const [feedbackType, setFeedbackType] = useState('');
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);

    const handleFeedbackTypeChange = (event) => {
        setFeedbackType(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        API.post('feedbackApi', '/feedback', {
            body: {
                feedbackType: feedbackType,
                comment: comment
            }
        }).then(response => {
            console.log(response);
            setOpen(true);
        }).catch(error => {
            console.log(error);
        });


        // Handle form submission logic here
    };

    return (

        <Paper elevation={1} style={{padding: '2rem'}}>
           
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '4rem', paddingTop: '4rem'}}>
                       
                            <Typography variant='h5'>Got feedback? We’d love to hear from you! </Typography>
                            <Typography variant='h6' color="text.secondary">
                            Whether you’re reporting a bug, suggesting a new feature, or just sharing your thoughts, your input is invaluable to us. Help us make your experience even better!</Typography>
                        
                    </div>
                    </Grid>
                
                    <Grid item xs={12}>
                    <form  onSubmit={handleSubmit}>

                        <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '2rem', gap:'2rem'}}>
                        
                        
                        <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">Feedback Category</InputLabel>
                            <Select value={feedbackType} onChange={handleFeedbackTypeChange}>
                                <MenuItem value="newFeature">New Feature Request</MenuItem>
                                <MenuItem value="bugReport">Bug Report</MenuItem>
                                <MenuItem value="generalFeedback">General Feedback</MenuItem>
                            </Select>
                        </FormControl>
                    

                        <FormControl fullWidth required>
                            <InputLabel htmlFor="comment" id="user-comment-in-feedback-form">Comment</InputLabel>
                            <OutlinedInput
                                label="Comment"
                                multiline
                                variant='outlined'
                                rows={4}
                                value={comment}
                                onChange={handleCommentChange}
                            />
                        </FormControl>


                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap:'2rem'}}>

                            <FormControl >
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </FormControl>
                        </div>
                    </form>

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="success">
                            Submission successful!
                        </Alert>
                    </Snackbar>

                    </Grid>
                </Grid>
                </Paper>

    );
};

export default Feedback;