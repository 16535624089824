import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const withNonAdminAccess = (WrappedComponent) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      let isMounted = true;

      async function checkAdminStatus() {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
          if (isMounted) {
            setIsAdmin(groups.includes('admin'));
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error fetching user groups:', error);
        }
      }

      checkAdminStatus();

      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      if (!isLoading && isAdmin) {
        navigate('/admindash'); // Redirect admin users to the admin page
      }
    }, [isLoading, isAdmin, navigate]);

    if (isLoading) {
      return <div>Loading...</div>; // Optionally, render a loading indicator
    }

    // Check if isAdmin and render the component or redirect
    return isAdmin ? <div>You are not authorized to access this page.</div> : <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default withNonAdminAccess;
