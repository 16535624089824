import React from 'react';

// const Overlay = ({ children, onClose }) => {
//     return (
//       <div style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         zIndex: 1000, // Ensure it's above other content
//         overflowY: 'auto', // Enable scrolling on the y-axis if content overflows
//       }}>
//         <div style={{
//           marginTop: '20px',
//           backgroundColor: '#fff',
//           padding: '20px',
//           borderRadius: '5px',
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//           maxHeight: '85vh', // Max height of the content area; vh = viewport height
//           overflowY: 'auto', // Enable scrolling within the content area
//         }}>
//           {children}
//           <button onClick={onClose} style={{marginTop: '10px'}}>Close</button>
//         </div>
//       </div>
//     );
//   };

const Overlay = ({ children, onClose }) => {
    const overlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000, // Ensure it's above other content
      overflowY: 'auto', // Enable scrolling on the y-axis if content overflows
    };
  
    const contentStyle = {
      marginTop: '20px',
      position: 'relative', // Needed for absolute positioning of the close button
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxHeight: '85vh', // Adjust based on responsiveness
      maxWidth: '80%',
      overflowY: 'auto', // Enable scrolling within the content area
    };
  
    const closeButtonStyle = {
      position: 'absolute',
      top: '10px',
      right: '0px',
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      fontSize: '20px',
    };
  
    return (
      <div style={overlayStyle}>
        <div style={contentStyle}>
          <button onClick={onClose} style={closeButtonStyle}>&times;</button> {/* Close button */}
          {children}
        </div>
      </div>
    );
  };
  
  

export default Overlay;


