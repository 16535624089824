import React, { useState, useEffect } from 'react';
import { Storage, API, Auth } from 'aws-amplify';
import '../styles/Apply.css';
import { Tooltip, CircularProgress } from '@mui/material';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@mui/material/Typography';
// import FormField from "@awsui/components-react/form-field";
import { Form, FormField } from '@awsui/components-react';
import Button from "@awsui/components-react/button";
import FormGroup from '@mui/material/FormGroup';
import { FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import '@awsui/global-styles/index.css';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import {
    View,
    Flex,
    Text,
    Card,
    TextField,
    SelectField
} from '@aws-amplify/ui-react'; 

// import { FormControl } from 'react-bootstrap';


const Apply = () => {
  const [firstName, setFirstName] = useState('');
  const [midName, setMidName] = useState('');
  const [lastName, setLastName] = useState('');
  const [experience, setExperience] = useState('');
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [employer, setEmployer] = useState('');
  const [title, setTitle] = useState('');
  const [industry, setIndustry] = useState('');

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const uploadTime = currDate + ' ' + currTime;
  const [role, setRole] = useState('');
  const [linkedinaddress, setLinkedInAddress] = useState('');
  const appstatus = 0; // 0: submitted; 1: approved; -1: declined; 2: requested for more documents

  const [services, setServices] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedOther: false, // Manage the 'Other' checkbox
    customChoices: '', // To store user input for custom choices
    checkedLabels: '' // Since checkedA is true by default
});

  const industries = [
    'Construction',
    'Education and Health Services',
    'Financial Activities',
    'Information',
    'Leisure and Hospitality',
    'Manufacturing',
    'Natural Resources and Mining',
    'Other Services (except Public Administration)',
    'Professional and Business Services',
    'Trade, Transportation, and Utilities'
    // Add more industries as needed
  ];

  // const [resume, setResume] = useState(null);
  const [additionalText, setAdditionalText] = useState('');
  const [roledetail, setRoleDetail] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newState = { ...services, [name]: checked };

    const labels = [];
    if (newState.checkedA) labels.push('Resume review');
    if (newState.checkedB) labels.push('Mock interview - Phone screening');
    if (newState.checkedC) labels.push('Mock interview - Technical');
    if (newState.checkedD) labels.push('Mock interview - Coding challenge (coming soon)')
    if (newState.checkedOther) labels.push(newState.customChoices);

    newState.checkedLabels = labels.join(', ');
    setServices(newState);
    // console.log('services', newState.checkedLabels);
};

const handleCheckboxCustomChange = (event) => {
  const customInput = event.target.value;
  // Update the customChoices in state and recompute the checkedLabels
  const newState = { ...services, customChoices: customInput };
  const labels = [];
  if (newState.checkedA) labels.push('Resume review');
  if (newState.checkedB) labels.push('Mock interview - Phone screening');
  if (newState.checkedC) labels.push('Mock interview - Technical');
  if (newState.checkedD) labels.push('Mock interview - Coding Challenge (coming soon)')
  if (newState.checkedOther) labels.push(customInput); // Include custom input

  newState.checkedLabels = labels.join(', ');
  setServices(newState);
  // console.log('services', newState.checkedLabels);
};


  // const CustomFormField = ({ label, required, description, defaultValue, onChange }) => (
   
  //     <FormField label={required ? <span>{label}<sup style={{ color: 'red' }}>*</sup></span> : label}>
  //       <TextField defaultValue={defaultValue} onChange={onChange} />
  //       {description && <p>{description}</p>}
  //     </FormField>
    
  // );

  const RequiredAsterisk = () => <sup style={{ color: 'red' }}>*</sup>;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [userSub, setUserSub] = useState('');

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
      // Check if no files are selected
    if (selectedFiles.length === 0) {
      setUploadMessage('No files selected for upload.');
      return;
    }
    try {
      // Upload each selected file
      const uploadPromises = selectedFiles.map(async (file) => {
        const result = await Storage.put(file.name, file, {
          contentType: file.type,
          level: 'protected', // Set the access level (public or private)
        });
        console.log('File uploaded:', result);
        return file.name; // Preserve the file name
      });

      // Wait for all uploads to complete
      // const uploadedFileNames = await Promise.all(uploadPromises);

      const newUploadedFileNames = await Promise.all(uploadPromises);

      // Update the state with the new file names
      setUploadedFileNames((prevUploadedFileNames) => [
        ...prevUploadedFileNames,
        ...newUploadedFileNames,
      ]);

      // Display success message and the preserved file names
      setUploadMessage(`Upload successful. Files: ${newUploadedFileNames.join(', ')}`);
      console.log("all uploads: ", uploadedFileNames);
    } catch (error) {
      console.error('Error uploading files:', error);
      // Display error message if upload fails
      setUploadMessage('Upload failed. Please try again.');
    }
  };

  useEffect(() => {
    // Use Auth.currentAuthenticatedUser() to get the current authenticated user
    let isMounted = true;
    const getCurrentUser = async () => {
      try {
        // const user = await Auth.currentAuthenticatedUser();
        const [credentials, user] = await Promise.all([
          Auth.currentCredentials(),
          Auth.currentAuthenticatedUser()
        ]);
        // Access the Cognito User ID
        // if (isMounted) {
        // const cognitoId = await Auth.currentCredentials().then(credentials => credentials.identityId);
        // setUserId(cognitoId);
        // const user = await Auth.currentAuthenticatedUser();
        // const sub = user.attributes.sub; // 'sub' attribute contains the user's unique identifier
        // setUserSub(sub);
        // }
        if (isMounted) {
          // Set user ID from credentials
          const cognitoId = credentials.identityId;
          setUserId(cognitoId);

          // Set user sub from user attributes
          const sub = user.attributes.sub;
          setUserSub(sub);
        }
      } catch (error) {
        console.error('Error getting current user:', error);
      }
    };

    getCurrentUser();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    if (!firstName || !lastName || !role || !experience || !roledetail) {
      alert('Please fill in all required fields.');
      setIsSubmitting(false);
      return;
    }

    if (uploadedFileNames.length === 0 && selectedFiles.length > 0) {
      // User has selected files but hasn't uploaded them
      // const userChoice = window.confirm("We noticed that you've selected files, but they are not uploaded unless you click the 'upload' button. Click 'OK' to go back and upload.");
      alert("We noticed that you've selected files, but they are not uploaded unless you click the 'upload' button. Click 'OK' to go back and upload.");
      setIsSubmitting(false);
      return;
    }
    // Handle form submission and DynamoDB storage here
    try {
      
      const response = await API.post('hrapi', '/hr', {
        body: {
            userid: userId,
            applytime: uploadTime,
            firstname: firstName,
            midname: midName,
            lastname: lastName,
            linkedin: linkedinaddress,
            filenames: uploadedFileNames,
            role: role,
            status: appstatus,
            addtl: roledetail,
            yoe: experience,
            sub: userSub,
            employer: employer,
            title:title,
            ind:industry,
            msg: message,
            proServ: services.checkedLabels
        },
      });

    // const submissionConfirmed = window.confirm('Thank you for your submission! We will get back to you within two business days. Click OK to close this window.');

    // // Close the window if the user clicks "OK"
    // if (submissionConfirmed) {
    //   window.close();
    // }
    setIsSubmitting(false);
    const statusCode = response.statusCode;
    if (statusCode === 200) {
      // Show confirmation dialog
      setIsSubmitting(false);
      const submissionConfirmed = window.confirm('Thank you for your submission! We have received it and will get back to you within three business days. Click OK to close this window.');


      // Close the window if the user clicks "OK"
      if (submissionConfirmed) {
          // Check if the opener exists and is not closed
          if (window.opener && !window.opener.closed) {
            // Refresh the opener window, specifically targeting the '/home' page if necessary
            window.opener.location.href = '/askhr'; // This will navigate the opener to the '/home' page
            // Alternatively, if you just need to refresh the current page of the opener:
            // window.opener.location.reload();
            // Close the current window
            window.close();
          } else {
            window.location.href = '/askhr';
          }


      }
    } else {
      setIsSubmitting(false);
      console.error('Error submitting. Status code:', response.statusCode);
      // Handle other status codes if needed
    }
  } catch (error) {
    setIsSubmitting(false);
    console.error('Error submitting:', error);
    // Handle error if the API request fails
  }


  };

  const handleRoleOptionChange = (option) => {
    setRole(option);

    // Set additional text based on selected option
    switch (option) {
      case '0':
      case '1':
        setAdditionalText('Please list a few job titles that you have hired for, separate them with comma');
        break;
      case '2':
        setAdditionalText('Please list a few keywords of your expertise, separate them with comma');
        break;
      
      default:
        setAdditionalText('');
        break;
    }
  };

  const handleExperienceChange = (e) => {
    // Ensure the input contains only numeric characters
    const numericValue = e.target.value.replace(/\D/g, '');
    // console.log(numericValue);
    // Update the state with the numeric value
    setExperience(numericValue);
  };

  


  return (


    <Flex direction="row" justifyContent="center" gap="1rem"  alignItems="center"
    alignContent="flex-start"
    // style={{ position: 'absolute'}}
    >


        {/* {
          isSubmitting && (
            <div className="loadingOverlay">
              <CircularProgress />
            </div>
          )
        } */}

      
      <View width="100%"> 


        
        <Card header={<h1>Apply</h1>} aria-label='Application-form-card' variation="elevated" marginBottom="1rem">


          
          <Flex as="form" direction="column" gap="2rem">

            <Flex direction="row" gap = "1rem"> 
                {/* <CustomFormField label="First Name" required>
                    <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </CustomFormField> */}
                <FormField label={<>First Name<RequiredAsterisk /></>} required>
                  <TextField defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </FormField>

                <FormField label="Middle Name" required>
                    <TextField defaultValue={midName} onChange={(e) => setMidName(e.target.value)} />
                </FormField>

                <FormField label={
                        <Flex>
                            <Text>
                            Last Name <sup style={{ color: 'red' }}>*</sup>
                                
                            </Text>
                            <Flex alignItems="center">
                                <Tooltip 
                                title={<Typography fontSize={16}>Real names provided for validation will be kept confidential and accessible only to designated personnel
                                 for identity verification. Upon approval, users can set up a public profile name visible to others on the platform. </Typography>}>
                                    <HelpIcon />
                                </Tooltip>
                            </Flex>

                        </Flex>


                } required>
                    <TextField defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} />
                </FormField>
            </Flex>

            <Flex direction="row" gap = "1rem"> 
              <FormField label={<>Current Employer <RequiredAsterisk /></>} required >
                <TextField defaultValue={employer} onChange={(e) => setEmployer(e.target.value)}
                    placeholder="Enter the name of your company" />
              </FormField>



              <FormField label={<>Title <RequiredAsterisk /></>} required >
                <TextField defaultValue={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter your title"  />
              </FormField>

            
            <FormField label="Your industry">

                <FormControl fullWidth>
                  <InputLabel id="industry-select-label">Industry</InputLabel>
                  <Select
                      labelId="industry-select-label"
                      id="industry-select"
                      value={industry}
                      label="Industry"
                      onChange={(e) => setIndustry(e.target.value)}
                  >
                      {industries.map((ind) => (
                          <MenuItem key={ind} value={ind}>
                              {ind}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
            </FormField>

            </Flex>

            <FormField label={<>Year of Experience<RequiredAsterisk /></>} required >
              <TextField defaultValue={experience} type="number" onInput={handleExperienceChange} 
                  placeholder="Enter number of years" />
            </FormField>




            
            


              <FormField label={<>Role<RequiredAsterisk /></>} required>
                <SelectField
                    defaultValue={role}
                    placeholder='Which role are you applying?'
                    onChange={(e) => {handleRoleOptionChange(e.target.value)}}
                >

                    <option value="0">I am a recruiter</option>
                    <option value="1">I am a hiring manager</option>
                    <option value="2">I am an expert in a certain industry</option>

                </SelectField>
              </FormField>

            


            {additionalText && (
                
                <FormField                                         
                    label={
                        <Text>
                            Additional Question<sup style={{ color: 'red' }}>*</sup>
                        </Text>
                        } 
                    description= {additionalText}
                    required
                >
                <TextField
                    
                    onChange={(e) => setRoleDetail(e.target.value)}
                    required
                />
                </FormField>
                
            )}

<FormField label= "Services you intend to offer (editable after approval)" >
<FormGroup>
        <FormControlLabel control={<Checkbox checked={services.checkedA} onChange={handleCheckboxChange} name="checkedA" />} label="Resume review" />
        <FormControlLabel control={<Checkbox checked={services.checkedB} onChange={handleCheckboxChange} name="checkedB" />} label="Mock interview - Phone screening" />
        <FormControlLabel control={<Checkbox checked={services.checkedC} onChange={handleCheckboxChange} name="checkedC" />} label="Mock interview - Technical" />
        <FormControlLabel control={<Checkbox checked={services.checkedD} onChange={handleCheckboxChange} name="checkedD" />} label="Mock interview - Coding challenge (coming soon)" />
        <FormControlLabel control={<Checkbox checked={services.checkedOther} onChange={handleCheckboxChange} name="checkedOther" />} label="Other" />
        {services.checkedOther && (
            <TextField
                label="Other services"
                variant="outlined"
                size="small"
                placeholder="Enter other services separated by commas"
                value={services.customChoices}
                onChange={handleCheckboxCustomChange}
            />
        )}
        {/* <Typography variant="body1" gutterBottom>Checked Items: {services.checkedLabels}</Typography> */}
    </FormGroup>
</FormField>


              <FormField label={
              
                    <Flex>
                      <Text>
                      LinkedIn profile address
                      </Text>
                      {/* <Flex alignItems="center">
                          <Tooltip 
                          title={<Typography fontSize={16}>
                          Providing your LinkedIn profile will greatly accelerate the process.</Typography>}>
                              <HelpIcon />
                          </Tooltip>
                      </Flex> */}

                    </Flex>}

              >
                      <TextField
                        type="url"
                        defaultValue={linkedinaddress}
                        onChange={(e) => setLinkedInAddress(e.target.value)}
                        placeholder="https://example.com"
                        
                      />
              </FormField>

    


            <FormField label={

                <Flex>
                    <Text>
                    Upload supporting documents (PDF only)
                        
                    </Text>
                    <Flex alignItems="center">
                        <Tooltip 
                        title={<Typography fontSize={16}>For hiring-related roles please upload your resume and one of the following documents to verify your current employment: an employment verification letter, your most recent paystub, or any other appropriate document; 
                        For experts, please upload a resume and other supporting documents that may demonstrate your expertise, e.g., publications, etc.</Typography>}>
                            <HelpIcon />
                        </Tooltip>
                    </Flex>

                </Flex>
                

                }>


            
              <div className="file-upload">
                  <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} multiple />
                  <Button onClick={handleUpload}>Upload</Button>
                  
                  {/* {uploadMessage && <p>{uploadMessage}</p>}  */}
                  
              </div>
              <div style={{ display: 'block' }}> {uploadMessage && <p>{uploadMessage}</p>}  </div>
            </FormField>

            <FormField label={<>Comment</>}>
                  <TextField defaultValue={message} onChange={(e) => setMessage(e.target.value)} />
            </FormField>


            <View as="div" style={{ paddingTop: "2rem" }}>
                {/* <Button variant="primary" type="submit" onClick={handleSubmit} endIcon={isSubmitting ? <CircularProgress /> : null}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button> */}
                <LoadingButton
                  onClick={handleSubmit}
                  endIcon={<SendIcon />}
                  loading={isSubmitting}
                  loadingPosition="end"
                  variant="contained"
                >
                  <span>Submit</span>
                </LoadingButton>
            </View>
          </Flex>

        </Card>
        </View>
    </Flex>
  );
};

export default Apply;
