import React from 'react';
import { useNavigate } from 'react-router-dom';
import SpaceBetween from '@awsui/components-react/space-between';
import { Text } from '@aws-amplify/ui-react';
import { Paper, Grid, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/system';
import firstFunctionImage from '../assets/firstfunction2.png';
import secondFunctionImage from '../assets/secondfunction2.png'
import careerSyncImage from '../assets/careersyncpic.jpg';
import WebinarNotice from '../components/WebinarNotice';
import { GradientBackground, HomeContainer } from '../utils/stylingUtils'
import { Flex } from '@aws-amplify/ui-react';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

// const { ref, inView } = useInView({
//     triggerOnce: true,  // Ensures the animation only plays once
//     threshold: 0.5       // Adjust based on when you want the animation to trigger
//   });

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: '#e0f7fa', // Light cyan background for the whole page
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent panels
    },
  }));



  // Create styled Typography components to apply specific styles
  const WelcomeText = styled(Typography)({
    fontFamily: 'Recoleta Regular',
    fontStyle: 'italic',
    color: 'white',
    lineHeight: '80%',
    // Default font size for large screens
    fontSize: '7vh',
    '@media (max-width: 600px)': {
      // Smaller font size for screens smaller than 600px
      fontSize: '7vw',
    }
  });
  
  const DescriptionText = styled(Typography)({
    display: 'block',
    fontStyle: 'normal',
    color: 'white',
    marginTop: '10px',
    marginBottom: '-10px',
    marginLeft: '20px',
    // Default font size for large screens
    fontSize: '3vh',
    '@media (max-width: 600px)': {
      // Smaller font size for screens smaller than 600px
      fontSize: '3vw',
    }
  });

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

const Content = () => {
    let navigate = useNavigate();
    const classes = useStyles();
    const webinar_title = "Acing your Interview - Tips and Tricks to help land your next role";
    const webinar_intro = "Join us for a free webinar where a seasoned recruiter will share expert tips.";
    const webinar_registerUrl = "https://idyllic.ai/askhr";
    const webinarTime = "June 6, 2024, at 5:00 PM Eastern Time";
    const presenterName = "Emily Mason";
    const presenterTitle = "People Strategist";
    return(
        <>

        <SpaceBetween size="l">
            

        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '10px' }}>
        <Helmet>
            <title>Discover Precision-Matched Careers and Expert Coaching - No More Searching, Just Perfect Fits with Idyllic AI</title>
            <meta name="description" content="Explore Idyllic AI for real-time job listings perfectly matched to your skills through advanced AI technology. Benefit from personalized career coaching with industry experts for resume reviews, mock interviews, and more, all tailored to help you discover and secure your ideal job." />
        </Helmet>
        <Grid container spacing={2} gap={3}>
            <Grid item xs={12}>  
            <HomeContainer>
            <div id="comingsoon"> 
            
                <img referrerPolicy="no-referrer" aria-label='fall sky' src="../crossroad.jpg"/>
                <button id="signin" onClick={() => 
                    {navigate("/match")}}
                    > 
                    sign in / register</button>

                <button id="aboutus" onClick={() =>
                    {navigate("/aboutus")}}
                    >
                        about us
                    </button>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center"
                        // sx={{ marginTop: '30%' }} 
                    >                        
                    
                        <Grid item xs="auto">
                        {/* <Text fontFamily='Recoleta Regular' fontStyle='italic' fontSize="7vw" color='white' lineHeight="80%">Welcome to idyllic.ai</Text> */}
                            <WelcomeText variant="h1" component="h1">Welcome to Idyllic AI</WelcomeText>
                        </Grid>
                        {/* <Grid item container justifyContent="center" alignItems="center">
                        <WebinarNotice 
                            title= {webinar_title} 
                            intro={webinar_intro} 
                            registerUrl={webinar_registerUrl} 
                            time={webinarTime}
                            presenterName={presenterName}
                            presenterTitle={presenterTitle}
                        />
                        </Grid> */}


                        <Grid item xs={8}>
                            <DescriptionText variant="subtitle1" component="h2">
                                Discover your ideal job with real listings and ChatGPT recommendations matched to your skills.
                            </DescriptionText>
                        </Grid>

                        <Grid item xs="auto">
                            <DescriptionText variant="body2" component="h2">
                                Consult with industry professionals who handle daily hiring decisions in your field for resume reviews, mock interviews, and more. 
                            </DescriptionText>
                        </Grid>
                    </Grid>
 
            </div>
           </HomeContainer>
            </Grid>

            {/* <div id="firstfunction">
                <img src="../assets/firstfunction.png" referrerPolicy="no-referrer" aria-label='firstfunction'/>
            </div> */}
        {/* <Grid container spacing={2} gap={3}> */}
            
        <Grid item>  {/* xs={12} makes it responsive and full width on small devices */}
            <HomeContainer>
            <GradientBackground>
            <Flex  direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                marginLeft="2rem">
            <Grid container spacing={2} gap={3}>
                <Grid item container xs={12} sm={6} md={6}>
                    <Grid item>
                        <Typography variant="h2" component="h2" fontWeight="700" color="white" gutterBottom>
                            <strong>CareerSync (coming soon): Bridging Talent and Opportunity</strong>
                        </Typography>
                    </Grid>
                <Grid item>
                   
                        <Typography variant="h6" component="h2" color="white" gutterBottom><strong>Employers: Hire Top Talent with Zero Risk </strong></Typography>
                       
                        <Typography variant="body1" color="white" component="p" gutterBottom>
                        Sign up for a free trial at CareerSync and fill one position at no cost. Experience firsthand how our integrated hiring solution can streamline your recruitment process and connect you with exceptional candidates ready to make an impact.
                        </Typography>
                        <br/>
                        <Typography variant="h6" color="white" component="h2" gutterBottom><strong>Students: Let the Best Job Opportunities Find You</strong></Typography>
                        <Typography variant="body1" color="white" component="p" gutterBottom>
                        Say goodbye to endless browsing and searching—let the right opportunities find you. Whether you're looking for an internship or a full-time position, during the semester or over summer and winter breaks, we've got you covered. Register with CareerSync and receive notifications for job postings that perfectly match your skills and interests. Take the first step towards your dream career today—effortlessly.
                        </Typography>
                </Grid>
            
                </Grid>

                <Grid item container xs={12} sm={5} md={5} direction="column" justifyContent="flex-end" alignContent="flex-end">
                    <Img alt="complex" src={careerSyncImage} />
                    </Grid>
            </Grid>
            
          </Flex>
            </GradientBackground>
            </HomeContainer>
        </Grid>

            <Grid item>
                <HomeContainer>
                <Img alt="complex" src={firstFunctionImage} />
                </HomeContainer>
            </Grid>

            <Grid item>
                <HomeContainer>
                <Img alt="complex" src={secondFunctionImage} />
                </HomeContainer>
            </Grid>

        </Grid>

        </div>

        </SpaceBetween>
        </>
    )
}


function Home() {


    return (

        <Content/>

    );
}

export default Home;