import React from 'react';
import { Text } from '@aws-amplify/ui-react';

const Status = () => {

    
    return (
        <div id="comingsoon">
            <img aria-label='forest' src="../forest.jpg"/>
            
            <div>
                <Text fontFamily='Recoleta Regular' fontStyle='sans-serif' fontSize="4vh" color='white'>Status Update</Text>
                <hr width="200"/>
                <Text fontFamily='Recoleta Regular' fontStyle='sans-serif' fontSize="2vh" color='white'>News: We are preparing to promote Idyllic AI's Historical Job Matches function next Wednesday (5/1/24), while continuing to recruit industry officials for tailored consultations with students.</Text>
            </div>
        </div>
    );
};

export default Status;