import { styled } from '@mui/system';

export function parseMarkdown(text) {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = text.split(boldRegex);
  
    return parts.map((part, index) => {
      if (index % 2 === 1) { // Odd indices are inside bold markers
        return <strong key={index}>{part}</strong>;
      }
      return part; // Even indices are outside the markers
    });
  }


// write a function like parseMarkdown that will parse text and replace all instances of ### with <h3> tags
export function parseMarkdownh3(text) {
    const h3Regex = /###(.*?)###/g;
    const parts = text.split(h3Regex);
  
    return parts.map((part, index) => {
      if (index % 2 === 1) { // Odd indices are inside h3 markers
        return <h3 key={index}>{part}</h3>;
      }
      return part; // Even indices are outside the markers
    });
  }

// merge parseMarkdown and parseMarkdownh3 into a single function called parseMarkdownWithHeaders
export function parseMarkdownWithHeaders(text) {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const h3Regex = /### (.+?)(?=\n|$)/g;
    const parts = text.split(boldRegex).map(part => part.split(h3Regex)).flat();
  
    return parts.map((part, index) => {
      if (part.startsWith('###') && part.endsWith('###')) {
        return <h3 key={index}>{part.slice(3, -3)}</h3>;
      } else if (index % 2 === 1) {
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }
  

export function CleanTextDisplay(text) {
    // replace multiple new lines with two new lines
    const cleanedText = text.replace(/\n\s*\n/g, '\n\n');
    // const cleanedText = text.replace(/\n\s*\n/g, '\n'); // relace multiple new lines with two new lines
    // replace ### in cleanedText to <h3> tag
    // const cleanedText = text.replace(/###/g, '<h3>');
    return cleanedText;
  }
  


  // Styled component for the background
export const GradientBackground = styled('div')({
  background: 'linear-gradient(to bottom, #5173AA, #77BFDE)', // Gradient from top (dark) to bottom (pale)
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh' // Ensures it covers at least the full viewport height
});

  // Define the HomeContainer styled component
export const HomeContainer = styled('div')({
  width: '90%',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden' // Ensures that children with a background or border that might overflow are clipped
});