import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import { transformDBdate, transformRecurrTS, calculateTimeZoneOffset } from '../utils/objectUtils';
import dayjs from 'dayjs';
import { Badge, Typography, Paper, Button } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { processMeetings } from '../utils/objectUtils';
import ChangeApptOptions from '../components/ChangeApptOptions';
import { useNavigate } from 'react-router-dom';
// import TimeZoneSelector from './TimeZoneSelector';


export default function UpcomingAppointmentPage() {

  const [meetings, setMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMeetings, setIsFetchingMeetings] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [hoursOfSD, setHoursOfSD] = useState([]);
  const maxDate = dayjs().add(8, 'week').endOf('week').toDate();
  const [selectedHours, setSelectedHours] = useState(new Map());
  const [hrNames, setHrNames] = useState([]);
  const [servs, setServs] = useState([]);
  const [timeLens, setTimeLens] = useState([]);
  const [meetIds, setMeetIds] = useState([]);
  const [presets, setPresets] = useState([]);
  const [tzs, setTZs] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [hrIds, setHrIds] = useState([]);
  const thisMonth = dayjs().startOf('month');
  const navigate = useNavigate();

  // useEffect(() => {
  //   let isMounted = true;
  //   API.get('allusersapi', '/allusers/:userid').then((res) => {
  //     console.log('all hr data approved and have sessions added', res);
  //     if (isMounted) {
        
  //       // const part_meetings = [appointmentDetails, ...(res[0].part_meetings || [])];
  //       // const part_meetings = appointmentDetails
  //       //                       ? [appointmentDetails, ...(res[0].part_meetings || [])]
  //       //                       : [...(res[0].part_meetings || [])];
  //       const part_meetings = [...(res[0]?.part_meetings || [])];
  //       const host_meetings = res[0]?.host_meetings || [];
  //       const merged_meetings = [...part_meetings, ...host_meetings];
  //       const allprocessedmeetings = processMeetings(merged_meetings);
  //       // const allPartMeetings = processMeetings(part_meetings);
  //       // const allHostMeetings = processMeetings(res[0].host_meetings);
  //       // console.log('all part meetings, ', allPartMeetings, 'before conversion,', part_meetings, 'res[0].part_meetings', res[0].part_meetings);
  //       // setPartMeetings(allPartMeetings);
  //       // setHostMeetings(allHostMeetings);
  //       // console.log('part_meetings,', part_meetings, 'host meetings,', host_meetings);
  //       setMeetings(allprocessedmeetings);
  //       setIsFetchingMeetings(false);
  //       // console.log('meetings,', allPartMeetings);
  //       // setIsLoading(false);
  //     }
  //   });

  //   return () => {
  //     isMounted = false; // Set the flag to false when the component unmounts
  //   };
  // },[]);


  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {

      const cachedPartMeetings = localStorage.getItem('partMeetings');
      const cachedHostMeetings = localStorage.getItem('hostMeetings');

      if (cachedPartMeetings && cachedHostMeetings) {
          // Data found in local storage, parse and set to state
          const part_meetings = JSON.parse(cachedPartMeetings);
          const host_meetings = JSON.parse(cachedHostMeetings);
          const merged_meetings = [...part_meetings, ...host_meetings];
          const allprocessedmeetings = processMeetings(merged_meetings);
          setMeetings(allprocessedmeetings);
          setIsFetchingMeetings(false);
      } else {
      // Data not found in local storage, fetch from API
        try {
          const [userRes, providerRes] = await Promise.all([
            API.get('meetingsapi', `/meetings/user-orders/:userid`),
            API.get('meetingsapi', `/meetings/hr-earnings/:hrid`)
          ]);
    
          if (isMounted) {
          //   const updatedAppointments = processAppointments(appointmentsRes);
          //   const processedOtherData = processOtherData(anotherRes);
            // Optionally combine data or update state separately
            const part_meetings = userRes && userRes.length > 0 ? userRes : [];
            const host_meetings = providerRes && providerRes.length > 0 ? providerRes : [];

            const enhancedPartMeetings = part_meetings.map(meeting => ({
              ...meeting,
              preset: 'group_call_participant'
            }));
        
            // Add "preset" to each meeting in host_meetings
            const enhancedHostMeetings = host_meetings.map(meeting => ({
              ...meeting,
              preset: 'group_call_host'
            }));

            const merged_meetings = [...enhancedPartMeetings, ...enhancedHostMeetings];
            const allprocessedmeetings = processMeetings(merged_meetings);
            setMeetings(allprocessedmeetings);
    

            localStorage.setItem('partMeetings', JSON.stringify(enhancedPartMeetings));
            localStorage.setItem('hostMeetings', JSON.stringify(enhancedHostMeetings));
    
            setIsFetchingMeetings(false);
          }
        } catch (error) {
          console.error('Error fetching data', error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, []);


useEffect(() => {
  computeHighlightedDays(thisMonth);
  // Assuming computeHighlightedDays does not need to directly update state
  // but instead does something like fetching data or updating context
}, [meetings]); // Runs on component mount and whenever currentMonth changes

const computeHighlightedDays = (month) => {
    const highlightedDays = new Set();
    Object.keys(meetings).forEach((date) => {
        const dateObject = dayjs(date);
        // console.log('date,', date, dateObject.isSame(month, 'month'));
        
        if (dateObject.isSame(month, 'month')) {
            // Add the day to highlightedDays if it's in the current month
            highlightedDays.add(dateObject.date());
        }
    });
    // console.log('highlighted days,', highlightedDays, 'month', month, 'meetings,', meetings);
    setHighlightedDays(Array.from(highlightedDays));
    setIsLoading(false);
};


const handleMonthChange = (newDate) => {
    const currentMonth = dayjs().startOf('month');
    const nextMonth = dayjs().add(1, 'month').startOf('month');
    const newDateMonth = dayjs(newDate).startOf('month');
  
    setIsLoading(true);

    if (dayjs(newDate).isSame(currentMonth, 'month')) {
      computeHighlightedDays(currentMonth);
      // console.log('current month is ', currentMonth);
    } else if (dayjs(newDate).isSame(nextMonth, 'month')) {
      computeHighlightedDays(nextMonth);
      // console.log("User navigated to the next month", nextMonth);
    } else if (dayjs(newDate).isSame(newDateMonth, 'month')) {
      computeHighlightedDays(newDateMonth);
      // console.log("User navigated to the 3rd month", newDateMonth);
    }
  };

  const handleDateClick = (date) => {
    // const dateString = date.toDate().toDateString();
    const dateString = date.toISOString().split('T')[0];
    setSelectedDate(date);
    console.log('meetings,', meetings[dateString], 'dateString,', dateString);
    const hours = meetings[dateString]?.hours || [];
    const providerNames = meetings[dateString]?.providers || [];
    const time_lens = meetings[dateString]?.timeLens || [];
    const meeting_IDs = meetings[dateString]?.meetingIds || [];
    const services = meetings[dateString]?.services || [];
    const allpresets = meetings[dateString]?.presets || [];
    const allorderids = meetings[dateString]?.orderIds || [];
    const timezones = meetings[dateString]?.timezones || [];
    const allhrIds = meetings[dateString]?.hrIds || [];

    setHoursOfSD(hours);
    setHrNames(providerNames);
    setServs(services);
    setTimeLens(time_lens);
    setMeetIds(meeting_IDs);
    setPresets(allpresets);
    setTZs(timezones);
    setOrderIds(allorderids);
    setHrIds(allhrIds);

    // console.log('timezones,', timezones);
    // recalculateAdjustedHours(dateString);

};



const handleJoinMeeting = async (meetingID, preset) => {
  const lobbyUrl = `/lobby`;
  const meetingWindow = window.open(lobbyUrl, "_blank"); // Open LobbyPage in a new window

  try {
    // Step 2: Fetch the meeting token from the backend
    const apiName = 'meetingsapi';
    const response = await API.get(apiName, `/meetings?meetingId=${meetingID}&preset=${preset}`, {})
    const meetingToken = response.data.token;
    console.log('meeting response, ', response);
    // Assuming the new window has a function `initializeMeeting` that can be called to start the meeting
    // Step 3: Pass the meeting token to the new window
    if (meetingWindow) {
      // meetingWindow.location.href = `/meeting?token=${meetingToken}`;
      meetingWindow.postMessage({ type: 'MEETING_TOKEN', token: meetingToken }, "*");
    }
  } catch (error) {
    console.error('Error fetching meeting token:', error);
    if (meetingWindow) {
      meetingWindow.alert('Failed to load meeting. Please try again.');
      meetingWindow.close();
    }
  }
}



const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌚' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };


  if (isFetchingMeetings) {
    return (
      <Paper elevation={3} sx={{ padding: 2, position: 'relative' }}>
        <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background for better readability
            color: 'black',
            padding: 1,
            borderRadius: 1,
          }}
        >
          Loading meetings...
        </Typography>
      </Paper>
    );
  }

    return (
    <>

    <Paper>

    <Typography variant='h5'>Upcoming Appointments</Typography>
    {/* <TimeZoneSelector timeZoneState={userTimeZoneState} onTimeZoneChange={handleTimeZoneChange}/> */}

    {meetings.length === 0 ? (
        <Typography variant="subtitle1">No upcoming appointments.</Typography>
      ) : (
        <div>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar 
        value={selectedDate}
        loading={isLoading}
        onChange={handleDateClick}
        slots={{
            day: ServerDay,
            }}
            slotProps={{
            day: {highlightedDays},
            // day: {currmonhighdays},
            }}
        disablePast
        maxDate={dayjs(maxDate)}
        onMonthChange={handleMonthChange}
        sx={{width: '100%' }}/>
    </LocalizationProvider>


    <div >
       
    {!selectedDate ? (
        <div>Select highlighted days to see upcoming appointments.</div>
      ) : hoursOfSD.length === 0 ? (
        <div>No appointments for selected date.</div>
      ) : (
           hoursOfSD?.map((hour,index) => {
            // console.log('hour', hour, 'isHoursSelected', isHourSelected(hour));
            let end_hour, end_min;
            if (timeLens[index] < 60) {
              end_hour = hour;
              end_min = timeLens[index];
            } else {
              end_hour = hour + 1;
              end_min = '00';
            }

            let color, displaytext;
            if (presets[index] === 'group_call_participant') { // participant
              color = '#ADD8E6';
              displaytext = servs[index] + ' with ' + hrNames[index];
            } else { // host
              color = '#ffffff';
              displaytext = 'Hosting ' + servs[index];
            }
        return (
              
              <div key={hour} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '10px' }}>
                
                <Paper
                    // onClick={() => handleTimeSlotClick(hour)}
                    style={{
                      
                      padding: '10px',
                      marginBottom: '5px',
                      textAlign: 'center',
                      width: '50%',
                      height: '30px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                      backgroundColor: color,
                      cursor: 'pointer', 
                    }}
                  >
                    
                      <Typography  variant='body1' > {hour}:00 - {end_hour}:{end_min} ({tzs[index]})</Typography>
                      <Typography variant='body1'> {displaytext} </Typography>
                      <Button size="medium" disableElevation onClick={() => handleJoinMeeting(meetIds[index], presets[index])}> Join</Button>
                      {/* <ChangeApptOptions onReschedule={(event) => handleReschedule(event, orderIds[index])} onCancel={(event) => handleCancel(event, orderIds[index])} />  */}
                  </Paper>
                      
              </div>
        );
      })
  
  
    )}
    </div>

    </div>
  )}
    </Paper>
    </>
  );
  

  
}

