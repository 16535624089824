import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Typography, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';


function AccountDropdown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { handleSignOut } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
        handleClose();
      };

    return (
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom', // Changed from 'top' to 'bottom'
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top', // This ensures the menu "grows" downward from the anchor point
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem> */}
                 <MenuItem>
                    <Typography variant="body1">
                        Webinar credits: <Box component="span" sx={{ color: 'red', fontWeight: 'bold' }}>2</Box>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/orders')}>Your Orders</MenuItem>
                <MenuItem  onClick={() => handleNavigate('/appointments')}>Upcoming Appointments</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                {/* Add more menu items as needed */}
            </Menu>
        </div>
    );
}

export default AccountDropdown;