import React, {useState, useRef, useEffect} from 'react';
import { API, Storage } from 'aws-amplify';
import { Button, IconButton, List, ListItem, ListItemText, 
  ListItemSecondaryAction, Box, Paper, Divider, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../components/CartContext';
import CircularProgress from '@mui/material/CircularProgress';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from 'uuid';

const parseUnavailableSlots = (unavailableSlots) => {
  return unavailableSlots.reduce((acc, slot) => {
    const [hrid, rawDate, hour] = slot.split("#");
    const formattedDate = `${rawDate.slice(0, 4)}-${rawDate.slice(4, 6)}-${rawDate.slice(6, 8)}`;
    const key = `${hrid}#${formattedDate}T${hour.padStart(2, '0')}:00:00.000Z`;
    acc[key] = true; // Mark this slot as unavailable
    return acc;
  }, {});
};

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_live_51P3KjIEHH3eI15xPJWUyoMWui5GlEwPny1qIsJhKufiN0yO4RQ1GOlzLOHiARWdTosQHeJ34XeHIfrynP7wCUy6V00W2VjGzHg');

const CartDetailPage = () => (
  <Elements stripe={stripePromise}>
    <CartDetails />
  </Elements>
);


const CartDetails = () => {
  const navigate = useNavigate();
  const { cart, removeFromCartById, clearCart, cartLoaded } = useCart();
  const [isCheckingAvail, setIsCheckingAvail] = useState(false);
  const [unavailableSlots, setUnavailableSlots] = useState({});


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const stripe = useStripe();

  const handleClose = () => {
    setOpen(false);
  };

  // console.log('cart,',cart);

  useEffect(() => {
    let isMounted = true; // Flag to check mount status
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!isMounted) return; // Check if component is still mounted
      console.log('clientSecret, ', clientSecret);
      console.log('paymentIntent, ', paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          // call orderapi to update paymentcomplete status, no need because listening to webhook successful paymentIntent as well
          // try {
          //   const initbody = {
          //   body: {
          //     orderid: ongoingOrderID
          //   },
          //   headers: { 'Content-Type': 'application/json' },
          //   };
          // API.post('orderapi', '/order/updatePaymentStatus', initbody);
          // }catch(error){
          //   console.log('updatePaymentStatus failed in orderapi:', error);
          // }

          setMessage("Payment succeeded!");
          sessionStorage.setItem('lastPurchase', JSON.stringify(cart));
          if (cartLoaded && cart.length > 0) {
          clearCart();

          navigate("/appointments/confirmation?createMeetings=true"); // Redirect to a success page

          }
          // verifyPaymentOnServer(clientSecret);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });

    return () => {
      isMounted = false;
    };
  }, [stripe, cartLoaded]);



  const handleRemoveItem = (itemID) => {
    removeFromCartById(itemID); // Implement this function based on your requirements
    // window.dispatchEvent(new CustomEvent('cartUpdated'));
    //lesson: custom event doesn't propagate through tabs.

  };
  const totalCost = cart.reduce((acc, item) => acc + (item.total), 0);

  const isMountedRef = useRef(true);

  useEffect(() => {
    // Component mounts
    isMountedRef.current = true;
    
    return () => {
      // Component unmounts
      isMountedRef.current = false;
    };
  }, []);

 

  const handleCheckout = async () => {
    
    setIsCheckingAvail(true);

    const items = cart.map(item => ({
      userid: item.hrid,
      timeSlots: item.meetHours.map(time => {
        const date = new Date(time);
        const dateString = date.toISOString().replace(/-/g, '').slice(0, 8);
        const hourString = `${date.getUTCHours()}`.padStart(2, '0');
        return `${dateString}#${hourString}`;
      })
    }));
    // console.log('timeSlots,', items);
    try {
      const apiName = 'availapi'; // replace with your API name
      const path = '/slots/check-availability'; // replace with your API path
      const init = {
        body: items,
        headers: { 'Content-Type': 'application/json' },
      };

      const orderID = generateOrderId();
      sessionStorage.setItem('orderID', orderID);
      
      const totalCharge = parseFloat(totalCost.toFixed(2));
  
      const response = await API.post(apiName, path, init);
      if (response.available) { // if all slots are available, of course they are also reserved in the backend
        // call orderapi to save the order in orderdb
        const initBody = {
          body: {
            orderid: orderID,
            cartItems: cart
        },
          headers: { 'Content-Type': 'application/json' },
        };
        API.post('orderapi', '/order', initBody);
        sessionStorage.setItem('timeSlotsWhrid', JSON.stringify(items));
        navigate('/checkout', { state: { orderID, totalCharge } });
        
      } else {
        // console.log('response, ', response);
        const slots = parseUnavailableSlots(response.unavailableSlots);
        if (isMountedRef.current) {
          setUnavailableSlots(slots);
          setOpen(true);
          // console.log('unavailable slots, ', slots);
        }
      }
      
      // console.log('response in handlecheckout, ', response);
      if (isMountedRef.current) {
        setIsCheckingAvail(false);
        
        // Further actions based on response
      }
      
      return response; // { available: true/false, unavailableSlots: [...] }
    } catch (error) {
      console.error("Failed to check availability:", error);
      if (isMountedRef.current) {
        setIsCheckingAvail(false);
        // Further actions based on response
      }
      return null;
    };
    
    
  };

  

  return (

    <div style={{ position: 'relative' }}>

        {
          isCheckingAvail && (
            <Box className="loadingOverlay" display="flex" flexDirection="column" alignItems="center" marginTop={2}>
              <CircularProgress />
              <Typography variant="body1" marginTop={1}>
                Double-checking availability...
              </Typography>
            </Box>
          )
        }
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Unavailable Appointment Slots</DialogTitle>
        <DialogContent>
          Oops! Some appointment slots have just become unavailable as they have been booked or changed by the providers.Please remove these appointments from your cart and select new time slots at your convenience. We apologize for any inconvenience and appreciate your understanding.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Shopping Cart
      </Typography>
      {(cart.length) > 0 ? (
      <>
      <Paper elevation={3}>
        <List>
          {cart.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={item.serv}
                  secondary={
                    <>

                      <Typography component="span" variant="body2" color="text.primary">
                        Provider: {item.hrName}
                      </Typography>
                      <br />
                      {item.meetHours.map((time, timeIndex) => {
                        
                        const key = `${item.hrid}#${time}`;
                        // console.log("Rendering key:", key, 'unavailableSlots[key]', unavailableSlots[key]);
                        return (
                        <Box
                          key={timeIndex}
                          component="span"
                          sx={{
                            backgroundColor: unavailableSlots[key] ? 'red' : 'transparent',
                            color: unavailableSlots[key] ? '#fff' : 'inherit',
                          }}
                        >
                          {item.meetTimes[timeIndex]} {/* Display the time already formatted to local time */}
                          ({item.stuTZ})
                        </Box>
                        );
                      })}


                      <br />
                      {'Sessions: ' + item.numSession + ' | Cost per Session: $' + item.chargePS}
                      <br />
                      <Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>
                        Service Total: ${item.total}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {index < cart.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end', // Aligns children (total and button) to the right
        alignItems: 'center', // Vertically aligns items in the middle, adjust as needed
        gap: 2, // Adds some space between the total and the button, adjust as needed
        mt: 2
      }}>
        
        <Typography variant="h6">
          Order Total: ${totalCost.toFixed(2)}
        </Typography>
      
        <Button variant="contained" color="primary" onClick={handleCheckout}>
          Proceed to checkout
        </Button>
      </Box>
      </>
      ) : (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="subtitle1">Your cart is empty</Typography>
      </Box>
      )}
    </Box>
  </div>
  );
};

export default CartDetailPage;

function generateOrderId() {
  const now = new Date();
  const utcYear = now.getUTCFullYear();
  const utcMonth = now.getUTCMonth() + 1; // getUTCMonth returns 0-11, add 1 for 1-12
  const utcDate = now.getUTCDate();
  const dateString = `${utcYear}${String(utcMonth).padStart(2, '0')}${String(utcDate).padStart(2, '0')}`;

  const shortUuid = uuidv4().substring(0, 8);
  return `${dateString}-${shortUuid}`;
}