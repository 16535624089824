import React from 'react';
import {Paper, Typography, Button, Box } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';

const WebinarItem = ({ title, intro, registerUrl, time, presenterName, presenterTitle }) => {
  return (
    <Paper elevation={3} sx={{ width: '100%', padding: '16px', backgroundColor: '#e3f2fd', borderRadius: '8px', marginBottom: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <CampaignIcon color="primary" sx={{ marginRight: '8px', fontSize: '40px' }} />
          <Typography variant="h5">
            {title}
          </Typography>
        </Box>
        <Box sx={{ paddingLeft: '8px' }}>
          <Typography variant="body1" gutterBottom>
            {intro}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <strong>Time:</strong> {time}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <strong>Presenter:</strong> {presenterName}, {presenterTitle}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '4px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            href={registerUrl} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Register for Webinar
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

const WebinarList = ({ webinars }) => {
  return (

      <Box sx={{ padding: '20px' }}>
        {webinars.map((webinar, index) => (
          <WebinarItem key={index} {...webinar} />
        ))}
      </Box>

  );
};

export default WebinarList;
