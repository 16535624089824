import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button, Typography, Box, Tooltip } from '@mui/material';
import '../styles/Payment.css';

export default function CheckoutForm({ amountInDollars }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log('paymentIntent, ', paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded! navigating to /confirmation");
          navigate('/confirmation'); // Replace '/confirmation' with your confirmation page's path
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('pay button clicked.');
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe.js hasnt loaded');
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/cart`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.", error.message, error.type);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center',  flexDirection: 'column',gap: '10px' }}>

     <Box width="80%" maxWidth="600px" mx="auto" p={2}>
      <Typography component="span" variant="h5" color="text.primary" >
      Please complete payment within 10 mins to secure your booking. Thank you for your prompt attention!
        </Typography>



      </Box>
    
    <form onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <Button disabled={isLoading || !stripe || !elements} id="submit" type="submit" variant="contained">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : `Pay $${amountInDollars}`}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

    <Typography component="span" variant="body1" color="text.primary">
        For credit card issued outside of the United States, an additional 1% currency conversion fee may apply, as charged by Stripe.
      </Typography>

    </div>
  );
}