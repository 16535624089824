import React, { useEffect, useState } from 'react';
import { Grid, Card, Paper, Typography, Button, FormControl, InputLabel, OutlinedInput, TextField, Select, MenuItem, Box, Snackbar, Alert } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { lightGreen } from '@mui/material/colors';
import { API } from 'aws-amplify';


const CareerConcierge = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [major, setMajor] = useState('');
    const [feature, setFeature] = useState('');

    const [studentType, setStudentType] = useState('');
    const [studentStatus, setStudentStatus] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const studentTypes = {
        undergrad: ["Freshman", "Sophomore", "Junior", "Senior"],
        master: ["Graduating in 6 months", "Graduating in 1 year", "2-3 years", "Recently started"],
        phd: ["Graduating in 6 months", "Graduating in 1 year", "2-3 years", "Recently started"],
        postdoc: ["Recently started", "1-2 years", "2-3 years", "3+ years", "Less than 6 months remaining" ]
    };

    const handleTypeChange = (event) => {
        setStudentType(event.target.value);
        setStudentStatus(''); // Reset the second select when first select changes
    };

    useEffect(() => {

        let isMounted = true;


        API.get('registerForEarlyAccessApi', '/signup/:userid').then(response => {
            // console.log('response, ', response);
            if (response.length>0 && isMounted) {
                setMessage('You have applied for early access!');
            }
        }).catch(error => {
            console.error('Error:', error);
        });

        return () => { isMounted = false };
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        API.post('registerForEarlyAccessApi', '/signup', {
            body: {
                email: email,
                name: name,
                studentType: studentType,
                studentStatus: studentStatus,
                major: major,
                feature: feature
            }
        }).then(response => {
            console.log('Success:', response);
            setOpen(true);
        }).catch(error => {
            console.error('Error:', error);
        });


        // Handle form submission here
        // You can send the form data to your backend or perform any other actions
        // console.log('Form submitted:', { email, name, studentType, studentStatus, major, feature });
    };

    const lightGreen = '#90ee90'; // Light green color

    return (
        // <Paper elevation={1} style={{padding: '2rem'}}>
        <div>
            {/* <Card > */}
            
                <Grid container justifyContent="center">

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '2rem'}}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Sign up for Early Access to AI Career Concierge</Typography>
                        </Grid>
                    </div>


                    <div style={{paddingBottom: "2rem"}}>
                        <Typography sx={{ fontSize: 12, paddingBottom: "1rem" }}>Our AI Career Concierge will be your personal guide through every step of your career journey -- from choosing the right courses to mastering industry skills. 
                    Tailored to know and grow with you, it ensures a seamless transition from academia to professional success. Sign up now to be among the first to experience our AI Career Concierge and enjoy an extended free trial!
                    </Typography>
                        {/* <Typography variant="h6">Key features: Industry Insight {bull} personalized insights {bull} Strategic Guidance {bull} recruiter’s perspective and knowledge {bull} Adaptive Learning</Typography> */}
                        <Typography variant="h6">
                            {/* <Box component="span" fontWeight="fontWeightMedium">Key features:</Box> */}
                            
                            <div style ={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Box component="span" display="flex" alignItems="center" style={{ marginBottom: 4 }}>
                                <StarIcon fontSize="large" style={{ color: lightGreen, marginRight: 8}} />
                                Industry Insight
                                {/* </Box>
                                <Box component="span" display="flex" alignItems="center" style={{ marginBottom: 4 }}> */}
                                <StarIcon fontSize="large" style={{ color: lightGreen, marginRight: 8, marginLeft: 8  }} />
                                Personalized Insights
                                </Box>
                                <Box component="span" display="flex" alignItems="center" style={{ marginBottom: 4 }}>
                                <StarIcon fontSize="large" style={{ color: lightGreen, marginRight: 8 }} />
                                Strategic Guidance
                                {/* </Box>
                                <Box component="span" display="flex" alignItems="center" style={{ marginBottom: 4 }}> */}
                                <StarIcon fontSize="large" style={{ color: lightGreen, marginRight: 8, marginLeft: 8 }} />
                                Recruiter’s Perspective and Knowledge
                                {/* </Box>
                                <Box component="span" display="flex" alignItems="center" style={{ marginBottom: 4 }}> */}
                                <StarIcon fontSize="large" style={{ color: lightGreen, marginRight: 8, marginLeft:8 }} />
                                Adaptive Learning
                                </Box>
                            </div>
                        </Typography>
                        {/* <Typography variant="body1">1. <strong>Personalized Insights</strong>: By understanding industry trends and standards, the AI can offer personalized advice that aligns with current job market demands.</Typography>
                        <Typography variant="body1">2. <strong>Strategic Guidance</strong>: The AI can help users navigate their career path more effectively by suggesting when to pursue new qualifications or pivot their career focus based on industry shifts.</Typography>
                        <Typography variant="body1">3. <strong>Competitive Edge</strong>: With insights from a , users can tailor their applications and interview strategies to stand out in the competitive job market.</Typography>
                        <Typography variant='body1'>4. <strong>Application Tracking and Feedback</strong>: Track your job applications and provide feedback on their status or quality, helping users improve applying strategy and interview skills over time.</Typography> 
                        <Typography variant='body1'>5. <strong>Adaptive Learning</strong>: The AI learns from the user’s experiences and preferences, continuously refining its advice to better suit their needs.</Typography>
                        <Typography variant='h5' sx={{paddingTop: "2rem"}}>Interested? Sign up now to be the first to experience the future of career guidance!</Typography> */}
                        {/* <Typography variant='h5' sx={{paddingTop: "2rem"}}>Excited for the future of career guidance? Sign up now to be among the first to experience our AI Career Concierge and enjoy an extended free trial!</Typography> */}
                    </div>
                    
                    
                    {message!=='' && <Alert sx={{marginBottom: "2rem"}} > <Typography variant="h6" color="green" >{message} Please update your information if your email has changed or if you have a new preferred feature in mind!</Typography>
                    </Alert>}
                    
                    <Grid item container justifyContent="center" xs={12} sm={8} lg={6}>
                    <Card style={{padding: '2rem', width: '100%'}}>

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="success">
                            Submission successful!
                        </Alert>
                    </Snackbar>
            
                    <form onSubmit={handleSubmit} style={{width: '100%'}}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="email">Email</InputLabel>
                                        <OutlinedInput
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="small"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel id="student-name-label"> Name</InputLabel>
                                        <OutlinedInput
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        size='small'
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth required>
                                        <InputLabel id="student-type-label">Are you a:</InputLabel>
                                        <Select
                                        labelId="student-type-label"
                                        id="student-type"
                                        value={studentType}
                                        label="Are you a:"
                                        onChange={handleTypeChange}
                                        size="small"
                                        >
                                        <MenuItem value="undergrad">Undergraduate Student</MenuItem>
                                        <MenuItem value="master">Master's Student</MenuItem>
                                        <MenuItem value="phd">Ph.D. Student</MenuItem>
                                        <MenuItem value="postdoc">Postdoc</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth required >
                                        <InputLabel id="student-status-label">Which year:</InputLabel>
                                        <Select
                                        labelId="student-status-label"
                                        id="student-status"
                                        value={studentStatus}
                                        label="which year"
                                        onChange={(event) => setStudentStatus(event.target.value)}
                                        disabled={!studentType} // Disable until a student type is selected
                                        size="small"
                                        >
                                        {studentType && studentTypes[studentType].map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="major">Major</InputLabel>
                                        <OutlinedInput
                                        type="text"
                                        id="major"
                                        value={major}
                                        onChange={(e) => setMajor(e.target.value)}
                                        label="Major"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth >
                                        {/* <InputLabel htmlFor="feature">Feature you're most excited about</InputLabel> */}
                                        <TextField
                                        label="Feature you're most excited about"
                                        id="feature"
                                        multiline
                                        rows={4}
                                        value={feature}
                                        onChange={(e) => setFeature(e.target.value)}
                                        variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'row', paddingBottom: '2rem'}}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>


                    </form>
                    </Card>
                    </Grid>
                </Grid>
            {/* </Card> */}
        {/* </Paper> */}
        </div>
    );
};

export default CareerConcierge;