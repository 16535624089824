import React, { createContext, useContext, useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import _ from 'lodash';
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartLoaded, setCartLoaded] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    // Update the item count whenever the cart changes
    setCartItemCount(cart.length);
  }, [cart]);


  const updateCartAPI = async () => {
    try {
      // Example API call to update cart data
      await API.put('updateCartapi', '/cart', { body: {cart: cart} });
      // Optionally, confirm success and update local state or UI as needed
    } catch (error) {
      console.error('Error updating cart data:', error);
    }
  };

  const debouncedUpdateCart = _.debounce(async (cart) => {
    try {
      // Assume updateCartAPI is an async function that updates the cart on the backend
      await updateCartAPI(cart);
      // console.log('Cart updated successfully, cart is', cart);
    } catch (error) {
      console.error('Failed to update cart:', error);
      // Handle error (e.g., show notification to the user)
    }
  }, 1000); // Debounce for 1 second

  useEffect(() => {
    let isMounted=true;
    const storedCart = localStorage.getItem('userShoppingCart');
    // console.log('stored cart, ', storedCart);
    if (storedCart && storedCart !== "undefined") { // This checks for a truthy value, which excludes null and undefined
      const oldCart = JSON.parse(storedCart);
      if (isMounted) {
      setCart(oldCart);
      }
    }
    
      // Asynchronously load the latest cart data from the backend
    const loadCartData = async () => {
      try {
        const userData = await API.get('updateCartapi', '/cart/:userid');
        
        // if (userData && userData[0].cart && isMounted) {
        if (userData && userData.length > 0) {
          if (isMounted) {
            setCart(userData[0].cart);
          }
          // Optionally, update local storage with the latest cart data
          localStorage.setItem('userShoppingCart', JSON.stringify(userData.cart));
        } else {
          // Handle the case where cart data is not available
          if (isMounted) {
            setCart([]);
          }
          
        }
        
      } catch (error) {
        console.error('Error fetching cart data:', error);
        // Handle error (e.g., show a notification to the user)
      } finally {
        if (isMounted) {
            setCartLoaded(true);  // Ensure cartLoaded is true after attempting to load the cart
        }
    }
    };

    loadCartData();

    return () => {
      isMounted = false; // Cleanup function is called on component unmount
    };
    
  }, []);
  


  // Update sessionStorage whenever the cart changes
  useEffect(() => {
    // console.log('cart in useeffect,', cart, 'cartLoaded, ', cartLoaded);
    if (cartLoaded) {
    // console.log('userShoppingCart updated');
    localStorage.setItem('userShoppingCart', JSON.stringify(cart));
    debouncedUpdateCart(cart);
    }
    // console.log('cart in useeffect,', cart);
  }, [cart]);
  // useEffect(() => {
  //   sessionStorage.setItem('userShoppingCart', JSON.stringify(cart));
  // }, [cart]);

  const addToCart = (item) => {
    // console.log('old cart, ', cart);
    const updatedCart = [...cart, item];
    // console.log('updated cart, ', updatedCart);
    setCart(updatedCart); // This will also update sessionStorage via useEffect
    sessionStorage.removeItem('meetingsCreated'); // Clear the flag
  };

  const removeFromCartById = (itemId) => {
    const updatedCart = cart.filter(item => item.id !== itemId);
    setCart(updatedCart); // This will also update sessionStorage via useEffect
  };

  // const removeFromCartById = (itemId) => {
  //   setCart((currentCart) => {
  //     const updatedCart = currentCart.filter(item => item.id !== itemId);
  //     localStorage.setItem('userShoppingCart', JSON.stringify(updatedCart)); // Update localStorage
  //     return updatedCart; // Update state
  //   });
  // };

  const clearCart = () => {
    if (cartLoaded) {  // Ensure cart is only cleared when it's fully loaded
      setCart([]);
      localStorage.removeItem('userShoppingCart');
      // console.log('cart cleared');
    }
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCartById, clearCart, cartLoaded, cartItemCount }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the cart context
export const useCart = () => useContext(CartContext);
