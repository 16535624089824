import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {  parse, parseISO, formatISO, getISOWeek, addWeeks } from 'date-fns';
dayjs.extend(utc);
dayjs.extend(timezone);

export const findMatchingKeys = (obj, dictionary) => {
    return Object.keys(obj).reduce((acc, key) => {
      const foundKey = Object.keys(dictionary).find(subString => key.includes(subString));
      if (foundKey) {
        acc.push(dictionary[foundKey]);
      }
      return acc;
    }, []);
  };


  export const sessionDict = {
    resRev: 'Resume Review',
    phoneScreen: 'Phone Screen',
    technicalITV: 'Technical Interview',
    codingChall: 'Coding Challenge',
  };

  export function reverseMapping(mapping) {
    const reversed = {};
    for (const key in mapping) {
      const value = mapping[key];
      reversed[value] = key;
    }
    return reversed;
  }

  export function getTZ(timeZoneState){
    let timeZone;
    if (timeZoneState.useSystemTimeZone) {
      timeZone = timeZoneState.systemTimeZone;
    } else {
      timeZone = timeZoneState.selectedTimeZone;
    }
    return timeZone;
  }
  

  export const nameDict = {numSess: 'Number of sessions', appointmentLength:'Length/session (mins)', charge:'Price/session ($)', title: 'Title', descp: 'Description'};
  
  export const suffixDict = {appointmentLength:'mins'}

  export const findMatchingKeys2 = (obj, dictionary) => {
    return Object.keys(obj).reduce((acc, key) => {
      // Attempt to find a dictionary key that matches the current object key
      const foundKey = Object.keys(dictionary).find(subString => key.includes(subString));
      if (foundKey) {
        // If a matching key is found, create an object with the dictionary key as the property
        // and the original object's value as its value, then add it to the accumulator array
        // const newObj = {};
        // newObj[dictionary[foundKey]] = transformToMap(obj[key], dictionary[foundKey]); // Use the value from the original object
        acc.push(transformToMap(obj[key], dictionary[foundKey]));
      }
      return acc;
    }, []);
  };

  export const transformToMap = (dynamoDBFormat, nameOfService) => {
    const attributesObject = {}; // This will hold all attributes
    // console.log('dynamoDBFormat, ', nameOfService, dynamoDBFormat);

    

    Object.entries(dynamoDBFormat[0]).forEach(([key, value]) => {
      // const type = Object.keys(value)[0]; // Get the type descriptor (e.g., 'S' or 'N')
      const actualValue = Object.values(value)[0]; // Get the actual value
      attributesObject[key] = actualValue;

    });
  
    const map = new Map();
    map.set(nameOfService, attributesObject); // Store the single object under key '0'
    // console.log(nameOfService, map);
    return map;
  };

export const transformToMapForSessions = (dynamoDBFormat) => {
    const map = {};
    Object.entries(dynamoDBFormat).forEach(([key, value]) => {
      map[key] = Object.values(value)[0];
      // console.log('key', key, 'Object.values(value)',Object.values(value));
      // const newKey = value.descp; // Extract 'descp' value to use as the new key
      // const { descp, ...restOfAttributes } = value; // Destructure to separate 'descp' from the rest
    
      // map[newKey] = restOfAttributes; // Use the rest of the attributes as the value

    });

    const { title, ...restOfAttributes } = map;

    const newMap = new Map();
    const newKey = title;
    newMap.set(newKey, restOfAttributes); // Store the single object under key '0'
    // newMap[newKey] = restOfAttributes;
    return newMap;
  
  };


// to provide options for search other services search bar
export  const getAllSessionTitles = (profiles) => {
    // Initialize an empty array to hold all session titles
    let allSessionTitles = [];
  
    // Iterate over each profile
    profiles.forEach(profile => {
      // Map each session to its title and accumulate the titles in allSessionTitles
      const sessionTitles = profile.otherSessions?.map(session => {
        const sessionMap = transformToMapForSessions(session);
        // Assuming the map has only one key-value pair where the key is the title
        for (let [title, _] of sessionMap) {
          return title;
        }
      });
  
      // Combine the titles from this profile with the rest
      allSessionTitles = [...allSessionTitles, ...(Array.isArray(sessionTitles) ? sessionTitles : [])];
    });
  
    // Filter out any undefined entries and remove duplicates
    allSessionTitles = allSessionTitles.filter((title, index, self) => title && self.indexOf(title) === index);
  
    return allSessionTitles;
  };


export const transformDBdate = (dynamodbSTS) => {
  const selectedTimeSlots = new Map();

    if (dynamodbSTS) {
      
        // const STSdynamo = res[0].selectedTS;
      
        // console.log('selectedTS in dynamodb in AC,', res[0].selectedTS);
        

        Object.entries(dynamodbSTS).forEach(([key, value]) => {
          const items = value.L.map(item => ({
              hour: parseInt(item.hour.N),
              isRecurrentWeekly: item.isRecurrentWeekly.BOOL
          }));
          selectedTimeSlots.set(key, items);
        });
      }

    return selectedTimeSlots;

}

export const transformRecurrTS = (originalMap) => {
  const transformedMap = new Map();
  
  // Helper function to add or update the map
  const updateMap = (key, hour) => {
      if (transformedMap.has(key)) {
          // Avoid duplicate hours for the same date
          const existingHours = transformedMap.get(key);
          if (!existingHours.includes(hour)) {
              transformedMap.set(key, [...existingHours, hour]);
          }
      } else {
          transformedMap.set(key, [hour]);
      }
  };

  originalMap.forEach((value, key) => {
    const maxDate = dayjs().add(8, 'week').endOf('week').toDate();
      value.forEach(({ hour, isRecurrentWeekly }) => {
          // Add or update the original date
          updateMap(key, hour);
          
          if (isRecurrentWeekly) {
              // Calculate and update for 7 days ahead recurrently
              let nextDate = new Date(key);
              let nextDate2 = new dayjs(Date(key));
              for (let week = 1; week <= 8; week++) { // Assuming a year of recurrence for simplicity
                  if (nextDate2.isAfter(maxDate)) {
                    break; // Exit the loop if the next date exceeds maxDate
                  }
                  nextDate.setDate(nextDate.getDate() + 7); // Move to next week
                  const nextKey = nextDate.toDateString(); // Format the date as a string
                  updateMap(nextKey, hour);
              }
          }
      });
  });

  return transformedMap;
}

export const transformRecurrTS2 = (originalMap) => {
  const transformedMap = new Map();
  
  // Helper function to add or update the map
  const updateMap = (key, hour) => {
    if (transformedMap.has(key)) {
      // Avoid duplicate hours for the same date
      const existingHours = transformedMap.get(key);
      if (!existingHours.includes(hour)) {
        transformedMap.set(key, [...existingHours, hour]);
      }
    } else {
      transformedMap.set(key, [hour]);
    }
  };

  originalMap.forEach((value, key) => {
    value.forEach(({ hour }) => {
      // Add or update the original date with the hour
      updateMap(key, hour);
    });
  });

  return transformedMap;
}



export const calculateTimeZoneOffset = (dateString, timeZone1, timeZone2, hours) => {

  const dateObj = new Date(dateString);
  const offset_zone1 = getTimezoneOffset(timeZone1, dateObj); // provider zone, in milliseconds
  const offset_zone2 = getTimezoneOffset(timeZone2, dateObj); // user zone, in milliseconds
  const offsetInHours = (offset_zone2 - offset_zone1)/(1000*60*60);
  // console.log('provider,', timeZone1, 'user,', timeZone2, offsetInHours);
  return hours.map(hour => {
    let adjustedHour = hour + offsetInHours;

    // Adjust for overflow (more than 24 hours) and underflow (less than 0 hours)
    if (adjustedHour >= 24) adjustedHour -= 24;
    if (adjustedHour < 0) adjustedHour += 24;

    return adjustedHour;
  });
  // return offsetInHours;
};

export const transformToUTCDateTimeStrings = (selectedHours) => {
  const utcDateTimeStrings = [];
  
  for (const [dateStr, hours] of selectedHours) {
    const date = new Date(dateStr);
    hours.forEach(hour => {
      // Clone the date object to avoid modifying the original date
      const dateWithHour = new Date(date.getTime());
      dateWithHour.setHours(hour, 0, 0, 0); // Set hour, minutes, seconds, milliseconds
      // Convert to UTC string
      const utcDateTimeString = dateWithHour.toISOString();
      utcDateTimeStrings.push(utcDateTimeString);
    });
  }
  return utcDateTimeStrings;
}

export const transformToUTCDateTimeStringswTZ = (selectedHours, timezone) => {
  const utcDateTimeStrings = [];

  // Iterate over the Map entries
  for (const [dateStr, hours] of selectedHours.entries()) {
    hours.forEach(hour => {
      // Construct the date and time string
      const dateTimeStr = `${dateStr} ${hour}:00`;

      // Parse the date-time string assuming it is local time for the given timezone
      const localDate = parse(dateTimeStr, "EEE MMM dd yyyy H:mm", new Date());

      // Convert the local date as if it were in the specified timezone to UTC
      const utcDate = zonedTimeToUtc(localDate, timezone);

      // Format the UTC date to ISO string
      const utcDateTimeString = utcDate.toISOString();
      console.log('utcTime,',utcDateTimeString);
      utcDateTimeStrings.push(utcDateTimeString);
    });
  }

  console.log('utcDateTimeStrings,',utcDateTimeStrings);
  return utcDateTimeStrings;
};

export const transformToReadableDateTimeStrings = (selectedHours) => {
  const dateTimeStrings = [];
  for (const [dateStr, hours] of selectedHours) {
    const date = new Date(dateStr);
    hours.forEach(hour => {
      // Clone the date object to avoid modifying the original date
      const dateWithHour = new Date(date.getTime());
      dateWithHour.setHours(hour, 0, 0, 0); // Set hour, minutes, seconds, milliseconds
      
      // Format the date and time in a readable format
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', hour12: true };
      const formattedDateTime = dateWithHour.toLocaleString('en-US', options);
      
      dateTimeStrings.push(formattedDateTime);
    });
  }
  return dateTimeStrings;
};


export function processMeetings(meetings) {
  const processedMeetings = {};

  if (!meetings || meetings.length === 0) {
    return [];
  }

  meetings.forEach(meeting => {
    if (meeting.refund === true) {
      return;
    }

    meeting.meetTimes.forEach((meetTime, index) => {
      // const [dateStr, hourStr] = meetHour.split(', ').slice(-2);
      const splitResult = meetTime.split(', ');
      const dateStr = `${splitResult[1]}, ${splitResult[2]}`; // 'Mar 27, 2024'
      const hourStr = splitResult[3]; // '11 AM'
      
      const dateObj = new Date(dateStr);
      const dateKey = dateObj.toISOString().split('T')[0]; // Convert date to YYYY-MM-DD format

      const timeComponents = hourStr.split(' ');
      let hours = parseInt(timeComponents[0]);
      if (timeComponents[1] === 'PM' && hours < 12) hours += 12;
      if (timeComponents[1] === 'AM' && hours === 12) hours = 0;

      if (!processedMeetings[dateKey]) {
        processedMeetings[dateKey] = {
          hours: [],
          services: [],
          timeLens: [],
          meetingIds: [],
          presets: [],
          providers: [],
          timezones: [],
          orderIds: [],
          hrIds: [],
        };
      }

      processedMeetings[dateKey].hours.push(hours);
      if (meeting.meetTimes.length === 1) {
        processedMeetings[dateKey].services.push(meeting.serv) ;
      } else {
        processedMeetings[dateKey].services.push(meeting.serv + `- ${index+1}`);
      }
      
      processedMeetings[dateKey].timeLens.push(meeting.timelen);
      processedMeetings[dateKey].meetingIds.push(meeting.meetingId);
      processedMeetings[dateKey].presets.push(meeting.preset);
      processedMeetings[dateKey].providers.push(meeting.hrName);
      processedMeetings[dateKey].orderIds.push(meeting.orderid);
      processedMeetings[dateKey].hrIds.push(meeting.hrid);
      if (meeting.preset === 'group_call_participant') {
        processedMeetings[dateKey].timezones.push(meeting.stuTZ);
      } else if (meeting.preset === 'group_call_host') {
        processedMeetings[dateKey].timezones.push(meeting.hrTZ);
      }
    });
  });

  return processedMeetings;
}

export function transformToDateTimeForAvailSlotsDB(slots, timeZone) {

  
  const dateTimeStrings = [];
  console.log('slots,',slots);

  for (const [dateStr, hours] of slots) {
    const todayInTimeZone = dayjs.tz(dayjs().startOf('day'), timeZone);
    const dateInLocalTimeZoneAtStart = dayjs.tz(dateStr, timeZone).startOf('day'); // The date in question, also at start of day

  if (dateInLocalTimeZoneAtStart.isSame(todayInTimeZone, 'day') || dateInLocalTimeZoneAtStart.isAfter(todayInTimeZone, 'day')) {

    const dateInLocalTimeZone = dayjs.tz(dateStr, timeZone);
    const dateInUTC = dateInLocalTimeZone.utc();
    const offset_inhours = getTimezoneOffset(timeZone, dateInUTC) / (1000 * 60 * 60); // provider zone, in milliseconds
    
    hours.forEach(hourObj => {
      
      // console.log('offset_zone', offset_inhours);
      
      let adjustedHour = hourObj.hour - offset_inhours;
      let adjustedDate = dateInUTC;
      
      if (adjustedHour >= 24) {
        adjustedHour -= 24;
        adjustedDate = adjustedDate.add(1, 'day'); // Move to the next day
      } else if (adjustedHour < 0) {
        adjustedHour += 24;
        adjustedDate = adjustedDate.subtract(1, 'day'); // Move to the previous day
      }

      const dateTimeString = `${adjustedDate.format('YYYYMMDD')}#${adjustedHour.toString().padStart(2, '0')}`;
      dateTimeStrings.push(dateTimeString);
    });
  }

  }

  console.log('transformed date,', dateTimeStrings);

  return dateTimeStrings;
}

// available slots from availdb read back, and transformed to map array like selectedTimeSlots
// for provider's view with "isRecurrentWeekly"

export function transformAvailSlotsDBArrToMapArr(ObjArr, timeZone) {
  const resultMap = new Map();

  ObjArr.forEach(item => {
    const [dateStr, hour] = item.DateTime.split('#');
    const date = dayjs(dateStr);
    const offset_inhours = getTimezoneOffset(timeZone, date) / (1000 * 60 * 60);
    let adjustedHour = parseInt(hour, 10) + offset_inhours;
    let adjustedDate = date;
    
    if (adjustedHour >= 24) {
      adjustedHour -= 24;
      adjustedDate = adjustedDate.add(1, 'day'); // Move to the next day
    } else if (adjustedHour < 0) {
      adjustedHour += 24;
      adjustedDate = adjustedDate.subtract(1, 'day'); // Move to the previous day
    }

    const hourObj = { hour: parseInt(adjustedHour, 10), isRecurrentWeekly: false };
    const finalDateString = adjustedDate.toDate().toDateString();
    if (!resultMap.has(finalDateString)) {
      resultMap.set(finalDateString, [hourObj]);
    } else {
      resultMap.get(finalDateString).push(hourObj);
    }
  });

  return resultMap;
}


export function transformAvailSlotsDBArrToMapArrForUserView(ObjArr, timeZone) {
  const resultMap = new Map();

  ObjArr.forEach(item => {
    const [dateStr, hour] = item.DateTime.split('#');
    const date = dayjs(dateStr);
    const offset_inhours = getTimezoneOffset(timeZone, date) / (1000 * 60 * 60);
    let adjustedHour = parseInt(hour,10) + offset_inhours;
    let adjustedDate = date;
    
    if (adjustedHour >= 24) {
      adjustedHour -= 24;
      adjustedDate = adjustedDate.add(1, 'day'); // Move to the next day
    } else if (adjustedHour < 0) {
      adjustedHour += 24;
      adjustedDate = adjustedDate.subtract(1, 'day'); // Move to the previous day
    }

    const finalDateString = adjustedDate.toDate().toDateString();

    if (!resultMap.has(finalDateString)) {
      resultMap.set(finalDateString, [adjustedHour]);
    } else {
      resultMap.get(finalDateString).push(adjustedHour);
    }

  });

  return resultMap;
}

export function getAvailabilityWeekNumber(dateTimeArray) {
  const uniqueAvailabilityWeeks = Array.from(new Set(dateTimeArray.map(dateString => {
    // Split the dateString into the date part and hour part, and ignore the hour
    const [datePart] = dateString.split('#');
    // Format the date part into an ISO string that parseISO can handle
    const formattedDate = `${datePart.slice(0, 4)}-${datePart.slice(4, 6)}-${datePart.slice(6, 8)}`;
    // Convert the formatted date string into a Date object
    const date = parseISO(formattedDate);
    // Get the week number for the date
    return getISOWeek(date);
  })));

  return uniqueAvailabilityWeeks;
}

export function transformUtcToLocal(utcTimes, userTimezone) {
  return utcTimes.map(utcTime => {
    const localTime = dayjs(utcTime).tz(userTimezone);
    return localTime.format('ddd MMM DD YYYY: HH:mm');
  });
}

export function invalidateOrderCache(item) {
  localStorage.removeItem(item);
}