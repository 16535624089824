import React, { useState, useEffect, useRef } from 'react';
import {Grid, TextField, Button, Checkbox, FormControlLabel, Paper } from '@mui/material';
import { Storage, API } from 'aws-amplify';
// import InputAdornment from '@mui/material/InputAdornment';
import ServiceItem from './ServiceItem';
import ServiceItemOther from './ServiceItemOther';
// import AddIcon from '@mui/icons-material/Add';
import ServiceItemOtherArray from './ServiceItemOtherArray';
// import ProfileCard from './ProfileCard';
import { getSignedUrl } from '../utils/s3utils';
import ProfilePhoto from './ProfilePhoto';

const HRProfileForm = ({ onCancel }) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [preferredName, setPreferredName] = useState('');

  const [intro, setIntro] = useState('');
  const [userid, setUserId] = useState('');
  const [applytime, setApplyTime] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null); 
  const [resumeReview, setResumeReview] = useState(new Map()); // resume review
  const [serviceTwo, setServiceTwo] = useState(new Map()); // phoneScreen
  const [itvTwo, setITVTwo] = useState(new Map()); // technical interview
  const [itvThree, setITVThree] = useState(new Map()); // coding challenge
 
  const [otherSess, setOtherSess] = useState(new Map());
  const [sessions, setSessions] = useState([]);

  const [photoSignedURL, setPhotoSignedURL] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const fileInputRef = React.useRef(null);

  const triggerFileInput = () => {
    console.log('Attempting to trigger file input...');
    fileInputRef.current.click();
    console.log('File input should have been triggered.');
  };



  useEffect(() => {

    let isMounted = true; 
    API.get('hrapi', '/hr/:userid').then((res) => {
        
        if (res.length > 0 && isMounted) {
          // console.log('res in HR profile', res);
          setUserId(res[0].userid);
          setApplyTime(res[0].applytime);
          setPreferredName(res[0].preferredN || '');
          setIntro(res[0].intro);
          // optional fields that do not exist for new HRs
          const fields = ['resRev', 'phoneScreen', 'technicalITV', 'codingChall', 'otherSessions'];
          fields.forEach(field => {
            if (field in res[0]) {
              switch (field) {
                case 'resRev':
                  setResumeReview(transformToMap(res[0].resRev, 'Resume review'));
                  break;
                case 'phoneScreen':
                  setServiceTwo(transformToMap(res[0].phoneScreen, 'Mock interview - Phone screening'));
                  break;
                case 'technicalITV':
                  setITVTwo(transformToMap(res[0].technicalITV, 'Mock interview - Technical'));
                  break;
                case 'codingChall':
                  setITVThree(transformToMap(res[0].codingChall, 'Mock interview - Coding Challenge'));
                  break;
                case 'otherSessions':
                  const otherSessions = res[0].otherSessions;
                  
                // If length is 1, set otherSess
                if (otherSessions.length === 1) {
                  setOtherSess(transformToMap(otherSessions, 'Other'));
                } else if (otherSessions.length > 1) {
                  // If length is greater than 1, set the first key-value pair to otherSess
                  setOtherSess(transformToMap([otherSessions[0]], 'Other'));

                  // Set the rest of the key-value pairs to sessions
                  // const remainingSessions = transformToMap(otherSessions.slice(1), 'Other');
                  const remainingSessions = otherSessions.slice(1).map(session => transformToMapForSessions(session));
                  setSessions(remainingSessions);
                }
                default:
                  break;
              }
            }
          });


          if (res[0].photoname) {
            const filename = res[0].photoname;
            
            // Call getSignedUrl with the filename and userid
            getSignedUrl(filename, res[0].userid)
              .then((signedUrl) => {
                // Use the signed URL as needed
                setPhotoSignedURL(signedUrl);
                // console.log("Signed URL:", signedUrl);
              })
              .catch((error) => {
                console.error('Error getting signed URL:', error);
              });
          }
        }

    })
    .catch(error => {
        console.error('Error retrieving status:', error);
      });

    return () => {
      isMounted = false;  // Update component mount status when unmounting
    };

  }, []);

  // here, it works for all four sessions including "Other" as represented by ServiceItemOther
  const transformToMapForSessions = (dynamoDBFormat) => {
    const map = {};
  
    Object.entries(dynamoDBFormat).forEach(([key, value]) => {
      map[key] = Object.values(value)[0];
      // if (typeof Object.values(value)[0] === 'number') {
      //   map[key] = parseFloat(Object.values(value)[0]); // Convert numbers to floating point
      // } else if (typeof Object.values(value)[0] === 'string') {
      //   map[key] = Object.values(value)[0]; // Keep strings as they are
      // }
    });
  
    return map;
  };



  const transformToMap = (dynamoDBFormat, nameOfService) => {
    const attributesObject = {}; // This will hold all attributes
    // console.log('dynamoDBFormat, ', nameOfService, dynamoDBFormat);

    

    Object.entries(dynamoDBFormat[0]).forEach(([key, value]) => {
      // const type = Object.keys(value)[0]; // Get the type descriptor (e.g., 'S' or 'N')
      const actualValue = Object.values(value)[0]; // Get the actual value
      attributesObject[key] = actualValue;

    });
  
    const map = new Map();
    map.set(nameOfService, attributesObject); // Store the single object under key '0'
    // console.log(nameOfService, map);
    return map;
  };
  
  

  const handleProfilePhotoUpload = async () => {
    if (!profilePhoto || profilePhoto.size === 0) {
        // setUploadMessage('No files selected for upload.');
        // console.log('No files selected for upload.');
        return;
      }

    try {
      const result = await Storage.put(profilePhoto.name, profilePhoto, {
        contentType: profilePhoto.type,
        level: 'protected', // Set the access level (public or private)
      });
      // console.log('File uploaded:', result);
      return; // Preserve the file name
    }catch (error) {
      console.error('Error uploading files:', error);
        // Display error message if upload fails
      setUploadMessage('Upload failed. Please try again.');
    }


  };

  // Define a function to transform a map to DynamoDB format
  const transformToDynamoDBFormat = (map) => {
    
    let dynamoDBFormat = [];
    map.forEach((value, key) => {
      // console.log('value,', value);

      const items = {
        appointmentLength: { N: value.appointmentLength },
        charge: { N: value.charge },
        numSess: {N: value.numSess },
        };

      
      dynamoDBFormat.push(items);

    });
    return dynamoDBFormat;
  };

  // Define a function to transform a map to DynamoDB format
  const transformToDynamoDBFormatForOther = (map) => {
    // let dynamoDBFormat = {};
    let dynamoDBFormat = [];
    
    map.forEach((value, key) => {
      const items = {
        appointmentLength: { N: value.appointmentLength },
        charge: { N: value.charge },
        numSess: {N: value.numSess},
        descp: {S: value.descp},
        title: {S: value.title},
        };

        dynamoDBFormat.push(items);


    });

    return dynamoDBFormat;
  };

  const transformToDynamoDBFormatForObjArr = (obj) => {

    let dynamoDBFormat = [];
    for (let i = 0; i < Object.keys(obj).length; i++) {
      const key = String(i); // Convert index to string
      const value = obj[key];
      // console.log('key-value in objectOther', key, value);
      // Now you can access properties of the nested object
      const items = {
        appointmentLength: { N: value.appointmentLength },
        charge: { N: value.charge },
        numSess: {N: value.numSess},
        descp: {S: value.descp},
        title: {S: value.title},
        };


      dynamoDBFormat.push(items);
    }

    return dynamoDBFormat;
  };


  const RequiredAsterisk = () => <sup style={{ color: 'red' }}>*</sup>;

  const handleSubmit = (e) => {
    // console.log('resumeReview, ', resumeReview, 'phoneScreen,', serviceTwo, 'otherSess', otherSess, 'sessions', sessions);
    if (!preferredName || !intro) {
      alert('Please fill in all required fields.');
      return;
    }
    const body = {
      userid: userid,
      applytime: applytime,
      preferredN: preferredName,
      intro: intro,
    };

    if (profilePhoto?.name) {
      // Add the photo name to the body if profilePhoto exists and has a name
      body.photoname = profilePhoto.name;
    }

    if (resumeReview.size > 0) {
      body.resRev = transformToDynamoDBFormat(resumeReview);
    }
    if (serviceTwo.size > 0) {
      body.phoneScreen = transformToDynamoDBFormat(serviceTwo);
    }
    if (itvTwo.size > 0) {
      body.technicalITV = transformToDynamoDBFormat(itvTwo);
    }
    if (itvThree.size > 0) {
      body.codingChall = transformToDynamoDBFormat(itvThree);
    }

    if (otherSess.size === 0 && sessions.length === 0) {
      // If both are empty, do nothing
    } else if (otherSess.size > 0 && sessions.length > 0) {
      const transOtherSess = transformToDynamoDBFormatForOther(otherSess);
      const transSessions = transformToDynamoDBFormatForObjArr(sessions);
      // Transform the combined array and add it to body
      // console.log('transOtherSess', transOtherSess, 'transSessions', transSessions);
      const transCombo = [...transOtherSess, ...transSessions]; // transOtherSess:[{...}], transSessions: [{...}, {...}]
      // console.log('transCombo,', transCombo);
      // const transformedCombinedArray = combinedArray.map(map => transformToDynamoDBFormatForOther(map));
      body.otherSessions = transCombo;
    } else {
      // Transform "otherSess" if not empty and add it to body
      if (otherSess.size > 0) {
        body.otherSessions = transformToDynamoDBFormatForOther(otherSess);
      }
    
      // Transform "arrayOfMaps" if not empty and add it to body
      if (sessions.length > 0) {
        const transformedArray = transformToDynamoDBFormatForObjArr(sessions);
        body.otherSessions = transformedArray;
      }

    }

    // console.log('body,', body);

    API.put('hrapi', '/hr', { body });
    // console.log('Submitting availability...');
    alert('Submission successful!');
  };

  const handleCancel = () => {
    // Logic for canceling
    const isConfirmed = window.confirm("Are you sure you want to cancel the edits??"); // Show confirmation dialog
  
    if (isConfirmed) {
      onCancel();
    } 
  };

  // const getSignedUrl = async (filename, targetIdentityId) => {
  //   try {
  //       const config = {
  //       level: 'protected', // defaults to `public`
  //       identityId: targetIdentityId, // id of another user, if `level: protected`
  //       download: false, // defaults to false
  //       expires: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
  //       validateObjectExistence: true, // defaults to false
  //       cacheControl: undefined, // Specifies caching behavior along the request/reply chain
  //       };
    
  //       const signedUrl = await Storage.get(filename, config);
  //       //  const signedUrl = await Storage.get(filename, { level: 'protected', validateObjectExistence: true, identityId: targetIdentityId });


  //       return signedUrl;
  //   } catch (error) {
  //       console.error('Error generating signed URL:', error);
  //       throw error;
  //   }
  //   };

  const handleProfilePhotoChange = (e) => {

    console.log('triggered handleProfilePhotoChange');
    const file = e.target.files[0];
    console.log('file', file);

    setProfilePhoto(file);
    // console.log('new file selected,', e.target.files[0]);

    const reader = new FileReader();

    // Define a callback function to handle the file reading operation
    reader.onloadend = () => {
      // Set the preview URL to the result of the file reading
      setPreviewUrl(reader.result);
      console.log('previewUrl is updated', reader.result);
    };

    // Read the selected file as a data URL (base64 encoded)
    if (file) {
      reader.readAsDataURL(file);
    }
  };



  return (
    <div style={{ display: isCollapsed ? 'none' : 'block' }}>
      <Grid container spacing={2}>


        <Grid item xs={12} md={3} lg={3} container direction="column" rowGap={1}
            justifyContent="center"
            alignItems="center">

              <div className="photo-container" onClick={triggerFileInput}>
               {/*<div style={{ width: '150px', height: '200px', borderRadius: '50%', backgroundColor: 'lightgrey', position: 'relative', overflow: 'hidden' }}>*/}
                {/* Display the uploaded photo, preview, or the photo from the signed URL */}
                {/* {photoSignedURL ? (
                  <img src={photoSignedURL} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : previewUrl ? (
                  <img src={previewUrl} alt="Profile Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                  <label htmlFor="profile-photo-upload" style={{ fontSize: '24px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer' }}>
                    {profilePhoto ? profilePhoto.name : 'Click to select file'}
                  </label>
                )} */}
                  {photoSignedURL || previewUrl ? (
                    <ProfilePhoto url={previewUrl || photoSignedURL} />
                  ) : (<i className="fas fa-user-circle icon-placeholder"></i>)}
                <div className="photo-overlay">Change Profile Photo</div>
              </div>
    
            <input
                accept="image/*"
                id="profile-photo-upload"
                ref={fileInputRef}
                type="file"
                onChange={handleProfilePhotoChange}
                style={{ display: 'none' }}
            />
          
            {/* <Button variant="contained" component="span" onClick={handleProfilePhotoUpload}>
              Upload Profile Photo
            </Button> */}
          
        </Grid>
        <Grid item container direction="row"
                justifyContent="flex-start"
                alignItems="flex-start" xs={12} md={9} lg={9} gap={1}>
            <Grid item xs={12} md={8} lg={8}>
                <TextField
                    fullWidth
                    label={<>Preferred Name<RequiredAsterisk /></>}
                    value={preferredName}
                    onChange={(e) => setPreferredName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    id="multiline-static-intro"
                    label={<>Tell us about yourself<RequiredAsterisk /></>}
                    multiline
                    rows={4}
                    sx={{ width: '100%' }}
                    value={intro}
                    onChange={(e) => setIntro(e.target.value)}
                />
            </Grid>

            <ServiceItem
                serviceName="Resume review"
                serviceState={resumeReview}
                setServiceState={setResumeReview}
            />

            <ServiceItem
                serviceName="Mock interview - Phone screening"
                serviceState={serviceTwo}
                setServiceState={setServiceTwo}
            />

            <ServiceItem
                serviceName="Mock interview - Technical"
                serviceState={itvTwo}
                setServiceState={setITVTwo}
            />

            <ServiceItem
                serviceName="Mock interview - Coding Challenge (coming soon)"
                serviceState={itvThree}
                setServiceState={setITVThree}
            />

            <ServiceItemOther
                serviceName="Other"
                serviceState={otherSess}
                setServiceState={setOtherSess}
            />
            <ServiceItemOtherArray serviceName="Other"
                serviceState={sessions}
                setServiceState={setSessions}
                />

        </Grid>
      </Grid>
    
      <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: '10px', paddingTop: '40px'}}>
        <Button variant="outlined" size="large" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" size="large" color="primary" onClick={() => {
            handleSubmit(); // Call handleSubmit function
            handleProfilePhotoUpload(); // Call handleUpload function
          }}>
          Submit
        </Button>

      </div>
    </div>

  );
};

export default HRProfileForm;

