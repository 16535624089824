import React, { useState, useEffect } from 'react';
import { API, Storage, Auth } from 'aws-amplify';
import withNonAdminAccess from '../components/withNonAdminAccess';
import Container from "@awsui/components-react/container";
import { Flex, Card } from '@aws-amplify/ui-react';
import { Button, Grid } from '@mui/material';
import Header from "@awsui/components-react/header";
import AvailabilityComponent from '../components/AvailabilityComponent';
import HRProfileForm from '../components/HRProfileForm';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Overlay from '../components/Overlay';
import ProfileCard from '../components/ProfileCard';
import ProfileOverlay from '../components/ProfileOverlay';
import ConfirmationOverlay from './AppointmentConfirmationPage';
import { processMeetings } from '../utils/objectUtils';
import ServiceFilter from '../components/ServiceFilter';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationPanel from '../components/NotificationPanel';
import { Tooltip, Typography } from '@mui/material';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@mui/icons-material/Info';
import '../styles/Loading.css';

import UpcomingAppts from '../components/UpcomingAppts';
import MeetingDetails from '../components/MeetingDetails';
import WebinarList from '../components/WebinarList';

const AskHR = () => {
    const [appstatus, setAppStatus] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [comment, setComment] = useState ('');
    const [userId, setUserId] = useState('');
    const [applyTime, setApplyTime] = useState('');
    const [availButtonClicked, setAvailButtonClicked] = useState(false);
    const [editProfileClicked, setEditProfileClicked] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadMessage, setUploadMessage] = useState('');
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [allProcMeetings, setAllProcMeetings] = useState([]);
    const [expandApptsClicked, setExpandApptsClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Introduce a loading state
    const [isSubmitted, setIsSubmitted] = useState(false);

    const webinars = [
      {
        title: "Acing your Interview - Tips and Tricks to help land your next role",
        intro: "Join us for a free webinar where a seasoned recruiter will share expert tips.",
        registerUrl: "https://events.teams.microsoft.com/event/54e0decf-cc10-4e4a-a373-c9706ba49c17@46b22868-287c-4bcb-9a75-372618ceca57",
        time: "Thursday, June 6, 2024, at 5:00 PM Eastern Time",
        presenterName: "Emily Mason",
        presenterTitle: "People Strategist"
      }];


    useEffect(() => {
      setupApplicationSubmissionListener();
    }, []);

      // setting up display for all personnel
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);


  const handleMoreInfoClicked = (profile) => {
    setSelectedProfile(profile);
  };

  const handleCloseOverlay = () => {
    setSelectedProfile(null);
    setIsConfirmationVisible(false);
  };


  // useEffect(() => {
  //   let isMounted = true;
  //   API.get('hrapi', '/hr?status=1&checkFields=true').then((res) => {
  //     // console.log('all hr data approved and have sessions added', res);
  //     if (isMounted) {
  //     setProfiles(res);
  //     setFilteredProfiles(res); // Initially, no filter is applied
  //     }
  //   });

  //   return () => {
  //     isMounted = false; // Set the flag to false when the component unmounts
  //   };
  // },[]);
  useEffect(() => {
    let isMounted = true;
    
    const fetchProfiles = async () => {
        try {
            // Get the current authenticated user and their groups
            const user = await Auth.currentAuthenticatedUser();
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];

            const response = await API.get('hrapi', '/hr?status=1&checkFields=true');
            // console.log('all hr profiles,', response);
            // Check if user is in the "tester" group
            const isTester = groups && groups.includes('tester');
            // console.log('user tester group,', isTester);
            if (isMounted) {
                if (isTester) {
                    // Filter to include only tester profiles
                    const testerProfiles = response.filter(profile => profile.tester === true);
                    setProfiles(testerProfiles);
                    setFilteredProfiles(testerProfiles);
                    // console.log('tester profiles,', testerProfiles);
                } else {
                    // Filter out tester profiles
                    const nonTesterProfiles = response.filter(profile => profile.tester !== true);
                    setProfiles(nonTesterProfiles);
                    setFilteredProfiles(nonTesterProfiles);
                    // console.log('non tester profiles,', nonTesterProfiles);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchProfiles();

    return () => {
        isMounted = false; // Cleanup the component
    };
}, []);


  useEffect(() => {
    let isMounted = true;
    API.get('allusersapi', '/allusers/:userid').then((res) => {
      // console.log('all hr data approved and have sessions added', res);
      if (isMounted) {
        
        // const part_meetings = [appointmentDetails, ...(res[0].part_meetings || [])];
        const part_meetings = appointmentDetails
                              ? [appointmentDetails, ...(res[0]?.part_meetings || [])]
                              : [...(res[0]?.part_meetings || [])];
        const host_meetings = res[0]?.host_meetings || [];
        const merged_meetings = [...part_meetings, ...host_meetings];
        const allprocessedmeetings = processMeetings(merged_meetings);
        // const allPartMeetings = processMeetings(part_meetings);
        // const allHostMeetings = processMeetings(res[0].host_meetings);
        // console.log('all part meetings, ', allPartMeetings, 'before conversion,', part_meetings, 'res[0].part_meetings', res[0].part_meetings);
        // setPartMeetings(allPartMeetings);
        // setHostMeetings(allHostMeetings);
        setAllProcMeetings(allprocessedmeetings);
        // console.log('meetings,', allPartMeetings);
      }
    });

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  },[]);

  
    function setupApplicationSubmissionListener() {
      window.addEventListener('message', (event) => {
  
          if (event.data === 'applicationSubmitted') {
              setIsButtonVisible(false);
          }
      });
    }

    const handleApiResponse = (apistatus) => {
        if (apistatus === 1 && !localStorage.getItem('messageDisplayed')) {
          alert("Congratulations! Your application has been approved. Please fill out your calendar and let's make a meaningful impact in assisting job seekers on their journey to employment.");
          
          localStorage.setItem('messageDisplayed', 'true');
        }
    
        // Handle other logic based on apistatus if needed
      };
  
    const toggleCard = () => {
      setEditProfileClicked(!editProfileClicked);
    };

    const toggleUpcomingAppts = () => {
      setExpandApptsClicked(!expandApptsClicked);
    }

    useEffect(() => {
      if (isSubmitted && availButtonClicked) {
        // Directly set it to false to fold without confirmation
        setAvailButtonClicked(false);
        // Optionally reset isSubmitted here if needed for future submissions
        setIsSubmitted(false);
      }
      // This effect should run whenever isSubmitted changes
    }, [isSubmitted, availButtonClicked]);
    


    const availToggleCard = () => {
      // Check if the tab is currently unfolded
      console.log('availButtonClicked && !isSubmitted', availButtonClicked && !isSubmitted, availButtonClicked, !isSubmitted);
      if (availButtonClicked && !isSubmitted) {
        const confirmation = window.confirm("Kindly confirm that you've submitted your availability. If you've already done so, please click 'OK'. If you need a moment to complete your submission, click 'Cancel' to go back.");
        
        if (confirmation) {
          // User clicked 'OK', fold the tab
          setAvailButtonClicked(false); // Directly set it to false to fold
        } else {
          // User clicked 'Cancel', do nothing to allow the user more time
        }
      } else {
        // If the tab is currently folded, just unfold it without asking for confirmation
        setAvailButtonClicked(!availButtonClicked); // trueDirectly set it to true to unfold
      }

        // Reset submission state if closing
        if (availButtonClicked) {
          setIsSubmitted(false);
        }
    };

    // const availToggleCard = () => {
    //   console.log('availButtonClicked && !isSubmitted', availButtonClicked && !isSubmitted, availButtonClicked, !isSubmitted);
    //   // Only prompt confirmation if it's an unfold action or a fold action without submission
    //   if (!isSubmitted && (availButtonClicked || !availButtonClicked)) {
    //     const confirmation = window.confirm("Kindly confirm that you've submitted your availability...");
    //     if (confirmation || !availButtonClicked) {
    //       setAvailButtonClicked(!availButtonClicked);
    //     }
    //   } else if (!availButtonClicked) {
    //     // This case handles direct unfolding without needing confirmation
    //     setAvailButtonClicked(true);
    //   }
    // };
    
    
  

    useEffect(() => {
        let isMounted = true;

        API.get('hrapi', '/hr/:userid').then((res) => {
            
            if (isMounted) {

                if (res.length > 0) {
                  const apistatus = res[0].status;
                  const comment = res[0]?.comment;
                  const fns = res[0]?.filenames;
                  setUserId(res[0].userid);
                  setApplyTime(res[0].applytime);
                  setUploadedFileNames(fns);

                  setComment(comment);
                  setAppStatus(apistatus);
                  setIsButtonDisabled(apistatus === 0);
                  setIsButtonVisible(!(apistatus === 1 || apistatus === 2));
                  handleApiResponse(apistatus);
                  
                }
                setIsLoading(false);
            }

            
        })
        .catch(error => {
            console.error('Error retrieving status:', error);
          });

        return () => {
          isMounted = false;
        };


    }, [])


    const openApplication = () => {
        window.open('/apply', '_blank');
    };
    

    const handleBookNow = (newAppointmentDetails) => {
      setAppointmentDetails(newAppointmentDetails); // Update appointment details
      setIsConfirmationVisible(true); // Show confirmation overlay
    };


    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles(files);
    };

    const handleFileUpload = async (e) => {
      e.preventDefault();
        // Check if no files are selected
      if (selectedFiles.length === 0) {
        setUploadMessage('No files selected for upload.');
        return;
      }
      try {
        // Upload each selected file
        const uploadPromises = selectedFiles.map(async (file) => {
          const result = await Storage.put(file.name, file, {
            contentType: file.type,
            level: 'protected', // Set the access level (public or private)
          });
          // console.log('File uploaded:', result);
          return file.name; // Preserve the file name
        });
  
        // Wait for all uploads to complete
        // const uploadedFileNames = await Promise.all(uploadPromises);
  
        const newUploadedFileNames = await Promise.all(uploadPromises);
  
        // Update the state with the new file names
        setUploadedFileNames((prevUploadedFileNames) => [
          ...prevUploadedFileNames,
          ...newUploadedFileNames,
        ]);

        const updatedFileNames = [...uploadedFileNames, ...newUploadedFileNames];
  
        // Display success message and the preserved file names
        setUploadMessage(`Upload successful. Files: ${newUploadedFileNames.join(', ')}`);
    // update HR-dev dynamodb
        // console.log('updatedFileNames,', updatedFileNames);
      try {
        const newStatus = 0;
        const response = await API.put('hrapi', '/hr', 
        {
          body: {
              userid: userId,
              applytime: applyTime,
              status: newStatus,
              filenames: updatedFileNames,
          },
          queryStringParameters: {
            reupload: 'true',
          },
        },
        
        );
        // console.log('File upload response:', response);
        alert("Submission successful. Click 'OK' to continue.");
        localStorage.setItem('postRefreshMessage', 'Re-submission successful, we will get back to you in two business days');
        window.location.reload(); // Refreshes the page
      } catch (error) {
        console.error('Error uploading file:', error);
      }


    } catch (error) {
      console.error('Error uploading files:', error);
      // Display error message if upload fails
      setUploadMessage('Upload failed. Please try again.');
    }



    };


    const renderContent = () => {
        switch (appstatus) {

          case 0: // submitted, being reviewed
            return (
            
                <Container header={
                <Header variant="h2">
                    HR Dashboard
                </Header>}
                >
                    <p>Your application is currently being reviewed. Thank you for your patience.</p>
              </Container>
            );
          case 1: // approved, please add open time slots
            return (
              <>
              <Flex 
                  direction="column"
                  justifyContent="flex-start"
                  gap="1rem"
              >

              
              <Container header={
                <Header variant="h2">
                    <Tooltip title={<Typography fontSize={14}>Thank you for joining us as industry mentors! Beta testing starts now. Please ensure your service settings and profiles are updated. If you have any questions, feel free to reach out at contact@idyllic.ai. We look forward to embarking on this exciting journey together!</Typography>}>
                      <div>
                        <span>HR Dashboard </span>
                        <InfoIcon/>
                      </div>
                    </Tooltip>
                </Header>}
              >

                  <Card>
                    <Flex direction="row" gap="10px">
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', gap: '10px' }}>
                          <Button onClick={availToggleCard} startIcon={availButtonClicked ? <ExpandLessIcon /> : <EditIcon />}>
                            {availButtonClicked ?  'Collapse': 'Add Availability'}
                          </Button>
                        </div>    
                        
                        <div>
                            {availButtonClicked && (
                              <Overlay onClose={availToggleCard}>
                                <AvailabilityComponent onClose={availToggleCard} setIsSubmitted={setIsSubmitted} />
                              </Overlay>
                            )}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', gap: '10px' }}>
                          <Button onClick={toggleCard} startIcon={editProfileClicked ? <ExpandLessIcon /> : <EditIcon />}>
                            {editProfileClicked ?  'Collapse': 'Edit Profile'}
                          </Button>
                        </div> 

                        <div>
                          {editProfileClicked && (
                          // <HRProfileForm onCancel={toggleCard}/>
                            <Overlay onClose={toggleCard}>
                              <HRProfileForm onCancel={toggleCard}/>
                            </Overlay>
                          )}
                        </div>

                    </Flex>

                  </Card>


              </Container>


                
              </Flex>
              </>
              
            );
          case -1: // application is declined
            return (
              <Card variation="elevated">
              <div>
                {/* <h2>Content C</h2> */}
                <p>We appreciate your application, and we want to thank you for your interest. After careful consideration, we regret to inform you that we are unable to proceed with your application at this time. We truly value your efforts and encourage you to consider applying again in the future. Thank you for your understanding.</p>
              </div>
              
              </Card>
            );
          case 2: // request for more supporting documents
            return (
              <>
              <Card variation="elevated">
              <div>

                <p>Please provide the additional documents mentioned in the comments below to complete your application verification. Your prompt response is appreciated. For any questions or assistance, feel free to contact us. Thank you for your cooperation.</p>
                {/* <p>Comment from us: {comment}</p> */}
                {comment !== '' && (<div style={{
                    backgroundColor: '#f0f0f0', // Dark grey background
                    color: '#333', // White text color for contrast
                    padding: '10px', // Some padding around the text
                    borderRadius: '5px', // Rounded corners for a nicer look
                    fontFamily: 'monospace', // Monospaced font for the "computer output" look
                    fontSize: 'smaller', // Smaller font size
                    marginTop: '10px', // Space above the div
                    marginBottom: '10px', // Space below the div
                  }}>
                    {comment}
                  </div>)}

                <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} multiple />
                  <Button variant="contained" onClick={handleFileUpload}>Upload</Button>
              </div>
              <div style={{ display: 'block' }}> {uploadMessage && <p>{uploadMessage}</p>}  </div>
              </Card>
              </>
            );
          default:
            return null; // Do not display anything for unhandled or unexpected status values
        }
      };

    return (
        <div style={{ position: 'relative' }}>

            {
              isLoading && (
                <div className="loadingOverlay">
                  <CircularProgress />
                </div>
              )
            }

          <div>
            
            {/* {appstatus === 0 && (
                <p>Your application is currently being reviewed. Thank you for your patience.</p>
            )} */}
            <div>{renderContent()}</div>

            {/* {appointmentDetails && <Container header={
                <Header variant="h2">
                    Upcoming Appointments
                </Header>} style={{ paddingTop: '20px' }}>

              <div>
                <p>You are meeting with {appointmentDetails.provider} at {appointmentDetails.meetHours}</p>
                <Button variant='contained'>Join the meeting</Button>
              
              </div>
              
            </Container>} */}
            {/* <Container header={
                <Header variant="h2">
                    Upcoming Appointments
                </Header>} style={{ paddingTop: '20px' }}>


              <div>
                {appointmentDetails && <MeetingDetails appointmentDetails={appointmentDetails}/>}

              </div>

              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', gap: '10px' }}>
                <Button onClick={toggleUpcomingAppts} startIcon={expandApptsClicked ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {expandApptsClicked ?  'Collapse': 'See all appointments'}
                </Button>
              </div> 

              <div>
                {expandApptsClicked && (
                // <HRProfileForm onCancel={toggleCard}/>
                  <UpcomingAppts meetings={allProcMeetings} />
                )}
              </div>

              
            </Container> */}

            <Container header={
                <Header variant="h2">
                    Webinars
                </Header>} style={{ paddingTop: '20px', padding: '20px' }}>
                <div>
                  <Typography variant='h5'>Join this webinar for free and earn two credits for future webinars!</Typography>
                  <Typography variant="body1" color="textSecondary"><strong>Spots for this webinar are limited.</strong></Typography>
                  <Typography variant="body1" color="textSecondary">Check your credit balance by clicking on the profile icon in the top right corner.</Typography>

                  <WebinarList webinars={webinars} />
                </div>
            </Container>

            <Container header={
                <Header variant="h2">
                    All services
                </Header>} style={{ paddingTop: '20px' }}>
                
                <NotificationPanel />

                <div>
                  <ServiceFilter profiles={profiles} 
        setFilteredProfiles={setFilteredProfiles} ></ServiceFilter>
                </div>

                <Grid container spacing={2} justifyContent="center">
                  {filteredProfiles?.map((profile,index) => (
                    <Grid item xs={6} sm={3} md={3} lg={2} key={index} data-index={index}>
                       <ProfileCard key={index} data-index={index} profile={profile} onMoreInfoClicked={handleMoreInfoClicked} />
                    </Grid>
                  ))}
                  {selectedProfile && (<Overlay onClose={handleCloseOverlay}> <ProfileOverlay profile={selectedProfile} onBookNow={handleBookNow} /> </Overlay>)}
                
                      {/* Confirmation Overlay */}
                  {isConfirmationVisible && (
                    <Overlay onClose={handleCloseOverlay}>
                      <ConfirmationOverlay
                        appointmentDetails={appointmentDetails}
                        onClose={handleCloseOverlay}
                      />
                    </Overlay>
                  )}
                
                
                </Grid>
              </Container>
            
            {isButtonVisible && (
                    <button
                    className={isButtonDisabled ? 'greyed-out' : ''}
                    onClick={openApplication}
                    disabled={isButtonDisabled}
                    >
                    Apply
                    </button>
                )}

            
          </div>
        </div>
    );
};

export default withNonAdminAccess(AskHR);