import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import AppointmentListDisplay from '../components/AppointmentListDisplay';
import { API } from 'aws-amplify';
import { transformUtcToLocal } from '../utils/objectUtils';
import { CircularProgress, Divider } from '@mui/material';
import { Flex } from '@aws-amplify/ui-react';
import ChangeApptOptions from '../components/ChangeApptOptions';
import HrChangeApptOptions from '../components/HrChangeApptOptions';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';


function OrdersPage() {

    const [orders, setOrders] = useState([]);
    const [hrEarnings, setHrEarnings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        // invalidateOrderCache('partMeetings');
        // invalidateOrderCache('hostMeetings');
      
        const fetchData = async () => {

            const cachedOrders = localStorage.getItem('partMeetings');
            const cachedEarnings = localStorage.getItem('hostMeetings');

            if (cachedOrders && cachedEarnings) {
                // Data found in local storage, parse and set to state
                setOrders(JSON.parse(cachedOrders));
                setHrEarnings(JSON.parse(cachedEarnings));
                console.log('orders:', JSON.parse(cachedOrders), 'earnings:', JSON.parse(cachedEarnings));
                setIsLoading(false);
            } else {
            // Data not found in local storage, fetch from API
            try {
                const [userRes, providerRes] = await Promise.all([
                API.get('meetingsapi', `/meetings/user-orders/:userid`),
                API.get('meetingsapi', `/meetings/hr-earnings/:hrid`)
                ]);
        
                if (isMounted) {
                //   const updatedAppointments = processAppointments(appointmentsRes);
                //   const processedOtherData = processOtherData(anotherRes);
                // Optionally combine data or update state separately
                const partMeetings = userRes && userRes.length > 0 ? userRes : [];
                const hostMeetings = providerRes && providerRes.length > 0 ? providerRes : [];
                const enhancedPartMeetings = partMeetings.map(meeting => ({
                    ...meeting,
                    preset: 'group_call_participant'
                  }));
              
                  // Add "preset" to each meeting in host_meetings
                  const enhancedHostMeetings = hostMeetings.map(meeting => ({
                    ...meeting,
                    preset: 'group_call_host'
                  }));
                
                setOrders(partMeetings);
                setHrEarnings(hostMeetings);
        
                localStorage.setItem('partMeetings', JSON.stringify(enhancedPartMeetings));
                localStorage.setItem('hostMeetings', JSON.stringify(enhancedHostMeetings));
        
                setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
            }
        };
      
        fetchData();
      
        return () => {
          isMounted = false;
        };
      }, []);

    // useEffect(() => {
    //     let isMounted = true;
    
    //     const fetchAppointment = async () => {
    //       try {
    //         const res = await API.get('meetingsapi', `/meetings/user-orders/:userid`);
    //         // console.log('fetched meeting', res);
    //         if (isMounted) {
    //             const updatedOrders = res.map(order => {
    //                 // Transform meetHours to meetTimes
    //                 console.log('meetHours:', order.meetHours, 'stuTZ:', order.stuTZ);
    //                 const meetTimes = transformUtcToLocal(order.meetHours, order.stuTZ);
    //                 // Calculate charge per session
    //                 const chargePS = order.total / order.numSession;
            
    //                 // Return a new object with the updated properties
    //                 return {
    //                     ...order, // Spread the original order to maintain other properties
    //                     meetTimes, // Updated meetTimes
    //                     chargePS   // Updated charge per session
    //                 };
    //             });
            
    //             setOrders(updatedOrders); // Update the state with the new orders array
    //             setIsLoading(false);
    //         }
    //       } catch (error) {
    //         console.error('Error fetching appointment data', error);
    //       }
    //     };
    
    //     fetchAppointment();
    
    //     return () => {
    //       isMounted = false;
    //     };
    //   }, []);

    const fetchOrders = async() => {
        setIsLoading(true);
        // Fetch orders from an API
        try {
            const [userRes, providerRes] = await Promise.all([
            API.get('meetingsapi', `/meetings/user-orders/:userid`),
            API.get('meetingsapi', `/meetings/hr-earnings/:hrid`)
            ]);
    
           
            //   const updatedAppointments = processAppointments(appointmentsRes);
            //   const processedOtherData = processOtherData(anotherRes);
            // Optionally combine data or update state separately
            const partMeetings = userRes && userRes.length > 0 ? userRes : [];
            const hostMeetings = providerRes && providerRes.length > 0 ? providerRes : [];
            const enhancedPartMeetings = partMeetings.map(meeting => ({
                ...meeting,
                preset: 'group_call_participant'
              }));
          
              // Add "preset" to each meeting in host_meetings
              const enhancedHostMeetings = hostMeetings.map(meeting => ({
                ...meeting,
                preset: 'group_call_host'
              }));
            
            setOrders(partMeetings);
            setHrEarnings(hostMeetings);
    
            localStorage.setItem('partMeetings', JSON.stringify(enhancedPartMeetings));
            localStorage.setItem('hostMeetings', JSON.stringify(enhancedHostMeetings));
    
            setIsLoading(false);
            
        } catch (error) {
            console.error('Error fetching data', error);
        }

      };

    const handleRefresh = () => {
        console.log("Refreshing orders...");
        fetchOrders();
      };

    const handleReschedule = (event, appointmentId) => {
    event.preventDefault();
    // Logic for rescheduling the session
    console.log('Reschedule clicked', appointmentId);
    navigate(`/orders/${appointmentId}/reschedule`);
    // navigate(`/appointments/${appointmentId}/reschedule?hrid=${hrid}`);
    };
    
    
    
    const handleCancel = (event, appointmentId) => {
    // Logic for canceling the session
    event.preventDefault();
    console.log('Cancel clicked');
    navigate(`/orders/${appointmentId}/cancel`);
    
    };


    return (
        <Paper>
        {!isLoading && orders.length === 0 && hrEarnings.length === 0 && <Typography variant="h4" align="center" style={{ marginTop: '20px' }}>No Orders Found</Typography>}
        {/* <Typography variant="h4" align="center" style={{ marginTop: '20px' }}>Your Orders</Typography> */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Typography variant="h4" component="h2" style={{ marginRight: '10px' }}>
                Your Orders
            </Typography>
            <IconButton onClick={handleRefresh} aria-label="refresh">
                <RefreshIcon />
            </IconButton>
        </div>
        {isLoading ? (<Flex direction='row' justifyContent="center" ><CircularProgress /> </Flex>) : (
            <>
                <div>
                    {orders.map((item, index) => (
                        <div key={index}>
                            <Flex direction='row' justifyContent="space-between" alignItems="center" style={{ marginLeft: '20px', marginRight: '20px' }} >
                            <AppointmentListDisplay order={item} />
                            {/* {isAtLeastTwoDaysAhead(item.meetHours) && 
                            <ChangeApptOptions onReschedule={(event) => handleReschedule(event, item.orderid)} onCancel={(event) => handleCancel(event, item.orderid)} /> 
                            } */}
                            {item.refund === true ? (
                                <Typography variant='h6'>Refund Requested</Typography> // Display "Refund Requested" when refund is true
                            ) : (
                                // Check if the meeting is at least two days ahead and not refunded
                                isAtLeastTwoDaysAhead(item.meetHours) && (
                                <ChangeApptOptions
                                    onReschedule={(event) => handleReschedule(event, item.orderid)}
                                    onCancel={(event) => handleCancel(event, item.orderid)}
                                />
                                )
                            )}
                            </Flex>
                            <Divider variant="middle"/>
                        </div>
                    
                     ))}
                </div>
            
                {hrEarnings.length>0 && (
                    <>
                    <Typography variant="h4" align="center" style={{ marginTop: '20px' }}>Your Earnings</Typography>
                        <div>
                            {hrEarnings.map((item, index) => (
                                <div key={index}>
                                    <Flex direction='row' justifyContent="space-between" alignItems="center" style={{ marginLeft: '20px', marginRight: '20px' }} >
                                    <AppointmentListDisplay order={item} />
                                    {/* {isAtLeastTwoDaysAhead(item.meetHours) &&
                                    <Button variant="contained" color="primary" onClick={(event) => handleCancel(event, item.orderid)}>Cancel</Button> } */}
                                    {item.refund === true ? (
                                        <Typography variant='h6'>Cancel Requested</Typography> // Display "Refund Requested" when refund is true
                                        ) : (
                                            // Check if the meeting is at least two days ahead and not refunded
                                            isAtLeastTwoDaysAhead(item.meetHours) && (
                                            <HrChangeApptOptions
                                                onCancel={(event) => handleCancel(event, item.orderid)}
                                            />
                                            )
                                        )}
                                    </Flex>
                                    <Divider variant="middle"/>
                                </div>
                            
                            ))}
                        </div>
                    </>
                )}
            </>
            )}
        
        
        
        
        </Paper>
    );
}

function isAtLeastTwoDaysAhead(meetHours) {
    const now = new Date();
    const twoDaysLater = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000); // Add 48 hours
    // console.log('meetHours:', meetHours);
    // Convert each UTC time in meetHours to a Date object and find the earliest
    const earliestMeeting = meetHours.reduce((earliest, current) => {
      const currentDate = new Date(current);
      return currentDate < earliest ? currentDate : earliest;
    }, new Date(meetHours[0]));
  
    return earliestMeeting >= twoDaysLater;
  }



function invalidateOrderCache(item) {
localStorage.removeItem(item);
}
export default OrdersPage;

