import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, TextField, InputAdornment } from '@mui/material';


const ServiceItemOther = ({ serviceName, serviceState, setServiceState }) => {

  const [isChecked, setIsChecked] = useState(false);
  
  useEffect(() => {
    // Check if the serviceState has the serviceName and set isChecked accordingly
    const hasService = serviceState.has(serviceName);
    setIsChecked(hasService);
    console.log('isChecked', isChecked, 'serviceState,', serviceState, 'serviceState.has', serviceState.has(serviceName));
  }, [serviceState]); // Depend on serviceState and serviceName to re-run this effect

    const handleServiceCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        console.log('original serviceState', serviceState);
        setServiceState((prevService) => {
            const updatedService = new Map(prevService);
            if (isChecked) {
            // Set initial values for service 1
            updatedService.set(serviceName, {
                appointmentLength: '0',
                charge: '0',
                numSess: '0',
                title: '',
                descp: '',
                // Add other attributes here
            });
            console.log('updatedService', updatedService);
            } else {
            // Remove service from the map if unchecked
            updatedService.delete(serviceName);
            }
            
            return updatedService;
        });

    };

  const handleNumSessionsChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);

        // const parsedValue = parseFloat(value);

        // service.numSess = parsedValue;
        service.numSess = value;
        
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };

  const handleAppointmentLengthChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        // const parsedValue = parseFloat(value);
        // service.appointmentLength = parsedValue;
        service.appointmentLength = value;
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };

  const handleChargeChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        // const parsedValue = parseFloat(value);
        // service.charge = parsedValue;
        service.charge = value;
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };



  const handleFieldChange = (field, value) => {
    
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        if (field === 'descp') {
          service.descp = value;
        } else if (field === 'title') {
          service.title = value;
        }
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };

  return (
    <Grid container item xs={12} md={12} lg={12} direction="row" justifyContent="flex-start" alignItems="flex-start" gap={1}>
      <Grid item xs={12} md={5} lg={5}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} name={serviceName} onChange={handleServiceCheckboxChange} />}
          label={serviceName}
        />
      </Grid>

      <Grid item xs={4} md={2} lg={2}>
        {/* {console.log('serviceState in Other,', serviceState)} */}
        {serviceState.has(serviceName) && (<TextField
          fullWidth
          size="small"
          label="Number of Sessions"
          type="number"
          value={serviceState.get(serviceName).numSess}
          onChange={handleNumSessionsChange}
        />)}
      </Grid>

      <Grid item xs={4} md={2} lg={2}>
      {serviceState.has(serviceName) && (<TextField
          fullWidth
          label="Session length"
          size="small"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">mins</InputAdornment>,
            min: 0
          }}
          value={serviceState.get(serviceName).appointmentLength}
          onChange={handleAppointmentLengthChange}
        />)}
      </Grid>
      
      <Grid item xs={4} md={2} lg={2}>
      {serviceState.has(serviceName) && (<TextField
          fullWidth
          size="small"
          label="Charge/session"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            min: 0
          }}
          type="number"
          value={serviceState.get(serviceName).charge}
          onChange={handleChargeChange}
        />)}
      </Grid>

      <Grid item xs={4} md={3} lg={3}>
        {serviceState.has(serviceName) && (
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={serviceState.get(serviceName).title || ''}
            onChange={(e) => handleFieldChange('title', e.target.value)}
            // onChange={(e) => setServiceState(prevState => ({
            //   ...prevState,
            //   [serviceName]: {
            //     ...prevState[serviceName],
            //     title: e.target.value
            //   }
            // }))}
          />
        )}
      </Grid>

      <Grid item xs={4} md={6} lg={6}>
        
        {serviceState.has(serviceName) && (
          <TextField
            fullWidth
            size="small"
            label="Discription"
            value={serviceState.get(serviceName).descp || ''}
            onChange={(e) => handleFieldChange('descp', e.target.value)}
            // onChange={(e) => setServiceState(prevState => ({
            //   ...prevState,
            //   [serviceName]: {
            //     ...prevState[serviceName],
            //     descp: e.target.value
            //   }
            // }))}
          />
        )}
      </Grid>


    </Grid>
  );
};

export default ServiceItemOther;