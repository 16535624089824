import { Typography, Button, CircularProgress, Paper, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { invalidateOrderCache, transformUtcToLocal } from '../utils/objectUtils';
import AppointmentListDisplay from '../components/AppointmentListDisplay';
import { Flex } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';


function CancelAppointmentPage() {
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [refundAmountInCents, setRefundAmountInCents] = useState(0);
    const [refundAmountInDollars, setRefundAmountInDollars] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { appointmentId } = useParams(); // appointmentId is order id
    const [order, setOrder] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [refundRequested, setRefundRequested] = useState(false);



    useEffect(() => {
        let isMounted = true;
    
        const fetchAppointment = async () => {
          try {
            const res = await API.get('meetingsapi', `/meetings/meeting-details/${appointmentId}`);
            // console.log('appointmentid', appointmentId, 'fetched meeting', res);
            if (isMounted) {
                res.meetTimes = transformUtcToLocal(res.meetHours, res.stuTZ);
                res.chargePS = res.total / res.numSession;

                setOrder(res);
                setRefundAmountInDollars(res.total);
                const totalChargeInCents = res.total*100;
                setRefundAmountInCents(totalChargeInCents);
               
                setPaymentIntentId(res.pi_id);

                setIsLoading(false);
            }
          } catch (error) {
            console.error('Error fetching appointment data', error);
          }
        };
    
        const refundStatus = getRefundStatus(appointmentId);
        if (refundStatus === true) {
            console.log('refund status is true');
            setRefundRequested(true);
        } else {
            console.log('refund status is false');
            setRefundRequested(false);
            
        }
        fetchAppointment();
    
        return () => {
          isMounted = false;
        };
      }, [appointmentId]);

    const handleRefund = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // console.log('paymentIntentId:', paymentIntentId, 'refundAmount:', refundAmount);
        if (order.refund === true) {
            
            setRefundRequested(true);
            setIsLoading(false);
            return;
        }
        
        try {
            // Replace with your API call to the backend , "/payment", requestBody
            const response = await API.post('paymentapi', '/payment/refund', {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    payment_intent_Id: paymentIntentId,
                    amount: refundAmountInCents || undefined // Send undefined if no amount is entered
                }
            });

            setIsLoading(false);
            console.log('refund response:', response);
            if (response.status === 'success') {
                setMessage(response.message);
                setShowSnackbar(true);
                setRefundRequested(true);
                // console.log('orderdate:', order.date, 'hrid:', order.hrid, 'meetHours:', order.meetHours);
                // update refund status to be true in session storage
                updateRefundStatusInSession(order.orderid);
                updateRefundStatus(order.date, order.hrid, order.meetHours);
                invalidateOrderCache('partMeetings');
                invalidateOrderCache('hostMeetings');
                
            } else {
                throw new Error(response.message);
            }
        } catch (err) {
            setError(err.message);
            setMessage('');
        }
    };


    async function updateRefundStatus(order_date, hrid, meet_Hours) {
        const response = await API.post('meetingsapi', '/meetings/update-after-refund', {
          headers: {
            'Content-Type': 'application/json'
          },
          body: {
             orderdate: order_date, 
             hrid: hrid,
             meetHours: meet_Hours,
            }
        });
      
        console.log('response,', response);
      }
      

    const handleAbort = (e) => {
        navigate('/orders');
        e.preventDefault();
    }

    return (
        <Paper>
            {isLoading ? (<Flex direction='row' justifyContent="center" ><CircularProgress /> </Flex>) : (
                <div>
            <AppointmentListDisplay order={order} />
            
            {refundRequested ? (
                <Flex direction="row" justifyContent="flex-end">
                <Button disabled variant="contained">Refund Successfully Requested</Button>
                </Flex>
            ) : (
                <Flex direction="row" justifyContent="flex-end">
                <Button onClick={handleAbort} variant="outlined">Abort Refund Request</Button>
                <Button onClick={handleRefund} variant="contained">Request Refund</Button>
                </Flex>
            )}
            </div>
            )}
            {showSnackbar && (
                <Alert severity="success" style={{ marginTop: 10 }}>{message} <br /> You will receive the refund ${refundAmountInDollars} approximately 5-10 business days later from your original payment method.</Alert>
            )}
            {error && (
                    <Alert severity="error" style={{ marginTop: 10 }}>{error}</Alert>
                )}
        </Paper>
    );
}
function updateRefundStatusInSession(orderId) {
    const refunds = JSON.parse(sessionStorage.getItem('refunds')) || {};
    refunds[orderId] = true;
    console.log('refunds:', refunds);
    sessionStorage.setItem('refunds', JSON.stringify(refunds));
  }

function getRefundStatus(orderId) {
    // Retrieve the refunds object from sessionStorage
    const refunds = JSON.parse(sessionStorage.getItem('refunds'));
    console.log('refunds:', refunds, 'orderId:', orderId);
    // Check if the specific orderId exists in the retrieved object
    if (refunds && refunds[orderId]) {
        return refunds[orderId];  // Return the refund status
    } else {
        return 'No refund status found';  // Return a default message or handle as needed
    }
}


export default CancelAppointmentPage;
