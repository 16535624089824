import React from 'react';
import { Grid, FormControlLabel, Checkbox, TextField, InputAdornment, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ServiceItemOtherArray = ({ serviceName, serviceState, setServiceState }) => {

    // const handleAddSession = () => {
    
    //     const newSession = {
    //         sessionId: generateId(), // Generate a unique ID for the session
    //         sessionData: new Map(), // Initialize session data as an empty map
    //     };
    //     setServiceState([...serviceState, newSession]);
    //   };
  // console.log('Other Sessions, ', serviceState);
  const handleAddSession = () => {
    const newSession = {
        sessionId: generateId(), // Generate a unique ID for the session
        appointmentLength: 0,
        charge: 0,
        numSess: 0,
        title: '',
        descp: '',
    };
    setServiceState([...serviceState, newSession]);
  };


  const handleServiceCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    setServiceState(prevState => {
      const updatedService = [...prevState]; // Make a copy of the array
      if (isChecked) {
        // Set initial values for service
        updatedService[index] = {
          appointmentLength: 0,
          charge: 0,
          numSess: 0,
          title: '',
          descp: '',
        };
      } else {
        // Remove service from the array if unchecked
        updatedService.splice(index, 1);
      }
      return updatedService;
    });
  };

  const getFieldDataType = (field) => {
    // Define the data type for each field
    const dataTypeMap = {
      appointmentLength: 'number',
      charge: 'number',
      numSess: 'number',
      title: 'string',
      descp: 'string',
      // Add more fields as needed
    };
    
    // Return the data type of the field
    return dataTypeMap[field]; // Default to 'string' if field type is not specified
  };

  const handleFieldChange = (index, field, value) => {
    // const fieldType = getFieldDataType(field);
    
    // let updatedValue;
    // if (fieldType === 'number') {
    //   updatedValue = parseFloat(value);
    // } else {
    //   updatedValue = value;
    // }
    const updatedValue = value;

    setServiceState(prevState => {
      const updatedService = [...prevState]; // Make a copy of the array
      updatedService[index][field] = updatedValue; // Update the specific field of the map at the given index
      console.log('updated serviceState,', updatedService);
      return updatedService;
    });
  };

  return (
    <Grid container item xs={12} md={12} lg={12} direction="row" justifyContent="flex-start" alignItems="flex-start" gap={1}>
      

      {serviceState.map((service, index) => (
        <React.Fragment key={index}>

            <Grid item xs={12} md={5} lg={5}>
                    <FormControlLabel
                    control={<Checkbox name={serviceName} defaultChecked onChange={(e) => handleServiceCheckboxChange(e, index)} />}
                    label={serviceName}
                    />
            </Grid>

          <Grid item xs={4} md={2} lg={2}>
            <TextField
              fullWidth
              size="small"
              label="Number of Sessions"
              type="number"
              value={service.numSess}
              onChange={(e) => {handleFieldChange(index, 'numSess', e.target.value); console.log('service', service)}}
            />
          </Grid>

          <Grid item xs={4} md={2} lg={2}>
            <TextField
              fullWidth
              label="Session length"
              size="small"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                min: 0
              }}
              value={service.appointmentLength}
              onChange={(e) => handleFieldChange(index, 'appointmentLength', e.target.value)}
            />
          </Grid>
          
          <Grid item xs={4} md={2} lg={2}>
            <TextField
              fullWidth
              size="small"
              label="Charge/session"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                min: 0
              }}
              type="number"
              value={service.charge}
              onChange={(e) => handleFieldChange(index, 'charge', e.target.value)}
            />
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <TextField
              fullWidth
              size="small"
              label="Title"
              value={service.title || ''}
              onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
            />
          </Grid>

          <Grid item xs={4} md={6} lg={6}>
            <TextField
              fullWidth
              size="small"
              label="Description"
              value={service.descp || ''}
              onChange={(e) => handleFieldChange(index, 'descp', e.target.value)}
            />
          </Grid>
        </React.Fragment>
      ))}

      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="outlined"
          onClick={handleAddSession}
          startIcon={<AddIcon />}
        >
          Add Session
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceItemOtherArray;

function generateId() {
    // Generate a unique identifier for each session
    return Math.random().toString(36).substring(2);
  }