import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Match from './routes/Match';
import Home from './routes/Home';
import Newsletter from './routes/Newsletter';
// import TodayJobs from './routes/TodayJobs';
// changes on 4/2
import AlluserLayout from './components/AlluserLayout';
import VeuserLayout from './components/VeuserLayout';
import AskHR from './routes/AskHR';
import Dashboard from './routes/Dashboard';
import MyApplications from './routes/MyApplications';
import PostJob from './routes/PostJob';
import Apply from './routes/Apply';
import AdminDash from './routes/AdminDash';
import Lobby from './routes/Lobby';
import CartDetailPage from './routes/CartDetailPage';
import CheckoutPage from './routes/CheckoutPage';
import AppointmentConfirmationPage from './routes/AppointmentConfirmationPage';
import UpcomingAppointmentsPage from './routes/UpcomingAppointmentPage';
import RescheduleAppointmentPage from './routes/RescheduleAppointmentPage';
import CancelAppointmentPage from './routes/CancelAppointmentPage';
import Status from './routes/Status';
import CareerConcierge from './routes/CareerConcierge';
import Feedback from './routes/Feedback';
import Terms from './routes/Terms';
import AboutUs from './routes/AboutUs';
import OrdersPage from './routes/OrdersPage';

function App() {
    return (
       
        <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<AlluserLayout />}>
                <Route index
                    element={<Home />}
                />
                {/* <Route path="/newsletter"
                       element={<Newsletter />}
                /> */}
                <Route path="status"
                       element={<Status />}
                />
                <Route path="terms"
                       element={<Terms />}
                />
                <Route path="aboutus"
                       element={<AboutUs />}
                />
            </Route>

            {/* define lobby outside of both AlluserLayout and VeuserLayout*/}
            <Route path="lobby"
                       element={<Lobby />}
            />

            <Route path="/" element={<VeuserLayout />}>
                <Route path="home"
                       element={<Dashboard />}
                />
                {/* <Route path="applications"
                       element={<MyApplications />}
                />  */}
                <Route path="cart"
                       element={<CartDetailPage/>}
                />
                <Route path="checkout"
                       element={<CheckoutPage/>}
                />
                <Route path="match"
                       element={<Match />}
                />
                {/* <Route path="todayjobs"
                       element={<TodayJobs />}
                /> */}
                <Route path="askhr"
                       element={<AskHR />}
                />
                {/* <Route path="postjob"
                       element={<PostJob />}
                /> */}
                <Route path="apply"
                       element={<Apply />}
                />

                <Route path="admindash"
                       element={<AdminDash />}
                />
                <Route path="feedback"
                       element={<Feedback />}
                />

                <Route path="careerconcierge"
                       element={<CareerConcierge />}
                />
                <Route path="orders">
                     <Route index element={<OrdersPage />} />
                     <Route path=":appointmentId/reschedule" element={<RescheduleAppointmentPage />} />
                     <Route path=":appointmentId/cancel" element={<CancelAppointmentPage />} />
                </Route>
                           
                

                <Route path="appointments">
                     <Route index element={<UpcomingAppointmentsPage />} />
                     {/* <Route path="dashboard" element={<AppointmentsDashboardPage />} /> */}
                     <Route path="confirmation" element={<AppointmentConfirmationPage />} />
                     {/* <Route path=":appointmentId/reschedule" element={<RescheduleAppointmentPage />} />
                     <Route path=":appointmentId/cancel" element={<CancelAppointmentPage />} /> */}
                </Route>
            </Route>

            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/todayjobs" element={<TodayJobs />} /> */}
            {/* <Route path="/newsletter" element={<Newsletter />} /> */}
        </Routes>
        

    );
}

export default App;