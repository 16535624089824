import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

function AppointmentListDisplay({ order }) {

  const normalizedOrder = Array.isArray(order) ? order : [order];
  return (
    <List>
      {normalizedOrder.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={item.serv}
              secondary={
                <>
                  {(item.onlydate) && <Typography component="span" variant="body2" color="text.primary">
                    Order date: {item.onlydate}, Order ID: {item.orderid} <br />
                  </Typography>}
                  <Typography component="span" variant="body2" color="text.primary">
                    Provider: {item.hrName}
                  </Typography>
                  <br />
                  {item.meetHours.map((time, timeIndex) => (
                    <Box key={timeIndex} component="span">
                      {item.meetTimes[timeIndex]} ({item.stuTZ}) <br />
                    </Box>
                  ))}
                  <Typography component="span" variant="body2" color="text.primary">
                  {'Sessions: ' + item.numSession + ' | Cost per Session: $' + item.chargePS}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>
                    Service Total: ${item.total}
                  </Typography>
                </>
              }
            />
            {/* Uncomment below if you want to add actions like buttons to each list item */}
            {/* <ListItemSecondaryAction>
                <Button>Change</Button>
            </ListItemSecondaryAction> */}
          </ListItem>
          {index < normalizedOrder.length - 1 && <Divider variant="inset" component="li" />}
        </React.Fragment>
      ))}
    </List>
  );
}

export default AppointmentListDisplay;
