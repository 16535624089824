/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "allusersapi",
            "endpoint": "https://ijybx310m9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "availapi",
            "endpoint": "https://ny1cw70lkb.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "feedbackApi",
            "endpoint": "https://pi80qh3z9h.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "hrapi",
            "endpoint": "https://daium7n4k3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "matchedjobsapi",
            "endpoint": "https://xhc7hxwux8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "meetingsapi",
            "endpoint": "https://zranl9znp3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "orderapi",
            "endpoint": "https://tfr8kiet54.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "paymentapi",
            "endpoint": "https://z0lxpn30h4.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "registerForEarlyAccessApi",
            "endpoint": "https://0xrnapwce0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "reserveapi",
            "endpoint": "https://4bvm9pwytc.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "resevalapi",
            "endpoint": "https://8d5ddg2s9b.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "resprocessapi",
            "endpoint": "https://k0swwlj4jg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "resumeapi",
            "endpoint": "https://xxr4043b12.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "resumeparseAPI",
            "endpoint": "https://7ut35ntzu1.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "todaysjobapi",
            "endpoint": "https://07fibcc1ae.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "updateCartapi",
            "endpoint": "https://kfzm7473lf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:2db72d9b-f7db-4990-9ea6-7a7345dceb79",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6BXAbs8iB",
    "aws_user_pools_web_client_id": "3fo57bl0tlt157b0esan7j3h92",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "allusersdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "availdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "cartdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "resumemeta-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "feedbackdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "hrlistdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "matchedjobsdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "meetingsdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "orderdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "parseresdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "registerForEarlyAccessdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "reservedb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "resevalresult-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "resparsetable-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "resprocesstable-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "successfulTransactionsdb-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "todaysjobdb-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "mywebapp938f0b080d9546be95eba34c62fc421e115935-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
