import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import SearchBarPH from '../assets/searchbarplaceholder.png';
import { styled } from '@mui/system';
const AboutUs = () => {
    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '50%',
        maxHeight: '100%',
      });
    return (
        <div className="about-us-container">
            <Paper elevation={1} style={{padding: '2rem'}} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    
                <Typography variant='h4' style={{ textDecoration: "underline", textDecorationColor: "green", textDecorationThickness: "thick" }}> About Us </Typography>
                </div>
            
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm = {8} md={6} lg={4}  >
                   
            <p>Welcome to Idyllic AI! We are a team of dedicated professionals committed to offering comprehensive career support to undergraduate and graduate students, as well as postdoctoral scholars.</p>
            <strong>Our mission</strong>
            <p>Our mission is to make the transition from academia to industry smooth and easy. We understand that all students aspire to an idyllic life, supported by a career that is both rewarding and enjoyable. We're committed to helping you achieve just that!</p>
            <strong>Why I started Idyllic AI:</strong>
            <p>As a former graduate student, I understand the challenges of navigating the job market. When I was job hunting, I struggled with which keywords to use in the search bar. </p>
            <Img alt="complex" src={SearchBarPH} />
            <p>"Job title, keywords, or company" — that's what the placeholder says. Yet, I was unsure what to input. Despite my broad skill set, submitting all my qualifications yielded few matches. On the other hand, entering just a few often led to irrelevant suggestions.

I wondered, could I simply input my entire resume into the search box and instantly discover suitable jobs that match my skills and passions? So I founded Idyllic AI to accomplish exactly this.
We search a database of 800,000 real job postings from various industries to find jobs that align with your entire skill set and educational background. Utilizing ChatGPT-4, we provide tailored recommendations based on a meticulously crafted prompt, all personalized for you.
With Idyllic AI, you gain in seconds the knowledge that took me weeks to acquire through traditional search methods.</p>

<p>If you're still shaping your career, you can explore real job postings that match <i>your</i> background and degrees. This will help you understand the required skills and identify areas for development.
           </p> 
           <strong>Coming Soon</strong>

<p>We are actively seeking recruiters and industry professionals, who make hiring decisions daily, to join us as career coaches. 
    They will provide tailored advice and conduct mock interviews for students within their specific industries. 
    This ensures you receive genuine insights into the preferences and norms of your field. Additionally, these experts will offer webinars, some at minimal cost and others for free. We'll be launching this service in the coming weeks—sign up to stay updated!</p>
           <p>Feel free to explore our website and learn more about what we do.</p>
           
           </Grid>
           </Grid>
           
            </Paper>
            
        </div>
    );
};

export default AboutUs;