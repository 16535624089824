import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Typography, Divider, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FeedbackIcon from '@mui/icons-material/Feedback';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2), // adds padding around the title
    paddingTop: theme.spacing(4), // additional top padding for the title
    paddingBottom: theme.spacing(1) // less padding at the bottom
  },
  listItem: {
    padding: theme.spacing(2) // ensures padding is consistent with the title
  }
}));



const CustomLeftDrawer = ({ items }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const classes = useStyles();
  
    const handleDrawerToggle = () => {
      setOpen(!open);
    };
  
    const handleItemClick = (href) => {
      if (href) {
        navigate(href);
      }
      setOpen(false);
    };

    const iconMap = {
      jobMatching: <WorkOutlineIcon />,
      networking: <PeopleOutlineIcon />,
      newFeature: <NewReleasesIcon />,
      feedback: <FeedbackIcon />
  };
  
    return (
      <React.Fragment>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{ marginRight: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}
        >
          <Typography variant="h4" gutterBottom className={classes.title} >
            
            <span style={{
              backgroundColor: '#e8f5e9', // A light green color from the Material UI green palette
              padding: '0.2rem 0.5rem',
              borderRadius: '4px',
              color: 'black' // Or another color that matches your design
              }}>
                  idyllic AI 
              </span>
          </Typography>



          <List>
            {items.map((item, index) => (
              
              <div key={index}>
                
              {item.type === 'link' && 
              <ListItem button onClick={() => handleItemClick(item.href)} className={classes.listItem} disablePadding>
                <ListItemIcon sx={{ marginRight: '-24px' }}>
              {iconMap[item.icontype]}
              </ListItemIcon>
                <ListItemText primary={<Typography variant="h6">{item.text}</Typography>} />
              </ListItem>
              }
              {item.type === 'divider' && <Divider />}
              </div>
            ))}
          </List>
        </Drawer>
      </React.Fragment>
    );
  };

export default CustomLeftDrawer;