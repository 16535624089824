import React, {useState, useEffect, useRef} from 'react';
import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import FormField from "@awsui/components-react/form-field";
// import DateInput from '@awsui/components-react/date-input';
import Alert from "@awsui/components-react/alert";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Button from "@awsui/components-react/button";
import TokenGroup from "@awsui/components-react/token-group";
import SpaceBetween from "@awsui/components-react/space-between";
import ProgressBar from "@awsui/components-react/progress-bar";
import Amplify, {Auth, Storage, API} from 'aws-amplify';
import Utf8ArrayToStr from "../libs/utf.js";
// import Select from '@mui/material/Select';
import UploadIcon from '@mui/icons-material/Upload';

import { Button as Button1, LinearProgress, Typography } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {
    View,
    Flex,
    Text,
    Expander,
    ExpanderItem,
    Card,
    Grid,
    Badge,
    TextField,
    SelectField,
    Placeholder,
    ButtonGroup,
    TextAreaField,
    Heading,
    useTheme,
    Loader
} from '@aws-amplify/ui-react';

import awsmobile from '../aws-exports';
import { ConstructionOutlined } from '@mui/icons-material';
import { CleanTextDisplay, parseMarkdown, parseMarkdownWithHeaders, parseMarkdownh3 } from '../utils/stylingUtils.js';


Amplify.configure(awsmobile);



function formatBytes(a, b = 2, k = 1024) {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
function intersection(a, b) {
return a.filter((value) => b.indexOf(value) !== -1);
}

const Content = () => {
    const hiddenFileInput = useRef(null);
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    // const [visibleHistAlert, setVisibleHistAlert] = useState(false);
    const [uploadList, setUploadList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [historyCount, setHistoryCount] = useState(0);
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    // const [uploadName, setUploadName] = useState('');
    // Parsing resume
    const [parsedSkills, setParsedSkills] = useState('');
    const [resParsingStatus, setResParsingStatus] = useState(false);
    const [parsedSkillsLoading, setParsedSkillsLoading] = useState(false);
    const [showJobRec, setShowJobRec] = useState('false');
    const [uid, setUid] = useState('');
    const [thisResName, setThisResName] = useState('');
    const [thisResUploadTime, setThisResUploadTime] = useState('');
    const [resPercent, setResPercent] = useState(0);
    const [allUpload, setAllUpload] = useState([]);

    // Degree level, expected graduation, name, related degrees, proskills, fam skills, what you want
    const [degLevel, setDegLevel] = useState('');
    const [graduateDate, setGraduateDate] = useState('');
    const [degName, setDegName] = useState('');
    const [rtDeg, setRTDeg] = useState('');
    const [proSkills, setProSkills] = useState('');
    const [famSkills, setFamSkills] = useState('');
    const [userNeed, setUserNeed] = useState('');
    const [userComm, setUserComm] = useState('');

    // Matched jobs
    const [titlearr, setTitleArr] = useState([]);
    const [companyarr, setCompanyArr] = useState([]);
    const [desparr, setDespArr] = useState([]);
    const [mskillsarr, setMSkillsArr] = useState([]);
    const [mjtime, setMJTime] = useState('');
    const [isLoaderDtm, setIsLoaderDtm] = useState(false); // changes 3/26/23 useState(false), false is a boolean, not a string, X'false'X
    // const matchSession = useRef(null);
    // chatGPT response
    const [gptres, setGptRes] = useState('');

    //changes made today: 3/26/23
    const [percent, setPercent] = useState(0);
    // here

    // const [isCheckedList, setIsCheckedList] = useState([])
    // const [userChoiceList, setUserChoiceList] = useState([])
    
    // const [indarr, setIndArr] = useState([])
    // const [jobfuncarr, setJobfuncArr] = useState([])
    
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [textfieldLocked, setTextfieldLocked] = useState(true);
    const [loading, setLoading] = useState(false);

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]); 
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        
        API.put('matchedjobsapi', '/matjobs', {
            body: {
                userid: uid,
                time: mjtime,
                lecheck: not(left, leftChecked), //not(left, leftChecked)
                richeck: right.concat(leftChecked), //right.concat(leftChecked)
            },
        })

        };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));

        API.put('matchedjobsapi', '/matjobs', {
            body: {
                userid: uid,
                time: mjtime,
                lecheck: left.concat(rightChecked), //not(left, leftChecked)
                richeck: not(right, rightChecked) //right.concat(leftChecked)
            },
        })

        };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        };


    const customList = (descriptions, companies, mskills, titles, items) => (

            <Expander type="multiple">
                {items.map((index) => 
                <Card key={`${companies[index]}-${index} -${titles[index]}`}> 


                    <Flex padding="0.5rem" onClick={handleToggle(index)}>
                        <Checkbox
                            checked={checked.indexOf(index) !== -1}
                            tabIndex={index}
                            disableRipple
                            key={index}
                            inputProps={{
                            'aria-labelledby': `transfer-list-item-${index}-label`,
                            }}
                        />

                        <Badge size="large" variation="warning" fontSize="12px">
                            {companies[index]}
                        </Badge>
                        <Badge size="large" variation="success" fontSize="12px">
                            {mskills[index]}
                        </Badge>

                    </Flex>

                    <ExpanderItem title= {
                        <CustomTitle 
                            jobtitle = {titles[index]} 
                        /> 
                        } 
                        value={index+1} 
                        id="resJobs - ${index}"
                        key={`expanderitem - ${companies[index]}-${index} -${titles[index]}`}
                        data-index={index}
                        >
                        <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap', fontSize: '12px'}} component={'span'} aria-label="chatgpt-response-pre">
                            {descriptions[index]}
                        </pre>
                       
                            
                        
                    </ExpanderItem>
                </Card> )}
            
            </Expander>
        );

    // const uploadTime = currDate + ' ' + currTime;

    // useEffect(() => {
    //     setUserNeed('0'); // Set the default value when the component mounts
    //     setDegLevel('0');
    //     setGraduateDate('0');
    //   }, []);
    

    useEffect(() => {
        let isMounted = true; // Flag to track mounting status
        API.get("resprocessapi", "/parsedres/:userid").then((metaRes) => {
            // console.log('metares in resprocessapi', metaRes);
            if (isMounted && metaRes && metaRes.length > 0) {
            var latestUploadTime = ''
            var i = 0
            // create an array to store all item.filename across all items in metaRes
            var allResUpload = [];
        
            for (const item of metaRes) {
                setUid(item.userid);
                if (i==0) {
                    latestUploadTime = item.uploadtime;
                    // setParsedSkills(item.parsedskills);
                    // setThisResName(item.filename);

                    // if (item.filename && item.filename !== '') {
                    //     allResUpload.push(item.filename);
                    // }
                    // check if item.filename exists and if it's not an empty array
                    if (item.filenamearr && Array.isArray(item.filenamearr) && item.filenamearr.length > 0) {
                        // Flattens item.filenamearr and pushes each element as a separate entry into allupload
                        allResUpload.push(...item.filenamearr);
                    }

                    setThisResUploadTime(latestUploadTime);
                    item.maxdeglevel && setDegLevel(item.maxdeglevel);
                    setDegName(item.maxdegname);
                    item.maxgradtime && setGraduateDate(item.maxgradtime);
                    setProSkills(item.proskills);
                    setFamSkills(item.famskills);
                    item.userneed && setUserNeed(item.userneed);
                    item.usercomm && setUserComm(item.usercomm);
                    setRTDeg(item.rtdeg);
                    
                    
                    
                } else {
                    
                    if (item.uploadtime > latestUploadTime) {
                        latestUploadTime = item.uploadtime;
                        // setParsedSkills(item.parsedskills);
                        // setThisResName(item.filename);
                        // if (item.filename && item.filename !== '') {
                        //     allResUpload.push(item.filename);
                        // }
                        if (item.filenamearr && Array.isArray(item.filenamearr) && item.filenamearr.length > 0) {
                            // Flattens item.fileNameArr and pushes each element as a separate entry into allupload
                            allResUpload.push(...item.filenamearr);
                        }
                        setThisResUploadTime(latestUploadTime);
                        setDegLevel(item.maxdeglevel);
                        setDegName(item.maxdegname);
                        setGraduateDate(item.maxgradtime);
                        setProSkills(item.proskills);
                        setFamSkills(item.famskills);
                        setUserNeed(item.userneed);
                        setUserComm(item.usercomm);
                        setRTDeg(item.rtdeg);
                        
                    }
                }
                i=i+1;
                console.log('latestuploadtime', latestUploadTime);
            };
            // console.log('allresupload', [...allResUpload, thisResName]);
            // add thisResName to allResUpload if thisResName is not empty
            // if (thisResName !== '') {
            //     allResUpload.push(thisResName);
            // }

            setAllUpload(allResUpload);
            // setParsedSkillsLoading(false);
            // setAllUpload([...allUpload, ...metaRes])
        }
        }).catch(error => {
            // Handle any errors here
            console.error(error);
          });

        
        setResParsingStatus(true);
          return () => {
            isMounted = false; // Set flag to false when component unmounts
          };
        
    }, [])
    
    

    useEffect(() =>{
        let isMounted = true;
        setLoading(true);
        API.get('matchedjobsapi', '/matjobs/:userid')
        .then(mjobsRes => {
            // NOTE: mjobsRes is list of table items that belong to this user
            if (isMounted ) {
            const isTableEmpty = mjobsRes.length === 0;

            // Update showDiv based on the table content
            setShowJobRec(!isTableEmpty);
            if (mjobsRes.length != 0) {
                var desp_string_arr = [];
                // var latestBatchJobs = mjobsRes[mjobsRes.length - 1];
                var latestBatchJobs = mjobsRes[0];
                // console.log('JOBS loaded', mjobsRes);
                // console.log('mjobsRes length:', mjobsRes.length, 'Time for Matched Jobs', latestBatchJobs.time);
                
                setTitleArr(latestBatchJobs.title_arr);
                setCompanyArr(latestBatchJobs.company_arr);
                for (const descrp of latestBatchJobs.desp_arr){
                    // console.log('descrp', Utf8ArrayToStr(descrp.data));
                    desp_string_arr.push(CleanTextDisplay(Utf8ArrayToStr(descrp.data)));
                }
                setDespArr(desp_string_arr);
                setMJTime(latestBatchJobs.time);
                setMSkillsArr(latestBatchJobs.mskills_arr);
                setGptRes(Utf8ArrayToStr(latestBatchJobs.chatgpt_res.data)); // binary data decoding needs to access the "data", so Utf8ArrayToStr(binary_variable.data)
                
                if (latestBatchJobs.hasOwnProperty('lecheck')) {
                    setLeft(latestBatchJobs.lecheck)
                } else {
                    setLeft([...Array(desp_string_arr.length).keys()])
                }

                if (latestBatchJobs.hasOwnProperty('richeck')) {
                    setRight(latestBatchJobs.richeck)
                }
                
            }
            
            setLoading(false);

            }
           

        });

        return () => {
                        isMounted = false; // Set flag to false when component unmounts
                    };
        
    }, []); //showJobRec

    //changes made today 3/23/2023
    // useEffect(() => {
    //     const clearID = setInterval(() => {
    //       setPercent((percent) => {
    //         if (percent < 100) {
    //           return percent + 1;
    //         }
    //         return 0;
    //       });
    //     }, 600);
    //     return () => clearInterval(clearID);
    //   }, []);

    function configTimerForResParsedSkillsLoader() {



        const clearID = setInterval(() => {
            setResPercent((resPercent) => {
              if (resPercent < 95) {
                return resPercent + 5;
              }
              return 0;
            });
          }, 1500);
          return () => clearInterval(clearID);
    }


    // function configTimerForLoader() {
    //     const clearID = setInterval(() => {
    //         setPercent((percent) => {
    //           if (percent < 100) {
    //             return percent + 1;
    //           }
    //           return 0;
    //         });
    //       }, 700);
    //       return () => clearInterval(clearID);
    // }
    function configTimerForLoader() {
        // Calculate the interval for each 1.43% increment so that it completes 100% in 70 seconds
        const intervalDuration = 1000; // 1000 milliseconds = 1 second
    
        // Set up the interval
        const clearID = setInterval(() => {
            setPercent((percent) => {
                // Calculate the new percentage
                const newPercent = percent + (100 / 70);
                // Check if the percentage reaches or exceeds 100
                if (newPercent >= 100) {
                    clearInterval(clearID); // Clear the interval once 100% is reached
                    return 100; // Optionally set it exactly to 100 to ensure it doesn't go over
                }
                return newPercent; // Update the percent state
            });
            // console.log('percent', percent);
        }, intervalDuration);
        
        // Return a cleanup function to clear the interval when no longer needed
        return () => clearInterval(clearID);
    }
    

    // here

    // function convertDateToUTC(date) { 
    //     return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    // }
    


    function getData(e) {
        e.preventDefault();
        setTextfieldLocked(false);
    }

    function cancelEdit(e) {
        e.preventDefault();
        setTextfieldLocked(true);
    }

    function showUserInputField(e) {
        e.preventDefault();
        var T = document.getElementById("TestsDiv");
        if (e.target.value == "5") {
            T.style.display='block'; // <-- Set it to block
        } else {
            T.style.display='none';
        }
    }

    const CustomTitle = ({ jobtitle}) => {
        const { tokens } = useTheme();
        return (
          <Flex gap={tokens.space.small}>
            <View>
                <Heading level={4}>
                    {jobtitle}
                </Heading>
            </View>
          </Flex>
        );
    };


    function validateForm(e) {
        e.preventDefault();
        var a = document.getElementById("req_1").value;
        var b = document.getElementById("req_2").value;
        var c = document.getElementById("req_3").value;
        var d = document.getElementById("req_4").value;
        var ee = document.getElementById("req_5").value;
        var f = document.getElementById("req_6").value;
        var g = document.getElementById("req_7").value;
        if (a == "" || b == "" || c == "" || d == "" || ee =="" || f == "" || g == "") {
            alert("please fill out all required fields")
            // console.log('VALIDATE FORM', c)
            return false;
        }
        handleSubmit(); // Call handleSubmit if validation passes
        return true;
    }

    // const getParsedSkills = () => {
    //     console.log('getParsedSkills: getting parsed skills');
        
    //     API.get("resprocessapi", "/parsedres/:userid").then((metaRes) => {
    //         console.log('metares in resprocessapi', metaRes);
    //         var latestUploadTime = ''
    //         var i = 0
    //         console.log('metaRes', metaRes);
    //         for (const item of metaRes) {
    //             setUid(item.userid);
    //             if (i==0) {
    //                 latestUploadTime = item.uploadtime;
    //                 setParsedSkills(item.parsedskills);
    //                 item.filename && setThisResName(item.filename);
    //                 setThisResUploadTime(latestUploadTime);
    //                 item.maxdeglevel && setDegLevel(item.maxdeglevel);
    //                 setDegName(item.maxdegname);
    //                 item.maxgradtime && setGraduateDate(item.maxgradtime);
    //                 setProSkills(item.proskills);
    //                 setFamSkills(item.famskills);
    //                 item.userneed && setUserNeed(item.userneed);
    //                 item.usercomm && setUserComm(item.usercomm);
    //                 setRTDeg(item.rtdeg);
                    
    //             } else {
    //                 if (item.uploadtime > latestUploadTime) {
    //                     latestUploadTime = item.uploadtime;
    //                     setParsedSkills(item.parsedskills);
    //                     item.filename && setThisResName(item.filename);
    //                     setThisResUploadTime(latestUploadTime);
    //                     setDegLevel(item.maxdeglevel);
    //                     setDegName(item.maxdegname);
    //                     setGraduateDate(item.maxgradtime);
    //                     setProSkills(item.proskills);
    //                     setFamSkills(item.famskills);
    //                     item.userneed && setUserNeed(item.userneed);
    //                     item.usercomm && setUserComm(item.usercomm);
    //                     setRTDeg(item.rtdeg);
                        
    //                 }
    //             }
    //             i=i+1;
                
    //         };
            
    //         setResParsingStatus(true);
    //         setParsedSkillsLoading(false);
            
    //         // setAllUpload([...allUpload, ...metaRes])
            
    //     });
    // }



    const handleSubmit = () => {
        
        // console.log('uid', uid, 'thisResUploadTime', thisResUploadTime, 'filename', thisResName, 'parsedSkills', parsedSkills, 'maxdeglevel', degLevel, 'maxdegname', degName, 'maxgradtime', graduateDate,
        // 'rtdeg', rtDeg, 'proskills', proSkills, 'famskills', famSkills, 'userneed', userNeed, 'usercomm', userComm);
        // if thisResName is empty, then don't include filename in requestBody
        let requestBody = {};

        requestBody = {
            maxdeglevel: degLevel,
            maxdegname: degName,
            maxgradtime: graduateDate,
            rtdeg: rtDeg,
            proskills: proSkills,
            famskills: famSkills,
            userneed: userNeed,
          };
        // if uploadtime is not empty, then include it in the requestBody
        if (thisResUploadTime !== '') {
            requestBody.uploadtime = thisResUploadTime;
        }
        // if (thisResName !== '') {
        //     requestBody.filename = thisResName;
        // } 

        if (allUpload.length > 0) {
            requestBody.filenamearr = allUpload;
        }
        
        // const requestBody = {
        //     // userid: uid,
        //     // uploadtime: thisResUploadTime,
        //     filename: thisResName,
        //     // parsedskills: parsedSkills,
        //     maxdeglevel: degLevel,
        //     maxdegname: degName,
        //     maxgradtime: graduateDate,
        //     rtdeg: rtDeg,
        //     proskills: proSkills,
        //     famskills: famSkills,
        //     userneed: userNeed,
        //   };
          
          // Check if userComm is defined before adding it to the request body
        // if userComm is not empty, then include it in the requestBody
        if (userComm !== '') {
            requestBody.usercomm = userComm;
        }
        console.log('requestBody', requestBody);

        API.put("resprocessapi", "/parsedres", {
             body: requestBody
            // body: {
            //     userid: uid,
            //     uploadtime: thisResUploadTime,
            //     filename: thisResName,
            //     parsedskills: parsedSkills,
            //     maxdeglevel: degLevel,
            //     maxdegname: degName,
            //     maxgradtime: graduateDate,
            //     rtdeg: rtDeg,
            //     proskills: proSkills,
            //     famskills: famSkills,
            //     userneed: userNeed,
            //     usercomm: userComm,
            // },
        });

        alert('Submission successful! Thank you. The matching jobs will appear within about 60s.')
        setShowJobRec(true);
        setTextfieldLocked(true);
        setSubmitButtonDisabled(true);

        // setLoading(true); // already false before user can fill up the form and click submit
        setIsLoaderDtm(true); // user determinate loader
        setTitleArr([]);
        setCompanyArr([]);
        setDespArr([]);
        setMJTime('');
        setMSkillsArr([]);
        
        setPercent(0); 
        configTimerForLoader(); 
        setTimeout(updateMJobs, 50000);


        setTimeout(() => {
            window.scrollBy(0, 300);
        }, 100); // Delay scrolling by 100 milliseconds

        
    };

    const updateMJobs = () => {
        // Start API.get the matchedjobsdb to try to load the will-soon-be-written matched jobs

        API.get('matchedjobsapi', '/matjobs/:userid').then( newMJRes => {
            // console.log('newMJRes', newMJRes);
            // var latestBatchJobs = newMJRes[newMJRes.length - 1];
            var latestBatchJobs = newMJRes[0];
            var desp_string_arr = [];
            setTitleArr(latestBatchJobs.title_arr);
            setThisResUploadTime(latestBatchJobs.time);
            setCompanyArr(latestBatchJobs.company_arr);
            for (const descrp of latestBatchJobs.desp_arr){
                desp_string_arr.push(CleanTextDisplay(Utf8ArrayToStr(descrp.data)));
                // console.log('descrp', Utf8ArrayToStr(descrp.data));
            }
            setDespArr(desp_string_arr);
            setMJTime(latestBatchJobs.time);
            setMSkillsArr(latestBatchJobs.mskills_arr);

            setLeft([...Array(desp_string_arr.length).keys()]);
            setRight([]);
            setGptRes(Utf8ArrayToStr(latestBatchJobs.chatgpt_res.data));
            
            setIsLoaderDtm(false);
        });

        
    }
    
    const handleClick = () => {
        hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
        hiddenFileInput.current.click();
    };
    const handleChange = e => {
        e.preventDefault();
        let i, tempUploadList = [];
        for (i = 0; i < e.target.files.length; i++) {
            tempUploadList.push({
                label: e.target.files[i].name,
                labelTag: formatBytes(e.target.files[i].size),
                description: 'File type: ' + e.target.files[i].type,
                icon: 'file',
                id: i
            })
        }
        setUploadList(tempUploadList);
        setFileList(e.target.files);
    };


    function progressBarFactory(fileObject) {
        let localHistory = historyList;
        const id = localHistory.length;
        localHistory.push({
            id: id,
            percentage: 0,
            filename: fileObject.name,
            filetype: fileObject.type,
            filesize: formatBytes(fileObject.size),
            status: 'in-progress'
        });
        setHistoryList(localHistory);
        return (progress) => {
            let tempHistory = historyList.slice();
            const percentage = Math.round((progress.loaded / progress.total) * 100);
            tempHistory[id].percentage = percentage;
            if (percentage === 100) {
                tempHistory[id]['status'] = 'success';
            }
            setHistoryList(tempHistory);
        };
    }

    const handleUpload = () => {
        if (uploadList.length === 0) {
            setVisibleAlert(true);
        } else {
            console.log('Uploading files to S3');
            // setParsedSkillsLoading(true); 
            let i, progressBar = [], uploadCompleted = [];
            for (i = 0; i < uploadList.length; i++) {
                // If the user has removed some items from the Upload list, we need to correctly reference the file
                const id = uploadList[i].id;
                progressBar.push(progressBarFactory(fileList[id]));
                setHistoryCount(historyCount + 1);
                uploadCompleted.push(Storage.put(fileList[id].name, fileList[id], {
                        progressCallback: progressBar[i],
                        level: "protected"
                    }).then(result => {
                        // Trying to remove items from the upload list as they complete. Maybe not work correctly
                        // setUploadList(uploadList.filter(item => item.label !== result.key));
                        console.log(`Completed the upload of ${result.key} at ${currDate + currTime}`);
                        console.log(fileList);
                        console.log("filename from filelist " + fileList[0].name);
                        // setUploadName(fileList[0].name)
                        setThisResName(fileList[0].name);
                        // push fileList[0].name to allUpload
                        setAllUpload([...allUpload, fileList[0].name]);
                        
                    })
                );
            }
            // When you finish the loop, all items should be removed from the upload list
            Promise.all(uploadCompleted)
                .then(() => { 
                    setUploadList([]);
                    setUploadSuccess(true);
                    console.log("the uploadsuccess is " + uploadSuccess)
                    console.log('file name 2 is ' + fileList[0].name);
                    // setAllUpload([...allUpload, {filename: fileList[0].name, uploadtime: uploadTime}]) // because before refreshing, react won't read from resumemetadata-dev anymore, attach to the history uploads any files you just uploaded.
                    
                });
        }
   
    }

    // console.log('allUpload ', allUpload);

    const handleDismiss = (itemIndex) => {
        setUploadList([
            ...uploadList.slice(0, itemIndex),
            ...uploadList.slice(itemIndex + 1)
        ]);
    };

    const List = ({list}) => (
        <>
        {  list.length === 0 ? (
            <p> </p>
            ) : 
            list.map((item) => (
                <ProgressBar
                    key={`${item.filename}-${item.id}`}
                    data-index={`${item.filename}-${item.id}`}
                    status={item.status}
                    value={item.percentage}
                    variant="standalone"
                    additionalInfo={item.filesize}
                    description={item.filetype}
                    label={item.filename}
                />
                
            ))
           
        }
        </>
    );


    return (
        // <SpaceBetween size="l">
        <Flex direction="column" gap="1rem">
            {/* <Container
                id="s3-upload-multiple-objects"
                header={
                    <Header variant="h2">
                        Upload Resume
                    </Header>
                }
            >
                {
                    <div>
                        <Alert
                            onDismiss={() => setVisibleAlert(false)}
                            visible={visibleAlert}
                            dismissAriaLabel="Close alert"
                            dismissible
                            type="error"
                            header="No files selected"
                        >
                            You must select the files that you want to upload.
                        </Alert>
                        
                        <Alert
                            onDismiss={() => setUploadSuccess(false)}
                            visible={uploadSuccess}
                            dismissAriaLabel="Close alert"
                            dismissible
                            type="success"
                            header="Upload successful"
                        >
                            <List list={historyList}/>
                        </Alert>

                        <FormField
                            label='Resume in PDF/DOCX format'
                            description='Click on the Choose File button and select the file that you want to upload, then click Upload button'
                        />

                        
                        <Flex direcc="row" gap="1rem">
                            <Button onClick={handleClick}
                                    iconAlign="left"
                                    iconName="upload"
                            >
                                Choose file
                            </Button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{display: 'none'}}
                            />
                            <Button variant="primary" onClick={() => {handleUpload(); }}>Upload</Button>{' '}
                            
                        </Flex>

                        <TokenGroup
                            onDismiss={({detail: {itemIndex}}) => {
                                handleDismiss(itemIndex)
                            }}
                            items={uploadList}
                            alignment="vertical"
                            limit={10}
                        />
                    </div>
                }


            {
            allUpload.length > 0 ? 
                <Typography variant="p">You have uploaded {allUpload.join(', ')}</Typography> 
                : 
                null
            }

            </Container> */}

            <Container
                aira-label="res-parsing-results"
                header={
                    <Header variant="h2">
                        Your Skills
                    </Header>
                }
            >




                    <Card variation="elevated" marginBottom="5rem">

                        {/* User input form */}

                        <Flex as="form" id="userInputForm" onSubmit={(e) => validateForm(e)} direction="column" width="100%">

                            <Grid
                            columnGap="0.5rem"
                            rowGap="0.5rem"
                            templateColumns={{base:"1fr", small:"1fr 1fr" ,large: "1fr 1fr 1fr 1fr"}}
                            templateRows={{base:"1fr 1fr 1fr 1fr", small: "1fr 1fr", large: "1fr"}}
                            >
                                <Card columnStart="1" columnEnd="-1">
                                    <div>
                                        <Alert
                                            onDismiss={() => setVisibleAlert(false)}
                                            visible={visibleAlert}
                                            dismissAriaLabel="Close alert"
                                            dismissible
                                            type="error"
                                            header="No files selected"
                                        >
                                            You must select the files that you want to upload.
                                        </Alert>
                                        {/* newly added alert for successful upload */}
                                        <Alert
                                            onDismiss={() => setUploadSuccess(false)}
                                            visible={uploadSuccess}
                                            dismissAriaLabel="Close alert"
                                            dismissible
                                            type="success"
                                            header="Upload successful"
                                        >
                                            <List list={historyList}/>
                                        </Alert>

                                        <FormField
                                            label='Resume in PDF/DOCX format (optional)'
                                            description='Click on the Choose File button and select the file that you want to upload, then click Upload button. Remember to click the Submit button after uploading to finalize your edits.'
                                        />

                                        {/* <SpaceBetween direction="horizontal" size="xs"> */}
                                        <Flex direcc="row" gap="1rem">
                                            {/* <Button1 onClick={handleClick}
                                                    type="button"
                                                    iconAlign="left"
                                                    iconName="upload"
                                                    disabled={textfieldLocked}
                                            > */}
                                            <Button1 variant="outlined" 
                                                type="button" // Explicitly set the type to "button" to avoid accidental submissions
                                                disabled={textfieldLocked}
                                                onClick={handleClick}
                                                startIcon={<UploadIcon />}>
                                                Choose file
                                            </Button1>
                                            <input
                                                type="file"
                                                ref={hiddenFileInput}
                                                onChange={handleChange}
                                                style={{display: 'none'}}
                                            />
                                            <Button1 type="button" variant="contained" disabled={textfieldLocked} onClick={handleUpload}>Upload</Button1>{' '}
                                            {/* configTimerForResParsedSkillsLoader(); setTimeout(getParsedSkills, 20000); */}
                                        {/* </SpaceBetween> */}
                                        </Flex>

                                        <TokenGroup
                                            onDismiss={({detail: {itemIndex}}) => {
                                                handleDismiss(itemIndex)
                                            }}
                                            items={uploadList}
                                            alignment="vertical"
                                            limit={10}
                                        />
                                    </div>
                                        {
                                        allUpload.length > 0 ? 
                                            <Typography variant="p" color='green'>You have uploaded {allUpload.join(', ')}</Typography> 
                                            : 
                                            null
                                        }
                                </Card>

                                <Card columnStart="1" columnEnd="2">
                                    <FormField
                                        label={
                                            <Text>
                                                Level of Highest Degree
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        
                                        }
                                       
                                        description={<Text color="white">newline</Text>}
                                    />
                                    <SelectField
                                        id="req_1"
                                        size="default"
                                        // placeholder="What's your highest degree?"
                                        defaultValue={degLevel}
                                        onChange={(e) => setDegLevel(e.target.value)}
                                        isDisabled={textfieldLocked}
                                        key={degLevel}
                                    >
                                        <option value="" disabled>Select your highest degree</option>
                                        <option value="0">Bachelor's</option>
                                        <option value="1">Master's/MBA</option>
                                        <option value="2">Doctorate/MD/Ph.D.</option>
                                    </SelectField>
                                    {/* <FormControl fullWidth>
                                        <Select
                                            labelId="highest-deg-name"
                                            id="req_1"
                                            value={degLevel}
                                            onChange={(e) => setDegLevel(e.target.value)}
                                        >
                                            <MenuItem value="0">Bachelor's</MenuItem>
                                            <MenuItem value="1">Master's/MBA</MenuItem>
                                            <MenuItem value="2">Doctorate/MD/Ph.D.</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                </Card>

                                <Card columnStart={{base:"1", small:"2",large:"2"}} columnEnd={{base:"-1",small: "-1",large: "3"}}>
                                    <FormField
                                        label={
                                            <Text>
                                                Expected Graduation
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        }
                                        description= "When do you expect to graduate?"
                                    />
                                    
                                    <SelectField
                                        id="req_2"
                                        size="default"
                                        // placeholder="Please select an option below:"
                                        defaultValue={graduateDate}
                                        key={graduateDate}
                                        onChange={(e) => setGraduateDate(e.target.value)}
                                        isDisabled={textfieldLocked}
                                    >
                                        <option value="" disabled>Select your expected graduation</option>
                                        <option value="0">Already graduated</option>
                                        <option value="1">In 6 months</option>
                                        <option value="2">In a year</option>
                                        <option value="3">In two years</option>
                                        <option value="4">More than two years</option>
                                        <option value="5">Not sure</option>
                                    </SelectField>
                                </Card>

                                <Card columnStart={{base:"1",small: "1", large:"3"}} columnEnd={{base:"-1",small: "2", large: "4"}}>
                                        <FormField
                                            label={
                                                <Text>
                                                    Degree Name
                                                    <Text as="span" fontSize="1.2rem" color="red">
                                                        <sup>{' '}
                                                        (required)</sup>
                                                    </Text>
                                                </Text>
                                            }
                                            description='e.g., neuroscience'
                                        />
                                    <TextField
                                        id="req_3"
                                        defaultValue={degName}
                                        onChange={(e) => setDegName(e.target.value)}
                                        errorMessage="There is an error"
                                        isDisabled={textfieldLocked}
                                    />
                                </Card>

                                <Card columnStart={{base:"1",small: "2", large: "4"}} columnEnd="-1">
                                    <FormField
                                        // label='Related Degree'
                                        label={
                                            <Text>
                                                Related Degree
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        }
                                        description='e.g., cognitive science, physiology'
                                    />
                                    
                                    <TextField
                                        id="req_4"
                                        defaultValue={rtDeg}
                                        onChange={(e) => setRTDeg(e.target.value)}
                                        errorMessage="There is an error"
                                        isDisabled={textfieldLocked}
                                    />
                                    
                                </Card>


                            </Grid>
                           
                         {/* User input skills - Proficient skills and familiar-level skills*/}
                            <Grid
                                columnGap="0.5rem"
                                rowGap="0.5rem"
                                templateColumns={{base: "1fr" ,large:"1fr 1fr"}}
                                templateRows={{base: "1.5fr 1.5fr 1fr 1fr", large: "1.5fr 1fr"}}
                            >
                                <Card columnStart="1" columnEnd={{base:"-1", large:"2"}}>
                                    <FormField
                                        label={
                                            <Text>
                                                Proficient-level Skills
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        }
                                        description = "incl. the subjects, methods & tools of your study, e.g., biosonar sensing, stereocamera tracking, MATLAB, etc."
                                    />
                                    <TextAreaField
                                        id="req_5"
                                        placeholder="Use comma to separate skills"
                                        isDisabled={textfieldLocked}
                                        defaultValue={proSkills}
                                        onChange={(e) => setProSkills(e.target.value)}
                                        errorMessage="There is an error"
                                        // onInput={() =>  setSubmitButtonDisabled(false)}
                                    />
                                </Card>

                                <Card columnStart={{base: "1", large: "2"}} columnEnd="-1">
                                    <FormField
                                        label={
                                            <Text>
                                                Familiar-level Skills
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        }
                                        // label={
                                        //     <Text>
                                        //         Familiar-level Skills
                                        //     </Text>
                                        // }
                                        description="e.g., depth estimation, Lidar, point clouds, C++, R"
                                    />
                                    <TextAreaField
                                        id="req_6"
                                        placeholder="Use comma to separate skills"
                                        defaultValue={famSkills}
                                        onChange={(e) => setFamSkills(e.target.value)}
                                        isDisabled={textfieldLocked}
                                        alt="show this text"
                                        errorMessage="There is an error"
                                    />
                                </Card>

                                <Card rowStart={{base: "3", large:"2"}} rowEnd={{base: "4", large:"-1"}}>
                                    <FormField
                                        label={
                                            <Text>
                                                What do you immediately need? 
                                                <Text as="span" fontSize="1.2rem" color="red">
                                                    <sup>{' '}
                                                    (required)</sup>
                                                </Text>
                                            </Text>
                                        }
                                        // label={
                                        //     <Text>
                                        //         What do you immediately need? 
                                        //     </Text>
                                        // }
                                        description="Tell us what you want:"
                                    />
                                    <SelectField
                                        size="default"
                                        id="req_7"
                                        // placeholder="Please select an option below:"
                                        defaultValue={userNeed}
                                        key={userNeed}
                                        hasError={false}
                                        errorMessage="This is a required field."
                                        onChange={(e) => { const selectedValue = e.target.value;
                                            setUserNeed(selectedValue);
                                            console.log('userNeed', selectedValue); showUserInputField(e);
                                            console.log('uid', uid, 'thisResUploadTime', thisResUploadTime, 'filename', thisResName, 'parsedSkills', parsedSkills, 'maxdeglevel', degLevel, 'maxdegname', degName, 'maxgradtime', graduateDate,
                                            'rtdeg', rtDeg, 'proskills', proSkills, 'famskills', famSkills, 'userneed', userNeed, 'usercomm', userComm);
                                        }}
                                        isDisabled={textfieldLocked}
                                    >
                                        <option value="" disabled>Select your immediate need</option>
                                        <option value="0">Career development</option>
                                        <option value="1">To look for internship</option>
                                        <option value="2">To find my first industry job</option>
                                        <option value="3">Find a better job</option>
                                        <option value="4">Just look around</option>
                                        <option value="5">Other</option>
                                    </SelectField>
                                    {userNeed === "5" && userComm !== '' ? <Typography variant="p" color='green'>Other: {userComm}</Typography> : null}

                                </Card>

                                <Card id="TestsDiv" rowStart={{base:"4", large:"2"}} rowEnd="-1">
                                    <FormField
                                        label={
                                            <Text>
                                                If you selected "Other", please specify: 
                                            </Text>
                                        }
                                    />
                                    <TextField
                                        defaultValue={userComm}
                                        onChange={(e) => setUserComm(e.target.value)}
                                        isDisabled={textfieldLocked}
                                        alt="show this text"
                                        errorMessage="There is an error"
                                    />
                                </Card>

                                

                            </Grid>
                            <Flex direction="row" justifyContent="flex-end">
                                <ButtonGroup>
                                <Button type="submit" disabled={submitButtonDisabled} >Submit</Button> 
                                <Button variant="primary" type="button" onClick={(e) => {getData(e); setSubmitButtonDisabled(false)}}>Edit</Button>
                                <Button type="button" onClick={cancelEdit}>Cancel</Button>
                                </ButtonGroup>
                                
                            </Flex>
                            
                            
                            
                        </Flex>
                            
                    </Card>

            </Container>



            {showJobRec && (
            
            <Container
                aria-label="our-recs"
                header={
                    <Header variant="h2">
                        Jobs that may fit your background
                    </Header>
                    
                }
               
            >   
                {/* {loading
                ? <>
                    { isLoaderDtm ?  <Loader variation="linear" percentage={DeterminateLoaderExample(60)} isDeterminate /> : <Loader variation="linear" />}
                  </>
                :  null
                } */}
                {/* {console.log('IsloaderDTM', isLoaderDtm, 'loading', loading)} */}

                {/* {loading ? <Loader variation="linear" /> : null} */}
                <Flex direction="column">
                    {/* {loading ? <Loader variation="linear" /> : null} */}
                    {loading ? <LinearProgress /> : null}
                    {/* {isLoaderDtm ? <Loader variation="linear" percentage={percent} isDeterminate /> : null} */}
                    {isLoaderDtm ? <LinearProgress variant='determinate' value={percent} /> : null}
                
                

                <Grid
                    columnGap="0.5rem"
                    rowGap="0.5rem"
                    templateColumns="8fr 1fr 2fr"
                    templateRows="1fr"
                    >

                <Card columnStart="1" columnEnd="2">
                    <Flex justifyContent="center" padding="0 0 10px 0"> <Text fontSize="1.5rem" fontWeight="bold"> Our recommendations </Text> </Flex>
                    {customList(desparr, companyarr, mskillsarr, titlearr, left)}
                    
                </Card>

                <Card columnStart="2" columnEnd="3">
                    <Flex direction='column' padding="6rem 0 0 0">
                        <Button1
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button1>
                        <Button1
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button1>
                        <Button1
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button1>
                        <Button1
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button1>
                    </Flex>
                    
                </Card>

                <Card columnStart="3" columnEnd="-1">
                <Flex justifyContent="center" padding="0 0 10px 0"> <Text fontSize="1.5rem" fontWeight="bold"> Your choices </Text> </Flex>
                    {customList(desparr, companyarr, mskillsarr, titlearr, right)}
                </Card>

                </Grid>
                
                </Flex>

                
            </Container>
            )}

            
            
            {showJobRec && (<Container
                aria-label="ChatGPT-response"
                header={
                    <Header variant="h2">
                        ChatGPT4's Recommendations
                    </Header>
                    
                }
               
            > 
            <Flex aria-label="chatgpt-response-printout">
                <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap'}} component={'span'} aria-label="chatgpt-response-pre">
                    {parseMarkdownWithHeaders(gptres)  }
                </pre>
                
            
            </Flex>

            </Container>
            )}
            
            

            


        </Flex>

    );
};


function Match() {
    return (
        <Content />
    );
}

export default Match;