import React, { Component } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Switch, FormGroup, FormControlLabel } from '@mui/material';


class TimeZoneSelector extends Component {
    constructor(props) {
        super(props);
        const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log('props.timeZoneState,', props.timeZoneState);
        this.state = {
            selectedTimeZone: props.timeZoneState.selectedTimeZone,
            useSystemTimeZone: props.timeZoneState.useSystemTimeZone,
            systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    }

    // handleTimeZoneChange = (event) => {
    //     this.setState({ selectedTimeZone: event.target.value });
    //     this.props.onTimeZoneChange(this.state);
    //     console.log('event.target.value',event.target.value);
    // };

    handleTimeZoneChange = (event) => {
        const newValue = event.target.value;
        this.setState({ selectedTimeZone: newValue }, () => {
            // Now the state has been updated, and we can safely pass the new state to the parent.
            this.props.onTimeZoneChange({...this.state, selectedTimeZone: newValue});
            console.log('New selected time zone:', newValue);
        });
    };
    
    componentDidUpdate(prevProps) {
        // Check if timeZoneState prop has changed
        if (this.props.timeZoneState !== prevProps.timeZoneState) {
          this.setState({
            selectedTimeZone: this.props.timeZoneState.selectedTimeZone,
            useSystemTimeZone: this.props.timeZoneState.useSystemTimeZone,
            // Note: systemTimeZone is derived from the browser and likely doesn't need to update
          });
        }
      }

    // handleToggleChange = () => {
    //     this.setState((prevState) => ({
    //         useSystemTimeZone: !prevState.useSystemTimeZone
    //     }));
    // };

    handleToggleChange = () => {
        this.setState((prevState) => ({
          useSystemTimeZone: !prevState.useSystemTimeZone,
        }), () => {
          // After state update, pass the new state up to the parent component
          this.props.onTimeZoneChange(this.state);
        });
      };

    render() {
        const { selectedTimeZone, useSystemTimeZone } = this.state;
        // console.log('selectedTimeZone, useSystemTimeZone', selectedTimeZone, useSystemTimeZone);
        const timeZones = Intl.supportedValuesOf('timeZone');
        
        // const timeZones = [
        //     { value: 'America/New_York', label: 'Eastern Standard Time (EST)' },
        //     { value: 'America/Chicago', label: 'Central Standard Time (CST)' },
        //     { value: 'America/Denver', label: 'Mountain Standard Time (MST)' },
        //     { value: 'America/Los_Angeles', label: 'Pacific Standard Time (PST)' },
        //     { value: 'America/Anchorage', label: 'Alaska Standard Time (AKST)' },
        //     { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Standard Time (HAST)' }
        //     // Add more time zones as needed
        //   ];
          

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '2px' }}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={useSystemTimeZone} onChange={this.handleToggleChange} />}
                        label="Use System Time Zone"
                    />
                </FormGroup>
                {!useSystemTimeZone && (
                    <div style={{ width: '7rem' }}>

                        <FormControl size="small">
                            <InputLabel id="timeZoneLabel">Time Zone</InputLabel>
                            <Select
                                labelId="timeZoneLabel"
                                id="timeZone"
                                value={selectedTimeZone}
                                onChange={this.handleTimeZoneChange}
                                style={{ marginBottom: '20px', width: '200px' }}
                            >
                                {timeZones.map((timeZone) => (
                                    <MenuItem key={timeZone} value={timeZone}>
                                        {timeZone}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>

                )}
            </div>
        );
    }
}

export default TimeZoneSelector;
