import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { Button, Grid } from '@mui/material';


const MultiPdfViewer = ({ pdfPaths }) => {
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handlePdfButtonClick = (pdfPath) => {
    setSelectedPdf(pdfPath);
  };

  
  return (
    <div>
      {/* <div>
        {pdfPaths.map((pdfPath, index) => (
          
          <button key={index} onClick={() => handlePdfButtonClick(pdfPath)}>
            File {pdfPath.filename}
          </button>
        ))}

      </div> */}

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"> 
          {pdfPaths.map((pdfPath, index) => ( 
          <Grid item key={index} data-index={index} xs={12} sm={3} md={3}> 
              <Button key={index} onClick={() => handlePdfButtonClick(pdfPath)} style={{ textTransform: 'none' }}> 
              {pdfPath.filename.slice(0, -4)} 
              </Button> 
          </Grid> ))} 
        </Grid>
      
        {selectedPdf && (
        <div style={{ height: '500px', marginTop: '20px' }}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`} >
            <Viewer fileUrl={selectedPdf.signedUrl} />
          </Worker>
        </div>
        )}
      {/* </div> */}
    </div>
  );
};

export default MultiPdfViewer;
