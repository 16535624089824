import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { nameDict, findMatchingKeys } from '../utils/objectUtils';


const DataTable = ({ data }) => { 

    // const matchKeys = findMatchingKeys(data, nameDict);
    
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="simple table">
        <TableBody>
          {Object.entries(data).map(([attr, value]) => (
            <TableRow
              key={attr}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {nameDict[attr]}
              </TableCell>
              <TableCell align="left">{typeof value === 'object' ? JSON.stringify(value) : value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
