import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';

const NotificationPanel = () => {
    const [subscribed, setSubscribed] = useState(true); // Default to true or fetch from user's settings
    const [userid, setUserid] = useState(''); // Default to true or fetch from user's settings

    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        API.get('allusersapi', '/allusers/:userid').then(response => {
            if (isMounted) {
                // console.log('API response:', response);
                
                setUserid(response[0].userid); // acutally user sub from cognito registration
                const isSubscribed = response[0]?.emailPreferences === 'true';
                // console.log('isSubscribed:', isSubscribed);
                setSubscribed(isSubscribed);
            }
        }).catch(error => {
            console.log('API error:', error);
        });

        return () => {
            isMounted = false; // Clean up the useEffect hook
        };
    }, []);



    const handleSubscriptionChange = (event) => {
        const isChecked = event.target.checked; // This will be a boolean value
        const preferenceAsString = isChecked.toString(); // Convert boolean to string ("true" or "false")
    
        setSubscribed(isChecked); // Update state with the boolean value
    
        // Send the string representation of the preference to your backend
        API.post('allusersapi', '/allusers/email-preference', {
            body: {
                userid: userid,
                emailPreferences: preferenceAsString
            }
        }).then(response => {
            console.log('API response:', response);
        }).catch(error => {
            console.log('API error:', error);
        });
    };

    return (
        <Alert severity="info" style={{ marginBottom: '20px' }}>
            <div>
                <Typography variant='body1' style={{marginBottom: '10px'}}>
                    {/* We're excited to introduce our new career coaching services, rolling out in the coming weeks! These offerings include webinars and personal coaching sessions led by industry professionals actively involved in hiring decisions within your specific field. 
                    <br /> */}
                    Beta testing starts now! Join us today and enjoy a 10% discount on your first coaching session. After your session, complete the feedback form located under the "Feedback" tab in the left-side menu with your order ID, and we'll automatically apply a 10% refund.
                    {/* <br /> */}
                    {/* <br /> */}
                    {/* If you opted in to receive emails during sign-up, you're all set—look out for updates in your inbox! If not, please activate notifications here to receive information on free and low-cost webinars and coaching sessions designed to significantly enhance your career, plus much more. */}
                </Typography>
                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={subscribed}
                            onChange={handleSubscriptionChange}
                            name="subscribeEmail"
                            color="primary"
                        />
                    }
                    label={subscribed ? 'Subscribed' : 'Unsubscribed'}
                    
                /> */}
            </div>
        </Alert>
    );
};

export default NotificationPanel;
