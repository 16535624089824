
import React, { useEffect, useState } from 'react';
import withAuth from '../components/withAuth';
import { API, Storage } from 'aws-amplify';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import MultiPdfViewer from '../components/MultiPdfViewer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, red, yellow } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Grid from '@mui/material/Grid';


const AdminDash = () => {

    const [nameArr, setNameArr] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [linkedinArr, setLinkedinArr] = useState([]);
    const [idArr, setIdArr] = useState([]);
    const [applytimeArr, setApplyTimeArr] = useState([]);

    const [expanded, setExpanded] = useState(Array(10).fill(true));
    const [checkMarkArr, setCheckMarkArr] = useState(Array(10).fill(false));
    const [signedUrlsMap, setSignedUrlsMap] = useState(new Map());
    const [showTextField, setShowTextField] = useState(Array(10).fill(false));
    const [commentArr, setCommentArr] = useState(Array(10).fill(''));

    const handleCommentChange = (index, value) => {
        const updatedComments = [...commentArr];
        updatedComments[index] = value;
        setCommentArr(updatedComments);
      };
    

    const setExpandedAtIndex = (index, value) => {
        setExpanded(expanded.map((item, idx) => idx === index ? value : item));
        setCheckMarkArr(checkMarkArr.map((item, idx) => idx === index ? value : item));
      };

    const setCheckMarkArrAtIndex = (index, value)=> {
        setCheckMarkArr(checkMarkArr.map((item, idx) => idx === index ? value : item));
      };

    // const setCheckMarkArrAtIndexToNull = (index, value)=> {
    // setCheckMarkArr(checkMarkArr.map((item, idx) => idx === index ? value : item));
    // };

    const getSignedUrl = async (filename, targetIdentityId) => {
    try {
        const config = {
        level: 'protected', // defaults to `public`
        identityId: targetIdentityId, // id of another user, if `level: protected`
        download: false, // defaults to false
        expires: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        validateObjectExistence: true, // defaults to false
        cacheControl: undefined, // Specifies caching behavior along the request/reply chain
        };
    
        const signedUrl = await Storage.get(filename, config);
        //  const signedUrl = await Storage.get(filename, { level: 'protected', validateObjectExistence: true, identityId: targetIdentityId });


        return signedUrl;
    } catch (error) {
        console.error('Error generating signed URL:', error);
        throw error;
    }
    };


    useEffect(() => {
        let isMounted = true;

        const fetchDataAndImages = async () => {
            
            try {
              // Fetch data using API.get
                const response = await API.get('hrapi', '/hr/:userid');
                console.log('response in admin,', response);
                // Extract image keys from the API response
                
                const names = response.map(item => `${item.firstname} ${item.midname} ${item.lastname}`);
                const statuses = response.map(item => item.status);
                const linkedins = response.map(item => item.linkedin); 
                const ids = response.map(item => item.userid); 
                const atimes = response.map(item => item.applytime);
                const files = response.map(item => item.filenames);
                
               
                setNameArr(names);
                setStatusArr(statuses);
                setLinkedinArr(linkedins);
                setIdArr(ids);
                setApplyTimeArr(atimes);

                const signedUrlsMap = new Map();

                for (let i = 0; i < ids.length; i++) {
                    const targetIdentityId = ids[i];
                    const filenames = files[i];

                    const signedUrlPromises = filenames.map(async (filename) => {
                        
                        const signedUrl = await getSignedUrl(filename, targetIdentityId);
                        return { filename, signedUrl };
                    });

                    const signedUrls = await Promise.all(signedUrlPromises);
                    
                    signedUrlsMap.set(targetIdentityId, signedUrls);
                    
                }
                setSignedUrlsMap(signedUrlsMap);
                console.log('signedUrlsMap, ', signedUrlsMap);

            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
        
          // Call the async function
          if (isMounted) {
            fetchDataAndImages();
          }

          return () => {
            isMounted = false;
          };
          

    }, [])

    const handleApproveClick = (uid, apptime) => {
        // Handle button click for the specific item
        console.log('uid, apptime', uid, apptime);
        API.put("hrapi", "/hr", {
            body: {
                userid: uid,
                applytime: apptime,
                status: 1
            },
        }).then(response => {
            // Handle the response if needed
            console.log("API response:", response);
        }).catch(error => {
            // Handle errors if any
            console.error("API error:", error);
        });
        // Add your logic here
      };

      const handleDeclineClick = (uid, apptime) => {
        // Handle button click for the specific item
        API.put("hrapi", "/hr", {
            body: {
                userid: uid,
                applytime: apptime,
                status: -1
            },
        }).then(response => {
            // Handle the response if needed
            console.log("API response:", response);
        }).catch(error => {
            // Handle errors if any
            console.error("API error:", error);
        });
        // Add your logic here
      };

      const handleSubmitComment = (uid, apptime, comment) => {
        // Handle button click for the specific item
        
        API.put("hrapi", "/hr", {
            body: {
                userid: uid,
                applytime: apptime,
                status: 2,
                comment:comment,
            },
        }).then(response => {
            // Handle the response if needed
            console.log("API response:", response);
        }).catch(error => {
            // Handle errors if any
            console.error("API error:", error);
        });
        // Add your logic here
      };

      const handleMoreDocButtonClick = (index, value)=> {
        setShowTextField(showTextField.map((item, idx) => idx === index ? value : item));
      };

      const ensureProtocol = (url) => {
        if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
          return `https://${url}`;  // Defaulting to secure HTTP
        }
        return url;
      };

    return ( 
        <div>
            
        {nameArr.map((name, index) => (
            <Accordion key={`${idArr[index]}-${index}`} data-index={index} expanded={expanded[index]} title={`Name: ${name}`} value={index + 1} defaultExpanded>
            
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            >
            {checkMarkArr[index] === true && <CheckCircleIcon style={{ color: green[500], marginRight: 8 }} />}
            {checkMarkArr[index] === null && <CancelIcon style={{ color: red[500], marginRight: 8 }} />}
            {checkMarkArr[index] === 'pending' && <HourglassEmptyIcon style={{ color: yellow[500], marginRight: 8 }} />}
            {name}, {statusArr[index] === 0 ? "Being Reviewed" : statusArr[index]}, <a href={ensureProtocol(linkedinArr[index])} target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </AccordionSummary>
            <AccordionDetails>
                    
                   
                    {/* <div>
                        {Array.from(signedUrlsMap.entries()).map(([identityId, signedUrls]) => {
                            console.log('identity', identityId, 'signedUrls sent', signedUrls);
                            return (
                            <div key={identityId} data-index={identityId}>
                                <MultiPdfViewer pdfPaths={signedUrls} />
                            </div>
                            );
                            })}
                    </div> */}
                    <div>
                        {signedUrlsMap.get(idArr[index]) && (
                            <div key={idArr[index]} data-index={idArr[index]}>
                                <MultiPdfViewer pdfPaths={signedUrlsMap.get(idArr[index])} />
                            </div>
                        )}
                    </div>

            </AccordionDetails>
            <AccordionActions>
            <Button onClick={() => {handleApproveClick(idArr[index], applytimeArr[index]); setExpandedAtIndex(index, false); setCheckMarkArrAtIndex(index, true);}}>Approve</Button>
            <Button onClick={() => {handleDeclineClick(idArr[index], applytimeArr[index]); setExpandedAtIndex(index, false); setCheckMarkArrAtIndex(index, null);}}>Decline</Button>
            <Button onClick={() => handleMoreDocButtonClick(index, true)}>Request for more documents
            </Button>
            {showTextField[index] && (
                <>
                <TextField
                label="Document Details"
                variant="outlined"
                value={commentArr[index]}
                onChange={(e) => handleCommentChange(index, e.target.value)}
                // Add any other props you need for the TextField
                />
                <Button onClick={() => {handleSubmitComment(idArr[index], applytimeArr[index], commentArr[index]); setExpandedAtIndex(index, false); setCheckMarkArrAtIndex(index, 'pending');}}> Submit </Button>
                </>
            )}
            </AccordionActions>
            </Accordion>
        ))}
        </div>

     );
}
 
export default withAuth(AdminDash);