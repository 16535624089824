import React from 'react';
import Amplify from 'aws-amplify';


import awsmobile from '../aws-exports';
Amplify.configure(awsmobile);


const Dashboard = () => {
    return (
        <div>
            This is Dashboard
        </div>
    );
};

export default Dashboard;