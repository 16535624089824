import { Paper, Typography } from "@mui/material";
import React from "react";

function Terms() {

    return (
        <div>
            <Paper elevation={1} style={{padding: '2rem'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant="h4">Terms of Service</Typography>
            </div>
            <Typography variant="body1">
            <strong>1. Introduction</strong>
            <br/>
            Welcome to idyllic AI! By accessing our service, you agree to be bound by these Terms and Conditions. Please read them carefully.
        <br/>
<strong>2. Privacy and Data Protection</strong>
<br/>
We are committed to protecting your privacy and ensuring the security of your information. To learn more about how we handle your personal data, please refer to our Privacy Policy.
<br/>

<strong>3. Use of Your Data</strong> <br/>
To provide and improve our services, we collect and use your data in the following ways:
<li>Service Enhancement: We analyze user activity to make improvements and enhance usability.</li>
<li>Model Improvement: We use data to improve existing models and algorithms, ensuring a better user experience.</li>
<li>Your data is crucial for the operational effectiveness of our services and the development of new features.</li>

<strong>4. Protection of Your Information</strong> <br/>
We implement a variety of security measures to maintain the safety of your personal information. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.
<br/>
<strong>5. No Sale of Data</strong> <br/>
We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or serving you, so long as those parties agree to keep this information confidential.
<br/>
<strong> 6. Changes to Terms and Conditions</strong> <br/>
We reserve the right to modify these terms at any time. You should check this page regularly to notice any changes. Changes to the terms will become effective immediately upon being posted on this page.
<br/>
<strong>7. Governing Law</strong> <br/>
These terms will be governed by and construed in accordance with the laws of the United States, and you submit to the non-exclusive jurisdiction of the state and federal courts located in the United States for the resolution of any disputes.
<br/>
<strong>8. Contact Us</strong> <br/>
If you have any questions about these Terms and Conditions, please contact us at contact@idyllic.ai.
            </Typography>

            </Paper>
        </div>
    );
}

export default Terms;