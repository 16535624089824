import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, TextField, InputAdornment } from '@mui/material';


const ServiceItem = ({ serviceName, serviceState, setServiceState }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // Check if the serviceState has the serviceName and set isChecked accordingly
    const hasService = serviceState.has(serviceName);
    setIsChecked(hasService);
    // console.log('isChecked', isChecked, 'serviceState,', serviceState, 'serviceState.has', serviceState.has(serviceName));
  }, [serviceState]); // Depend on serviceState and serviceName to re-run this effect

    const handleServiceCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setServiceState((prevService) => {
            const updatedService = new Map(prevService);
            if (isChecked) {
            // Set initial values for service 1
            updatedService.set(serviceName, {
                appointmentLength: '0',
                charge: '0',
                numSess: '0',
                // Add other attributes here
            });
            } else {
            // Remove service from the map if unchecked
            updatedService.delete(serviceName);
            }
            
            return updatedService;
        });
        // Initialize values based on service name
        // let initialNumSess = 0; // or any other default value
        
        // if (isChecked) {
        //     setServiceState(prevState => new Map(prevState.set(serviceName, {
        //     numSess: initialNumSess,
        //     appointmentLength: 30,
        //     charge: 0,
        //     // Other attributes
        //     })));
        // }

    };

  const handleNumSessionsChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        // const updatedNumber = parseFloat(value);
        // service.numSess = updatedNumber;
        service.numSess = value;
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };

  const handleAppointmentLengthChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        // const updatedNumber = parseFloat(value);
        // service.appointmentLength = updatedNumber;
        service.appointmentLength = value;
        updatedService.set(serviceName, service);
        return updatedService;
      });
  };

  const handleChargeChange = (e) => {
    const value = e.target.value;
    setServiceState((prevState) => {
        const updatedService = new Map(prevState);
        const service = updatedService.get(serviceName);
        // const updatedNumber = parseFloat(value);
        // service.charge = updatedNumber;
        service.charge = value;
        updatedService.set(serviceName, service);
        console.log('updatedService', updatedService);
        return updatedService;
      });
    
  };

  return (
    <Grid container item xs={12} md={12} lg={12} direction="row" justifyContent="flex-start" alignItems="flex-start" gap={1}>
      <Grid item xs={12} md={5} lg={5}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} name={serviceName} onChange={handleServiceCheckboxChange} />}
          label={serviceName}
        />
      </Grid>

      <Grid item xs={4} md={2} lg={2}>
        {serviceState.has(serviceName) && (<TextField
          fullWidth
          size="small"
          label="Number of Sessions"
          type="number"
          value={serviceState.get(serviceName).numSess}
          onChange={handleNumSessionsChange}
        />)}
      </Grid>

      <Grid item xs={4} md={2} lg={2}>
      {serviceState.has(serviceName) && (<TextField
          fullWidth
          label="Session length"
          size="small"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">mins</InputAdornment>,
            min: 0
          }}
          value={serviceState.get(serviceName).appointmentLength}
          onChange={handleAppointmentLengthChange}
        />)}
      </Grid>
      
      <Grid item xs={4} md={2} lg={2}>
      {serviceState.has(serviceName) && (<TextField
          fullWidth
          size="small"
          label="Charge/session"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            min: 0
          }}
          type="number"
          value={serviceState.get(serviceName).charge}
          onChange={handleChargeChange}
        />)}
      </Grid>
    </Grid>
  );
};

export default ServiceItem;
