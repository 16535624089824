// ServiceFilter.js
import React, { useState, useEffect} from 'react';
import { FormControl, InputLabel, Select, MenuItem, Autocomplete, IconButton, Paper, TextField, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear'; 
import { sessionDict, 
  reverseMapping, 
  transformDBdate, 
  transformRecurrTS2, 
  transformToMapForSessions,
  getAllSessionTitles } from '../utils/objectUtils';
import { getISOWeek, addWeeks } from 'date-fns';

const ServiceFilter = ({ profiles, setFilteredProfiles }) => {
  const [servFilter, setServFilter] = useState('');
  const [availabilityFilter, setAvailabilityFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const allServices = getAllSessionTitles(profiles);
  const [triggerSearch, setTriggerSearch] = useState(false);
  // console.log('allServices', allServices);
  // const allServices = [
  //   "Essay Review",
  //   "SAT Prep",
  //   "Math Tutoring",
  //   "Science Tutoring",
  //   "Resume Writing",
  //   "College Application Guidance",
  //   "Coding Lessons",
  //   "Language Lessons",
  //   "Music Lessons",
  //   "Art Classes",
  //   "Sports Coaching",
  //   "Test Anxiety Coaching",
  //   "Scholarship Application Help",
  //   "Internship Search Assistance",
  //   "Study Abroad Advice",
  //   "Career Counseling",
  //   "Interview Preparation"
  // ];
  
  useEffect(() => {
    if (triggerSearch) {
      handleSearchClick();
      setTriggerSearch(false); // Reset the trigger after the search is executed
    }
  }, [triggerSearch]); 

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'serviceFilter') {
      setServFilter(value);
    } else if (name === 'availabilityFilter') {
      setAvailabilityFilter(value);
    }

    filterProfiles(name === 'serviceFilter' ? value : servFilter, name === 'availabilityFilter' ? value : availabilityFilter);
    // filterProfiles(value, name);
  };

const filterProfiles = (currentServFilter, currentAvailabilityFilter) => {
    let filteredProfs = profiles;
  
    // Apply service filter if not 'All' and a value is selected
    if (currentServFilter !== 'All' && currentServFilter) {
      filteredProfs = profileHasServ(currentServFilter, filteredProfs, sessionDict);
    }
  
    // Apply availability filter if a value is selected
    if (currentAvailabilityFilter) {
      filteredProfs = filterByAvailability(currentAvailabilityFilter, filteredProfs);
    }
  
    setFilteredProfiles(filteredProfs);
  };

  const profileHasServ = (expandedName, allProfiles, sessionDict) => {
    const invertedServDict = reverseMapping(sessionDict);
    if (expandedName in invertedServDict) {
      const propName = invertedServDict[expandedName];
      return allProfiles.filter(profile => propName in profile);
    }
    return [];
  };

  // const filterByAvailability = (availability, allProfiles) => {
  //   const today = new Date();
  //   const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
  //   const twoWeeksLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
  //   const oneMonthLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 28);

  //   return allProfiles.filter(profile => {
  //     const transMap = transformDBdate(profile.selectedTS);
      
  //     const STSmap = transformRecurrTS2(transMap);
  //   //   console.log('STSmap', STSmap);

  //     for (let [date, hours] of STSmap.entries()) {
  //       const profileDate = new Date(date);
  //       // console.log('profileDate', profileDate, 'date', date);
  //       if ((availability === 'One Week' && profileDate <= oneWeekLater) ||
  //           (availability === 'Two Weeks' && profileDate <= twoWeeksLater) ||
  //           (availability === 'One Month' && profileDate <= oneMonthLater)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   });
  // };

  const filterByAvailability = (availCriteria, allProfiles) => { 
    const today = new Date();
    const thisWeek = getISOWeek(today);

    const thisAndNext = Array.from({length: 2}, (_, i) => thisWeek + i);
    const thisAndNextTwo = Array.from({length: 3}, (_, i) => thisWeek + i);
    const thisAndNextThree = Array.from({length: 4}, (_, i) => thisWeek + i);
    
    return allProfiles.filter(profile => {
      
      const availWeeks = profile.availWeeks;
      

      if ((availCriteria === 'thisWeek' && availWeeks.includes(thisWeek)) ||
          (availCriteria === 'thisAndNext' && thisAndNext.some(week => availWeeks.includes(week))) ||
          (availCriteria === 'thisAndNextTwo' && thisAndNextTwo.some(week => availWeeks.includes(week))) ||
          (availCriteria === 'thisAndNextThree') && thisAndNextThree.some(week => availWeeks.includes(week))) {
            // console.log(availCriteria, availWeeks, thisAndNextThree.some(week => availWeeks.includes(week)));
            return true;
      }
      return false;
    });
  };

  const handleSearchInputChange = (event, newValue) => {
    // setSearchTerm(event.target.value);
      setSearchTerm(newValue);
      if (newValue === '') {
        setFilteredProfiles(profiles); // Assuming 'profiles' is the original complete list
      } 
  };

  // const handleClearSearch = () => {
  //   setSearchTerm(''); // Clear the search term
  //   setFilteredProfiles(profiles);
  //   console.log('search cleared');
  // };


  const handleSearchClick = () => {

    // Clear previous two filters
    setServFilter('All'); // Assuming 'All' is the default state for service filter
    setAvailabilityFilter(''); // Assuming '' or some other value is the default state for availability filter

    if (!searchTerm.trim()) {
      setFilteredProfiles(profiles); // Reset if search term is empty
      return;
    }

    

    // setFilteredProfiles(filtered);
    const filtered = profiles.filter(profile => {
        // Process or transform the property to get mapsArray
        // Assuming someFunc returns the mapsArray from the profile's property
        const sessions = profile.otherSessions?.map(session => transformToMapForSessions(session));
        console.log('sessions,', sessions);

        if (!sessions) {
            return false;
          }

        return sessions.some(map =>
          Array.from(map.keys()).some(key =>
            key.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      });
    // console.log('filtered,', filtered);
    setFilteredProfiles(filtered);
  };

  return (
    // <div style={{ display: 'flex', flexDirection: 'row'}}>
    <Grid container>

    
    <Grid item xs={6} sm="auto">
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-autowidth-label"  >Service</InputLabel>
        <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={servFilter}
            onChange={handleFilterChange}
            autoWidth
            size="small"
            label="Service"
            inputProps={{
                name: 'serviceFilter',
                id: 'service-filter',
              }}
        >
            <MenuItem value="All">
            <em>All</em>
            </MenuItem>
            <MenuItem value="Resume Review">Resume Review</MenuItem>
            <MenuItem value="Phone Screen">Phone Screen</MenuItem>
            <MenuItem value="Technical Interview">Technical Interview</MenuItem>
            <MenuItem value="Coding Challenge">Coding Challenge</MenuItem>
            {/* Add more options as needed */}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs= {6} sm="auto">
        <FormControl sx={{ m: 1, minWidth: 120, p:0 }}>
          <InputLabel htmlFor="availability-filter">Availability</InputLabel>
          <Select
           
            autoWidth
            size="small"
            value={availabilityFilter}
            onChange={handleFilterChange}
            inputProps={{
              name: 'availabilityFilter',
              id: 'availability-filter',
            }}
            label="Availability"
          >
            {/* <option aria-label="None" value="" />
            <option value="One Week">Within One Week</option>
            <option value="Two Weeks">Within Two Weeks</option>
            <option value="One Month">Within One Month</option> */}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="thisWeek">This week</MenuItem>
            <MenuItem value="thisAndNext">This & next week</MenuItem>
            <MenuItem value="thisAndNextTwo">This week & the next two</MenuItem>
            <MenuItem value= "thisAndNextThree">This week & the next three</MenuItem>
          </Select>
        </FormControl>
        </Grid>

        <Grid container item xs={6} sm="auto" alignItems="center">
        {/* <Paper
            component="form"
            elevation={0} 
            sx={{ p: '2px 4px', display: 'flex' }}
        > */}
        <div style={{ display: 'flex', gap:'10px', flexDirection: 'row', alignItems:'center'}}>
          <Autocomplete
        freeSolo
        options={allServices.sort((a, b) => a.localeCompare(b))}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{ ml: 1, flex: 1, minWidth:150, p:0 }}
            placeholder="Search Other Services"
            InputProps={{
              ...params.InputProps,
              style: { padding: 0 }, // Remove padding around the input element
            }}
          />
        )}
        inputValue={searchTerm}
        onInputChange={handleSearchInputChange}
        onChange={(event, newValue, reason) => {
          // When an option is selected (including the freeSolo input option), perform the search
          // Check 'reason' to distinguish between selection and other actions (like clearing)
          console.log('newValue,', newValue, 'reason,', reason==='selectOption');
          if (reason === 'selectOption') {
            setSearchTerm(newValue);
            setTriggerSearch(true); // Trigger the search based on the selected or typed value
          }
        }}
        id="combo-box-autocomplete"
        disablePortal
        clearOnEscape
      />
      <IconButton type="submit" sx={{ p: '10px', width: 32, height: 32  }} aria-label="search">
        <SearchIcon />
      </IconButton>
          {/* <Autocomplete
            disablePortal
            options={allServices.sort((a, b) => a.localeCompare(b))}
            id="combo-box-autocomplete"
            renderInput={(params) => (
              <>
          <TextField
            {...params}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Other Services"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                 
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchClick}>
          <SearchIcon />
        </IconButton>
        </>
        )}
        inputValue={searchTerm}
        onInputChange={handleSearchInputChange}
      /> */}
        {/* <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Other Services"
            inputProps={{ 'aria-label': 'search other services' }}
            onChange={handleSearchInputChange}
            value={searchTerm}
           
        />
        {searchTerm && (
            <IconButton type="button" sx={{ p: '10px' }} aria-label="clear" onClick={handleClearSearch}>
            <ClearIcon />
            </IconButton>
        )}
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchClick}>
            <SearchIcon/>
        </IconButton> */}
        {/* </Paper> */}
        </div>
        </Grid>
    {/* </div> */}
    </Grid>
  );
};

export default ServiceFilter;
