import React, { useState, useEffect,  useRef } from 'react';
import { IconButton, Button, List, ListItem, ListItemText, 
  ListItemSecondaryAction, Box, Paper, Divider, Typography,
  Skeleton } from '@mui/material';

import { API } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import { invalidateOrderCache } from '../utils/objectUtils';
// const AppointmentConfirmationPage = () => (
//   <Elements stripe={stripePromise}>
//     <Confirmation />
//   </Elements>
// );



const AppointmentConfirmationPage = () => {

  const lastPurchase = JSON.parse(sessionStorage.getItem('lastPurchase') || '[]');
  const [meetingids, setMeetingIDs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const alreadyCreated = sessionStorage.getItem('meetingsCreated');
  const queryParams = new URLSearchParams(window.location.search); // Assuming window.location.search
  const createMeetings = queryParams.get('createMeetings') === 'true' && !alreadyCreated;
  const transactID = sessionStorage.getItem('orderID');
  const paymentIntentID = sessionStorage.getItem('pi_id');
  
  // useEffect(() => {

  //   const queryParams = new URLSearchParams(location.search);
  //   const createMeetings = queryParams.get('createMeetings') === 'true';
  //   if (createMeetings && lastPurchase.length > 0) {
  //     console.log('reserve API called');
  //     const items = lastPurchase.map(item => ({
  //       userid: item.hrid,
  //       timeSlots: item.meetHours.map(time => {
  //         const date = new Date(time);
  //         const dateString = date.toISOString().replace(/-/g, '').slice(0, 8);
  //         const hourString = `${date.getUTCHours()}`.padStart(2, '0');
  //         return `${dateString}#${hourString}`;
  //       })
  //     }));

  //     try {
  //       const apiName = 'reserveapi'; // replace with your API name
  //       const path = '/reserve'; // replace with your API path
  //       const init = {
  //         body: items,
  //         headers: { 'Content-Type': 'application/json' },
  //       };
    
  //       API.post(apiName, path, init);
        
  //   }catch (error) {

  //   }
  // }
  // }, []);

  // function invalidateOrderCache() {
  //   localStorage.removeItem('partMeetings');
  // }
  invalidateOrderCache('partMeetings');

  useEffect(() => {

  
    if (createMeetings && lastPurchase.length > 0) {
      // Prepare data for the reserve API
      const items = lastPurchase.map(item => ({
        userid: item.hrid,
        timeSlots: item.meetHours.map(time => {
          const date = new Date(time);
          const dateString = date.toISOString().replace(/-/g, '').slice(0, 8);
          const hourString = `${date.getUTCHours()}`.padStart(2, '0');
          return `${dateString}#${hourString}`;
        })
      }));
  
      const apiName = 'reserveapi'; // Replace with your API name
      const path = '/reserve'; // Replace with your API path
      const init = {
        body: items,
        headers: { 'Content-Type': 'application/json' },
      };
  
      // Convert createMeeting to return a promise
      const meetingCreationPromise = new Promise((resolve, reject) => {
        Object.keys(lastPurchase).forEach(key => {
          lastPurchase[key].transactionID = transactID;
        });
        createMeeting(lastPurchase, (meetingIDs) => {
          console.log('Meeting ID received:', meetingIDs);
          setIsLoading(false);
          invalidateOrderCache();
          setMeetingIDs(meetingIDs);
          resolve(meetingIDs); // Resolve the promise with the meeting IDs
        }, reject); // Reject the promise on error
      });
  
      // Reserve API call promise
      const reservePromise = API.post(apiName, path, init);
  
      Promise.all([meetingCreationPromise, reservePromise])
        .then(() => {
          // Clear the createMeetings parameter after both API calls are made
          // localStorage.setItem('meetingsCreated', 'true');
          // call orderapi to update meetingCreated status

          try {
            const initbody = {
              body: {
                orderid: transactID,
            },
              headers: { 'Content-Type': 'application/json' },
            };
            API.post('orderapi', '/order/updateMeetingCreatedStatus', initbody);
          }catch(error) {
            console.log('change meetingCreated status failed: ', error);
          }


          sessionStorage.setItem('meetingsCreated', 'true');
          queryParams.delete('createMeetings');
          navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        })
        .catch(error => {
          console.error('Error in API calls:', error);
        });
    }
  }, []); // Depends on your external variables like `navigate`, `lastPurchase` if they are props
  


  // useEffect(() => {
  //   // Assuming `meetingDetails` is a state or prop that you update
  //   if (lastPurchase.length > 0) {
  //     try {
  //       createMeeting(lastPurchase, (meetingIDs) => {
  //       console.log('Meeting ID received:', meetingIDs);
  //       setMeetingIDs(meetingIDs);
  //       // Construct meeting details including the received meetingID
        
  //     })
  //    } catch (error) {
  //       console.error('Error recording meeting: ', error);
  //     }
  //   }
    
  // }, []);
  
  function createMeeting(meetingDetails, callback) {
    API.put('meetingsapi', '/meetings', {
      body: {
        orderid: transactID, // in appointment confirmation page, orderid is transactID passed from cartdetails page
        piID: paymentIntentID,
        cartItems: meetingDetails,
      }
    })
    .then(response => {
      console.log('Success:', response.success);
      if (callback && typeof callback === 'function') {
        callback(response.meetingIDs);
      }
    })
    .catch(error => {
      console.error('Error creating meeting:', error);
    });
  }


    return (
      <div> 
        <Box sx={{ maxWidth: 600, margin: 'auto', mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          
          Payment succeeded! Thank you.
        </Typography>
      
      <Paper elevation={3} sx={{ padding: 2, position: 'relative' }}>
      {isLoading ? (
        <>
        <List>
        {lastPurchase.map((_, index) => (
            <Box key={index} 
            sx={{ position: 'relative', 
            width: 'calc(100% - 32px)', 
            height: 56, 
            mb: index === lastPurchase.length - 1 ? 0 : 2, // No margin bottom for the last item
            borderRadius: 2, // Add border radius for rounded corners
            overflow: 'hidden', }}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
          ))}
        </List>
        <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background for better readability
              color: 'black',
              padding: 1,
              borderRadius: 1,
            }}
          >
            Creating meeting...
          </Typography>
        </>
      ) : (
        <List>
        {lastPurchase.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={item.serv}
                  secondary={
                    <>

                      <Typography component="span" variant="body2" color="text.primary">
                        Provider: {item.hrName}
                      </Typography>
                      <br />
                      {/* {item.meetHours.map((time, timeIndex) => {
                        
                        const key = `${item.hrid}#${time}`;
                        
                        return (
                        <Box
                          key={timeIndex}
                          component="span"
                        >
                          {item.meetTimes[timeIndex]} 
                        </Box>
                        );
                      })} */}

                      {
                        item.meetHours.map((time, timeIndex) => (
                          <Box key={timeIndex} component="span">
                            {item.meetTimes[timeIndex]} {/* Display the time already formatted to local time */}
                            ({item.stuTZ})
                          </Box>
                        ))
                      }
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Meeting ID: {meetingids[index]}
                      </Typography>

                      <br />
                      {'Sessions: ' + item.numSession + ' | Cost per Session: $' + item.chargePS}
                      <br />
                      <Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>
                        Service Total: ${item.total}
                      </Typography>

                    </>
                  }
                />
                {/* <ListItemSecondaryAction>
                    <Button>Change</Button>
                </ListItemSecondaryAction> */}
              </ListItem>
              {index < lastPurchase.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
         )}
      </Paper>
     
      
    </Box>

      </div>
    );
  }
  
  export default AppointmentConfirmationPage;
  