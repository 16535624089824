import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { Component } from 'react';
import { Button, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import TimeZoneSelector from './TimeZoneSelector';
import { Grid, Card } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { transformToDateTimeForAvailSlotsDB, 
  transformAvailSlotsDBArrToMapArr,
  getAvailabilityWeekNumber } from '../utils/objectUtils';
import { parseISO, getWeek, addWeeks } from 'date-fns';

import {
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';





class AvailabilityComponent extends Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.state = {
      selectedDate: null,
      selectedTimeSlots: new Map(), // Map where date is the key and an array of time slots is the value
      timeZone: 'UTC', // Default to UTC
      isRecurrentDaily: new Map(), // Map where each key is a time slot and value is isRecurrentDaily flag
      isRecurrentWeekly: new Map(), // Map where each key is a time slot and value is isRecurrentWeekly flag
      currentMonthHighlightedDays: [],
      nextMonthHighlightedDays: [],
      currentMonth: dayjs().startOf('month'),
      applytime: "",
      userid: "",
      existingTS: [],
      isLoading: true,
      open:false,
      timeZoneState: {
        selectedTimeZone: '',
        useSystemTimeZone: true,
        systemTimeZone: systemTimeZone,
      },
    };
    // this.timeSlotsRef = React.createRef();
    this.timeSlotsRef = React.createRef();
  }

  scrollToTimeSlots = () => {
    const isSmallScreen = window.innerWidth <= 400;

    if (isSmallScreen && this.timeSlotsRef.current) {
      const yOffset = this.timeSlotsRef.current.offsetTop - 700;
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
      console.log('yoffset inside of class', yOffset);
    }
  };


componentDidMount() {
  this._isMounted = true; // Set mounted flag

  this.initComponent().catch(error => {
    console.error('Initialization failed:', error);
    // Handle initialization error, like setting state to show an error message
  });
}

async initComponent() {
  try {
    await this.fetchData();
    // Assuming fetchDataResult is used or checked before proceeding.
    
    // Now that fetchData has completed, proceed with fetchAvailSlots.
    this.fetchAvailSlots();

    if (this._isMounted) {
      // Proceed with any state updates or further actions that depend on the successful completion of both operations
    }
  } catch (error) {
    console.error('An operation failed:', error);
    if (this._isMounted) {
      // Handle the error, for example, by setting state to show an error message
    }
  }
}

componentWillUnmount() {
  this._isMounted = false; // Clear mounted flag
}
  
fetchData() {

  return new Promise((resolve, reject) => {

  API.get('hrapi', '/hr/:userid').then((res) => { // comment: doesn't get week numbers
    if (this._isMounted){

      if (res.length > 0) {
        // console.log('res[0] in AC,', res[0]);
        const apptime = res[0].applytime;
        const userID = res[0].userid;

        // const selectedTimeSlots = new Map();
        // this.setState({ applytime: apptime, userid: userID});
        
        if (res[0].tz) {
          const tzState = res[0].tz;
          // console.log(tzState);
          this.setState({ applytime: apptime, userid: userID, timeZoneState: tzState }, () => resolve());
          // this.setState({ timeZoneState:tzState});
        } else {
          this.setState({ applytime: apptime, userid: userID }, () => resolve());

        }
      }
    } else {
      reject("Component is not mounted.");
    }
    })
    .catch(error => {
        console.error('Error retrieving status:', error);
        reject(error);
      });

    });
}



        // if (res[0].selectedTS) {
          
        //     const STSdynamo = res[0].selectedTS;
        //     const tzState = res[0].tz;
        //     // console.log('selectedTS in dynamodb in AC,', res[0].selectedTS);
            

        //     Object.entries(STSdynamo).forEach(([key, value]) => {
        //       const items = value.L.map(item => ({
        //           hour: parseInt(item.hour.N),
        //           isRecurrentWeekly: item.isRecurrentWeekly.BOOL
        //       }));
        //       selectedTimeSlots.set(key, items);
        //     });
        //     console.log('selectedTimeSlots,', selectedTimeSlots);
        //     this.setState({ applytime: apptime, userid: userID, selectedTimeSlots: selectedTimeSlots, timeZoneState:tzState});
        // } else {

        //   this.setState({ applytime: apptime, userid: userID});
        // }

fetchAvailSlots() {
  const { timeZoneState } = this.state;
  let timeZone;
  if (timeZoneState.useSystemTimeZone) {
    timeZone = timeZoneState.systemTimeZone;
  } else {
    timeZone = timeZoneState.selectedTimeZone;
  }

  API.get('availapi', '/slots/:userid').then((res) => {
    if (this._isMounted){
    // console.log('availapi.res,', res);
    if (res.length>0) {
      const resultMap = transformAvailSlotsDBArrToMapArr(res, timeZone);
      // console.log(resultMap);

      const dateTimeArray = res.map(obj => obj.DateTime);
      console.log('dateTimeArray,', dateTimeArray);
      const nextEightAvail = getAvailabilityWeekNumber(dateTimeArray);
      console.log('nextEightAvail,', nextEightAvail);
  
      this.setState({selectedTimeSlots: resultMap, existingTS: dateTimeArray});
    }
  }

  // despite res is empty or not:
  this.setState({ isLoading: false });
  })
}


componentWillUnmount() {
  this._isMounted = false;
}

handleDateClick = (date) => {

    // this.setState({
    //       selectedDate: date,
    //       });
    this.setState({ selectedDate: date }, () => {
      this.scrollToTimeSlots();
    });
    
};


  

handleTimeSlotClick = (hour) => {
  const { selectedTimeSlots, selectedDate, isRecurrentWeekly } = this.state;
  // let timeZone;
  // if (timeZoneState.useSystemTimeZone) {
  //   timeZone = timeZoneState.systemTimeZone;
  // } else {
  //   timeZone = timeZoneState.selectedTimeZone;
  // }
  // console.log('transformed,', transformToDateTimeForAvailSlotsDB(selectedTimeSlots, timeZone), selectedTimeSlots);
  const selectedDS = selectedDate.toDate().toDateString();
  // 1. change keys in selectedTimeSlots to isostrings, and name it transforemdMap
  const recurrentWeeklyStatus = isRecurrentWeekly.get(selectedDate.toDate().toDateString())?.get(hour) || false;

  // 2. Check if the date is already in selectedTimeSlots

  const existingTimeSlots = selectedTimeSlots.get(selectedDS) || []; // for this date
  const existingSlotIndex = existingTimeSlots.findIndex((slot) => slot.hour === hour);

  // Update the existing entry or add a new one
  let updatedTimeSlots;
  if (existingSlotIndex !== -1 ) {
    // Hour is already selected, remove it => this handles deselection of a time cell if user clicks it 2nd time, existingTimeSlots is updated 1 hour slot slower, so when de-select a cell
    // this hour cell was already added to existingTimeSlots. when de-select a time cell, existingSlotIndex is 1, this then removes the deselected hour. Does this remove other hours, yes!
    updatedTimeSlots = existingTimeSlots.filter((slot) => slot.hour !== hour);
    // if (updatedTimeSlots.length === 0) {selectedTimeSlots.delete(selectedDateString); this.computeHighlightedDays();}
    if (updatedTimeSlots.length === 0) {
      
      selectedTimeSlots.delete(selectedDS); // today has no slots selected, delete today
      const updatedSelectedTS = new Map(selectedTimeSlots);
      // console.log('updatedSelectedTS', updatedSelectedTS);


      // //update selectedTimeSlots by transforming keys back to date object


      this.setState({
        selectedTimeSlots: updatedSelectedTS,
      });

      this.computeHighlightedDays();
      }
  } else {
    // Hour is not selected, add it
    updatedTimeSlots = [
      ...existingTimeSlots,
      {
        hour,
        isRecurrentWeekly: recurrentWeeklyStatus,
      },
    ];
  }


  // Check if the date is already selected
  const isDateSelected = selectedTimeSlots.has(selectedDS);

  this.setState((prevState) => ({
    selectedDate: isDateSelected ? prevState.selectedDate : selectedDate,
    selectedTimeSlots: new Map(prevState.selectedTimeSlots).set(
      selectedDate.toDate().toDateString(),
      updatedTimeSlots
    ),
  }));
  


  this.setState(prevState => {
    const selectedDateString = selectedDate.toDate().toDateString();
    const dateMap = prevState.isRecurrentWeekly.get(selectedDateString) || new Map();
    const updatedRecurrentWeekly = new Map(prevState.isRecurrentWeekly.set(selectedDateString, new Map(dateMap.set(hour, false))));

    // Other logic

    return {
      isRecurrentWeekly: updatedRecurrentWeekly,
      // Other state updates
    };
  });

};

handleTimeZoneChange = (newTimeZoneState) => {
  this.setState({timeZoneState: newTimeZoneState});
  console.log(newTimeZoneState); // Do something with the time zone state
};


// handleRecurrentDailyChange = (hour, event) => {
//   event.stopPropagation(); // Prevent the event from propagating to the parent element
//   const { selectedDate, selectedTimeSlots } = this.state;
//   const { checked } = event.target;

//   // Calculate the next occurrences of the selected time up to 8 weeks from today
//   if (checked && selectedDate) {
//     const nextOccurrences = [];
//     const currentDate = dayjs(selectedDate);
    
//     // Calculate the next occurrences for each day excluding weekends
//     for (let i = 1; i <= 8 * 7; i++) {
//       const nextDate = currentDate.add(i, 'day');
//       const dayOfWeek = nextDate.day(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      
//       // Exclude weekends (Saturday and Sunday)
//       if (dayOfWeek !== 0 && dayOfWeek !== 6) {
//         const nextDateWithHour = nextDate.hour(hour);
//         nextOccurrences.push(nextDateWithHour.toDate());
//       }
      
//       // Break the loop if the next occurrence exceeds 8 weeks from today
//       if (nextDate.diff(dayjs(), 'week') >= 8) {
//         break;
//       }
//     }

//     // Update selectedTimeSlots with the next occurrences
//     const updatedTimeSlots = new Map(selectedTimeSlots);
//     nextOccurrences.forEach(date => {
//       // const dateString = date.toISOString();
//       updatedTimeSlots.set(date, [{ hour: hour, isRecurrentDaily: true, isRecurrentWeekly: false }]);
//     });

//     // Update the state with the new selectedTimeSlots
//     this.setState({ 
//       selectedTimeSlots: updatedTimeSlots, 
//       isRecurrentDaily: checked, 
//       isRecurrentWeekly: false 
//     });
//   } else {
//     // If unchecked, remove the recurrent daily time slots
//     this.setState({ 
//       isRecurrentDaily: checked, 
//       selectedTimeSlots: new Map(), // Clear all time slots
//     });
//   }
// };



handleRecurrentWeeklyChange = (date, hour, event) => {
  event.stopPropagation(); // Prevent the event from propagating to the parent element
  const { checked } = event.target;
  
  this.setState(prevState => {
    
    const currentDate = dayjs(date);
    const currDS = date.toDate().toDateString();
    const dateMap = prevState.isRecurrentWeekly.get(currDS) || new Map();
    const updatedRecurrentWeekly = new Map(prevState.isRecurrentWeekly.set(currDS, new Map(dateMap.set(hour, checked))));
    const nextOccurrences = [];
    const maxDate = dayjs().add(8, 'week').endOf('week').toDate();
    
    // Calculate the next occurrences for the next few weeks
    for (let i = 1; i <= 8; i++) {
      const nextDate = currentDate.add(i * 7, 'day').hour(hour);
      if (nextDate.isAfter(maxDate)) {
        break; // Exit the loop if the next date exceeds maxDate
      }
      nextOccurrences.push(nextDate.toDate());
      
    }
    const updatedTimeSlots = new Map(prevState.selectedTimeSlots);
    console.log('updated time slots in HR, ', updatedTimeSlots); 
    // Calculate the next occurrences of the selected time for the next few weeks
    if (checked) {


      // Update selectedTimeSlots with the next occurrences

        prevState.selectedTimeSlots.forEach((value, key) => {
          if (key === currDS){
            const updatedValue = value.map(slot => {
              if (slot.hour === hour) {
                return { ...slot, isRecurrentWeekly: true };
              }
              return slot;
            });
            updatedTimeSlots.set(key, updatedValue);
          } 
        });


      nextOccurrences.forEach(date => {
        const dateString = date.toDateString();
        // transformedMap.set(dateString, [...existingTimeSlots, { hour: hour, isRecurrentWeekly: true }]);
        
        const existingTimeSlots = updatedTimeSlots.get(dateString) || [];
        updatedTimeSlots.set(dateString, [...existingTimeSlots, { hour: hour, isRecurrentWeekly: true }]);

        const updatedHourMap = updatedRecurrentWeekly.get(dateString) || new Map(); // Get the map for the date
        updatedHourMap.set(hour, checked); // Set the checked status for the hour
        updatedRecurrentWeekly.set(dateString, updatedHourMap); // Update the map for the date in the main map
        
      });


      return {
        isRecurrentWeekly: updatedRecurrentWeekly,
        selectedTimeSlots: updatedTimeSlots,
      };
    } else {
      // If unchecked, remove the recurrent weekly time slots
     
      updatedTimeSlots.forEach((timeSlots, key) => {
        const filteredTimeSlots = timeSlots.filter(slot => !(slot.hour === hour && slot.isRecurrentWeekly));
        if (filteredTimeSlots.length === 0) {
          updatedTimeSlots.delete(key);
        } else {
          updatedTimeSlots.set(key, filteredTimeSlots);
        }
      });

      // update today
      if (updatedRecurrentWeekly.has(currDS)) {
        const updatedHourMap = updatedRecurrentWeekly.get(currDS);
        updatedHourMap.delete(hour); // Remove the hour from the map
        if (updatedHourMap.size === 0) {
            updatedRecurrentWeekly.delete(currDS); // If no more hours for the date, remove the date from the main map
        }
      }

      nextOccurrences.forEach(date => {
        const dateString = date.toDateString();
        if (updatedRecurrentWeekly.has(dateString)) {
            const updatedHourMap = updatedRecurrentWeekly.get(dateString);
            updatedHourMap.delete(hour); // Remove the hour from the map
            
            if (updatedHourMap.size === 0) {
                
                updatedRecurrentWeekly.delete(dateString); // If no more hours for the date, remove the date from the main map
            }
        }
      });

      console.log('updated Time slots', updatedTimeSlots);
      
      return {
        isRecurrentWeekly: updatedRecurrentWeekly,
        selectedTimeSlots: updatedTimeSlots,
      };
    }
  });
};




handleMonthChange = (newDate) => {
  const currentMonth = dayjs().startOf('month');
  const nextMonth = dayjs().add(1, 'month').startOf('month');
  const newDateMonth = dayjs(newDate).startOf('month');

  if (dayjs(newDate).isSame(currentMonth, 'month')) {
    const currentMonthHighlightedDays = this.computeHighlightedDays(currentMonth);
    this.setState({
      currentMonth: currentMonth,
      currentMonthHighlightedDays: currentMonthHighlightedDays,
    });
    console.log("User navigated to the current month");
  } else if (dayjs(newDate).isSame(nextMonth, 'month')) {
    const nextMonthHighlightedDays = this.computeHighlightedDays(nextMonth);
    this.setState({
      currentMonth: nextMonth,
      nextMonthHighlightedDays: nextMonthHighlightedDays,
    });
    console.log("User navigated to the next month");
  } else if (dayjs(newDate).isSame(newDateMonth, 'month')) {
    const newDateHighlightedDays = this.computeHighlightedDays(newDateMonth);
    this.setState({
      currentMonth: newDateMonth,
      currentMonthHighlightedDays: newDateHighlightedDays,
    });
    console.log("User navigated to the 3rd month");
  }
};



handleSnackbarClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  this.setState({open:false});
  if (this.props.setIsSubmitted) {
    this.props.setIsSubmitted(true); // Indicate submission was successful
    
  }
};

snackbarAction = (
  <React.Fragment>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={this.handleSnackbarClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);


printSelectedData = () => {
    this.state.selectedTimeSlots.forEach((timeSlots, date) => {
      console.log(`Date: ${date.toDateString()}`);
      timeSlots.forEach((slot) => {
        console.log(`  Hour: ${slot.hour}, Recurrent Daily: ${slot.isRecurrentDaily}, Recurrent Weekly: ${slot.isRecurrentWeekly}`);
      });
    });
  };

ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌚' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };


computeHighlightedDays = (month) => {
  const { selectedTimeSlots } = this.state;
  // const highlightedDays = [];
  // console.log('STmaps in chd', selectedTimeSlots);
  const highlightedDays = new Set();
  

  selectedTimeSlots.forEach((timeSlots, date) => {
      if (timeSlots.length === 0) {
        // Delete the entry for the date with empty timeSlots
        selectedTimeSlots.delete(date);
      }

  });

  if (selectedTimeSlots.size !== 0) {
    const today = dayjs(); // Get today's date
    selectedTimeSlots.forEach((timeSlots, date) => {
      
        const dateObject = dayjs(date);
        // console.log('date, ', date, dateObject.isSame(month, 'month') && !dateObject.isBefore(today));
        const isTodayOrLater = dateObject.isSame(today, 'day') || dateObject.isAfter(today);
        if (dateObject.isSame(month, 'month') && isTodayOrLater) { 
          // Add the day to highlightedDays if it's in the current month
          highlightedDays.add(dateObject.date());
        }
    });
  }

  // console.log('Array.from(highlightedDays)', Array.from(highlightedDays));
  // return highlightedDays;
  return Array.from(highlightedDays);
};

handleSubmit = async () => {
  // Logic for submitting the availability
  
  const { userid, selectedTimeSlots, applytime, timeZoneState, existingTS } = this.state;
  
  this.setState({isLoading: true});
  // const currDate = new Date().toLocaleDateString();
  // const currTime = new Date().toLocaleTimeString();
  // const uploadTime = currDate + ' ' + currTime;
  const dynamoDBFormat = {};



  selectedTimeSlots.forEach((value, key) => {
      const items = value.map(item => ({
          hour: { N: item.hour.toString() },
          isRecurrentWeekly: { BOOL: item.isRecurrentWeekly }
      }));
  
      dynamoDBFormat[key] = { L: items };
    });



  let timeZone;
  if (timeZoneState.useSystemTimeZone) {
    timeZone = timeZoneState.systemTimeZone;
  } else {
    timeZone = timeZoneState.selectedTimeZone;
  }

  // put only *NEWly added slots
  console.log('timeZonestate, ', timeZone);
  // datetime slots in UTC time, like ['20240323#14', ...]
  const dateTimeSlots = transformToDateTimeForAvailSlotsDB(selectedTimeSlots, timeZone);
  const eightWeekAvail = getAvailabilityWeekNumber(dateTimeSlots);
  console.log('eight weeks', eightWeekAvail);

  API.put('hrapi', '/hr', {
    body: {
        userid: userid,
        applytime: applytime,
        // selectedTS: dynamoDBFormat,
        tz: timeZoneState,
        availWeeks: eightWeekAvail,

    },
  })

  // console.log('dateTimeSlots', dateTimeSlots, 'existingTS', existingTS);
  const added = dateTimeSlots.filter(item => !existingTS.includes(item));

  // Find strings deleted from Arr_old
  const deleted = existingTS.filter(item => !dateTimeSlots.includes(item));
  // const added = [];
  // const deleted = [];
  console.log('Added strings:', added); 
  console.log('Deleted strings:', deleted); 

  const operations = [];

  // Prepare the addition operation if there are slots to add
  if (added.length > 0) {
    operations.push(
      API.put('availapi', '/slots', {
        body: {
          availableSlots: added
        }
      })
    );
  }

  // Prepare the deletion operation if there are slots to delete
  if (deleted.length > 0) {
    operations.push(
      API.del('availapi', '/slots', {
        body: {
          availableSlots: deleted
        }
      })
    );
  }


  // Proceed only if there's at least one operation to perform
  if (operations.length > 0) {
    try {
      // Wait for all prepared operations to complete
      await Promise.all(operations);
      
      // If all operations are successful, notify the user
      // alert("Update was successful!");
      this.setState({isLoading: false, open: true});

    } catch (error) {
      // If any operation fails, log the error and notify the user
      console.error("Update failed:", error);
      alert("Update failed. Please try again.");
    }
  } else {
    // Notify the user that no update is necessary if there are no slots to add or delete
    alert("No changes to update.");
    this.setState({isLoading: false});
    if (this.props.setIsSubmitted) {
      this.props.setIsSubmitted(true); // Indicate submission was successful
      
    }
  }

};

handleCancel = () => {
  // Logic for canceling
  const isConfirmed = window.confirm("Are you sure you want to clear all selections?"); // Show confirmation dialog

  if (isConfirmed) {

    this.setState({
      selectedTimeSlots: new Map()
    }, () => {
      // Callback function after state has been updated
      this.computeHighlightedDays(); // Call your function to recompute highlighted days
      console.log('Canceling...');
    });

  } else {
    console.log('Cancel action aborted.');
  }
};


computeHighlightedHours = () => {
    const { selectedTimeSlots, selectedDate } = this.state;
    const highlightedHours = [];
  
    console.log('selectedTimeSlots,', selectedTimeSlots, selectedDate);
    // Iterate over entries in selectedTimeSlots using forEach
    selectedTimeSlots.forEach((timeSlots, date) => {
      // Check if the date matches the selectedDate
      // console.log('dayjs(date).isSame(selectedDate', dayjs(date).isSame(selectedDate, 'day'), timeSlots);
      if (dayjs(date).isSame(selectedDate, 'day')) {
        // Extract hours from timeSlots and push to highlightedHours
        console.log('timeSlots,', timeSlots);
        timeSlots.forEach((slot) => {
          highlightedHours.push(slot.hour);
        });
        console.log('highlightedHours,', highlightedHours);
      }
    });

    
    return highlightedHours;
  };
  

  

  render() {
    const availableTimeSlots = [...Array(24).keys()].filter((hour) => hour >= 7 && hour <= 22);
    const { currentMonth, isLoading, open } = this.state;
    const highlightedDays = this.computeHighlightedDays(currentMonth);
    // console.log('highlightedDays,', highlightedDays, 'currentMonth,', currentMonth);
    const highlightedHours = this.computeHighlightedHours();
    const maxDate = dayjs().add(8, 'week').endOf('week').toDate();

    return (
      <>

        <div style={{ position: 'relative' }}>

        {
            isLoading && (
              <div className="loadingOverlay">
                <CircularProgress />
              </div>
            )
          }

        <div >

        <div>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
            message="Update was successful"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            action={this.snackbarAction}
          />
        </div>
      
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'row', gap: '50px', paddingLeft: '20px'}}> */}
      <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns={{base: "1fr" , small: "1fr 1fr", large:"1fr 1fr"}}
            templateRows={{base: "2fr 3fr", small:"4fr 2fr", large: "4fr 2fr"}}
        >


       
        
          <Card rowStart={{base: "1", small:"1", large:"1"}} rowEnd={{base: "2", small:"2", large:"2"}} padding="0"> 
            
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '20px' }}>
                <TimeZoneSelector timeZoneState={this.state.timeZoneState} onTimeZoneChange={this.handleTimeZoneChange}/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        value={this.state.selectedDate}
                        onChange={(newValue) => this.handleDateClick(newValue)}
                        slots={{
                            day: this.ServerDay,
                          }}
                          slotProps={{
                            day: {highlightedDays},
                            // day: {currmonhighdays},
                          }}
                        disablePast
                        maxDate={dayjs(maxDate)}
                        onMonthChange={this.handleMonthChange}
                        sx={{width: '100%' }}
                    />
                </LocalizationProvider>
              </div>
          </Card> 
        
        <Card rowStart={{base: "2", small:"1", large:"1"}} rowEnd={{base: "-1", small:"-1", large:"-1"}} style={{ padding: '0px 10px 10px' }}>  
        
        {this.state.selectedDate && (
          <Paper aria-label='time-slots-paper' elevation={0} style={{ padding: '0px', flexGrow: 1, width:'100%'}}>
            <Typography variant="h6"   style={{
                  marginBottom: '10px',
                  maxWidth: '90%', // Limit the width to encourage wrapping
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2, // Limit to two lines
                  WebkitBoxOrient: 'vertical',
                  lineHeight: '1.5em', // Adjust based on your design
                  height: '3em', // Line height * number of lines
                }}>Select available slots and submit upon completion.</Typography>
            {[...availableTimeSlots].map((hour) => {
                
                const isSelectedTimeSlot =
                 this.state.selectedTimeSlots
                   .get(this.state.selectedDate)
                   ?.some((slot) => slot.hour === hour) ?? false;
                const isHourHighlighted2 = highlightedHours?.length > 0 && highlightedHours.includes(hour); // same as isHourHighlighted2 in background color

            return (

              //<div key={hour} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', gap: '10px' }}>
              <div key={hour} style={{ display: 'flex', justifyContent: 'flex-start',  flexDirection: 'row', gap: '10px' }} ref={this.timeSlotsRef}>
                
                
                <Paper
                    
                    onClick={() => this.handleTimeSlotClick(hour)}
                    style={{
                      padding: '10px',
                      marginBottom: '5px',
                      textAlign: 'center',
                      width: '100px',
                      height: '30px',
                        backgroundColor: (() => {
                          const selectedTimeSlots = this.state.selectedTimeSlots.get(this.state.selectedDate);
                          const isHourSelected = selectedTimeSlots?.some((slot) => slot.hour === hour);
                          const isHourHighlighted = highlightedHours?.length > 0 && highlightedHours.includes(hour);
                        
                          if (isHourSelected || isHourHighlighted) {
                            return '#4caf50'; // Use the same background color for both clicked and highlighted
                          } else {
                            return '#ffffff'; // Default background color when not clicked or highlighted
                          }
                        })(),
                        cursor: 'pointer', // cursor: isPastDay ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <Typography variant="body2"> {hour}:00 - {hour + 1}:00 </Typography>
                    
                </Paper>

                {(isSelectedTimeSlot || isHourHighlighted2) && (
                    <div>
                      <FormControlLabel
                          control={
                          <Checkbox
                              // checked={this.state.isRecurrentWeekly.get(this.state.selectedDate)?.get(hour) || false}
                              checked={(function() {
                                const checkedValue = this.state.isRecurrentWeekly.get(this.state.selectedDate.toDate().toDateString())?.get(hour) || false;
                                // console.log('hour', hour, this.state.isRecurrentWeekly, 'datestring, ', this.state.selectedDate.toString());
                                return checkedValue;
                            }).bind(this)()}
                              onChange={(e) => this.handleRecurrentWeeklyChange(this.state.selectedDate, hour, e)}
                          />
                          }
                          label="Recurrent Weekly"
                      />
                    </div>
                  )}
                
              </div>
                   );
            })}
          </Paper>
        )}
        </Card>
      
      {/* </div> */}
      </Grid>
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: '10px', paddingTop: '40px'}}>
        <Button variant="outlined" size="large" color="secondary" onClick={this.handleCancel}>
          Clear all selection
        </Button>
        <Button variant="contained" size="large" color="primary" onClick={this.handleSubmit}>
          Submit
        </Button>

      </div>
    </div>
  </div>


      </>
    );
  }
}

export default AvailabilityComponent;
